import { gql } from '@apollo/client'
import productsFieldsQuery from '../../commons/fields/products'
import linkedProductsFieldsQuery from '../../commons/fields/linkedProducts'
import promotionsFieldsQuery from '../../commons/fields/promotions'

/**
 * Get catalog fields
 * @internal
 */
export const catalogFieldsQuery: string = `
  category
  priority
  productsPrices {
    ${productsFieldsQuery}
  }
 `

/**
 * Get's the string for `catalog` query.
 * This query gets all existing items in the catalog.
 * @internal
 */
const getCatalogQuery = gql`
  query Catalog(
      $catalogStorefrontName: String!,
      $catalogSessionId: String!,
      $categoryName: String,
      $pagination: PaginationInput,
      $totalPromotionsSessionId: ID!
      $groupCategories: Boolean
      $showChildrenCategories: Boolean
    ) {
    catalog(
      storefrontName: $catalogStorefrontName,
      sessionUid: $catalogSessionId,
      category: $categoryName,
      pagination: $pagination,
      groupCategories: $groupCategories
      showChildrenCategories: $showChildrenCategories
    ) {
      category
      priority
      parentId
      productsPrices {
        ${productsFieldsQuery}
        ${linkedProductsFieldsQuery}
      }
    }
    getTotalPromotions(storefrontName: $catalogStorefrontName, sessionUid: $totalPromotionsSessionId) {
      ${promotionsFieldsQuery}
    }
  }
`

export default getCatalogQuery
