import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import getSessionQuery from './query'
import { ISessionVariables, ISession } from './types'

/**
 * Hook declaration to obtain a get session lazy query.
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetSession = (
  options?: LazyQueryHookOptions<ISession, ISessionVariables>,
) => {
  return useLazyQuery<ISession, ISessionVariables>(getSessionQuery, options)
}

export default useLazyGetSession
