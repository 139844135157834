/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useAppSelector } from '../hooks'
import { ITemplate } from '../interfaces/templates'
import Default from './Default'
import getAnalyticsItems from '../utils/getAnalyticsItems'
import { getAnalyticsCartPayload } from '../utils/getAnalyticsPayload'
import { useGetSummary } from '../hooks/summary'

const Summary: FC<ITemplate> = ({ ...props }) => {
  const state = useAppSelector((stateRedux) => stateRedux)
  const { config } = state.defaultSlice
  const cart = state.cartSlice
  const getSummary = useGetSummary()

  useEffect(() => {
    getSummary()
    const { pathname } = window.location
    const route = pathname.split('/').pop()
    if (route === 'summary') {
      const cartAnalyticsItems = getAnalyticsItems(cart)
      const analyticsPayload = getAnalyticsCartPayload(
        cart,
        cartAnalyticsItems,
        config,
      )
      ReactGA.event('view_summary', analyticsPayload)
      window.fbq('trackCustom', 'ViewCart', {
        ...analyticsPayload,
        currency: config?.options?.currencyPrefix,
      })
    }
  }, [])
  return <Default {...props} />
}

export default Summary
