import { ICartProduct, ITransformCartProductsParams } from '../../interfaces'
import { ActivePromotion, PromotionType } from '../../graphQL/commons'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import { getCashPromotionUiDetails } from './getCashPromotionUiDetails'

const filterCashActivePromotion = (promotion: ActivePromotion) => {
  const {
    fields: { type, customerGetsCash: cash },
  } = getActivePromotionsFields(promotion)
  return (
    (type === PromotionType.VOLUME || type === PromotionType.DIRECT) && cash
  )
}

/**
 * Function declaration to set cash promotions ui details to be shown on cart
 *
 * @internal
 * @param cartProduct - The cart product to be updated
 * @param activePromotions - The active promotions array
 * @param config - Config file values
 * @returns IProduct updated with promotion ui details
 */
export const setCashPromotionUiDetailsCart = ({
  cartProducts,
  activePromotions,
  config,
}: ITransformCartProductsParams): ICartProduct[] => {
  const cashActivePromotions = activePromotions?.filter(
    filterCashActivePromotion,
  )

  // apply cash discount to the first product of the promotion
  return cashActivePromotions.reduce<ICartProduct[]>(
    (currentItems, activePromotion) => {
      const {
        promotion: { customerBuys },
      } = activePromotion
      let firstProductUpdated = false

      return currentItems.map((currentItem) => {
        if (
          firstProductUpdated ||
          !customerBuys.items?.includes(currentItem.sku)
        ) {
          return currentItem
        }
        const promotion = getCashPromotionUiDetails({
          cartProduct: currentItem,
          activePromotion,
          config,
        })
        firstProductUpdated = true
        return {
          ...currentItem,
          promotion,
        }
      })
    },
    cartProducts,
  )
}
