import { ICartGroup, ICartProduct } from '../interfaces'

interface SetDisabledCartItemsArgs {
  items: ICartProduct[]
}

/**
 * Set disabled cart items
 * @param items - The cart items
 * @returns ICartProduct[] updated with disabled items
 */
export const setDisabledCartItems = ({
  items = [],
}: SetDisabledCartItemsArgs) => {
  return items.map((item) => {
    const { isHidden, linkedConditionalStock } = item
    const isLinkedProduct = !!linkedConditionalStock
    const isDisabled = isHidden || isLinkedProduct
    return {
      ...item,
      readOnly: isDisabled,
    }
  })
}

interface SetDisabledCartGroupsArgs {
  groups: ICartGroup[]
}
/**
 * Set disabled cart items by groups
 * @param groups - The cart groups
 * @returns ICartGroup[] updated with disabled items
 */
export const setDisabledCartGroups = ({
  groups,
}: SetDisabledCartGroupsArgs) => {
  return groups.map((group) => {
    const { items } = group
    return {
      ...group,
      items: setDisabledCartItems({ items }),
    }
  })
}
