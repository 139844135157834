import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import Hotjar from '@hotjar/browser'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { useGetCart } from './useGetCart'
import { onCartResponse } from '../../utils/onCartResponse'
import {
  clearCatalogueCart,
  setSynchronizingCart,
} from '../../redux/slices/catalog'
import { generateDataDogContext } from '../../utils/dataDog'
import useCartEmpty from '../../graphQL/mutations/cartEmpty'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { addAlert } from '../../redux/slices/config'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import { EMPTY_STRING } from '../../consts/defaultConfigValues/defaultConstants'

export const useClearCart = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((stateRedux) => stateRedux)
  const getCartAction = useGetCart()
  const {
    defaultSlice: { storeName, sessionId },
  } = state
  const { config } = state.defaultSlice
  const category = 'cart'
  const action = 'Clean Cart'
  const onCompleted = () => {
    onCartResponse({
      data: {
        items: [],
        groups: [],
        total: 0,
        subtotal: 0,
        totalFee: 0,
        deferredPromotions: [],
        status: EMPTY_STRING,
        createdAt: EMPTY_STRING,
        updatedAt: EMPTY_STRING,
        id: EMPTY_STRING,
        activePromotions: [],
        activeFees: [],
      },
      dispatch,
      state,
    })
  }

  const onError = (error: ApolloError) => {
    const message = getAlertErrorMessage(
      error.networkError
        ? AlertMessageType.Network
        : AlertMessageType.RemoveItem,
      config?.texts,
    )
    dispatch(addAlert({ message }))
    getCartAction()
    const context = generateDataDogContext({
      title: 'Error on clear cart mutation',
      extraInfo: { function: 'clearCartAction > onError' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [cartEmptyFn] = useCartEmpty({ onCompleted, onError })

  const clearCartAction = async () => {
    try {
      dispatch(setSynchronizingCart(true))
      Hotjar.event(action)
      sendGoogleEvent(category, action)
      window.fbq('trackCustom', 'CleanCart')
      await cartEmptyFn({
        variables: {
          sessionUid: sessionId || '',
          storefrontName: storeName || '',
        },
      })
      dispatch(clearCatalogueCart())
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not clear cart',
        extraInfo: { function: 'clearCartAction' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(setSynchronizingCart(false))
    }
  }
  return clearCartAction
}

export default useClearCart
