import { MainHeaderProps } from '@engyalo/delivery-ui-components/lib/ui/components/MainHeader'
import { DefaultProps } from '../../../interfaces'
import {
  ButtonBack,
  ButtonBackHome,
  ButtonCart,
  ButtonSearch,
} from './childProps/IconButton'
import deepMerge from '../../../utils/deepMerge'
import {
  CinnamonActionNames,
  CinnamonPaths,
  DefaultModalContainerNames,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'

export const MainHeaderDefault: DefaultProps<MainHeaderProps> = {
  logo: '{{config.logo}}',
  buttons: [ButtonSearch, ButtonCart],
}

export const MainHeaderWithBack = deepMerge(MainHeaderDefault, {
  back: ButtonBackHome,
})

export const MainHeaderProductDetail = deepMerge(MainHeaderDefault, {
  back: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.GO_BACK_DYNAMIC,
        },
        {
          name: CinnamonActionNames.CLEAR_SUBITEMS,
        },
      ],
    } as any,
  },
  buttons: [ButtonCart],
})

export const MainHeaderFilters = deepMerge(MainHeaderWithBack, {
  heading: {
    value: TextLibrary.FILTERS_VIEW_TITLE,
    size: 20,
  },
  align: 'center',

  logo: undefined,
  buttons: [],
  back: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CLOSE_MODAL,
          args: {
            container: DefaultModalContainerNames.FILTERS_LIST,
          },
        },
        {
          name: CinnamonActionNames.GO_BACK,
          args: {
            path: CinnamonPaths.HOME,
          },
        },
      ],
    } as any,
  },
})

export const MainHeaderDetail: DefaultProps<MainHeaderProps> = {
  logo: '{{config.logo}}',
  align: 'center',
  styles: {
    '& .MainHeaderContent': {
      flex: 'inherit',
    },
  },
}

export const MainHeaderCart = deepMerge(MainHeaderWithBack, {
  align: 'center',
  buttons: undefined,
})

export const MainHeaderSummary = deepMerge(MainHeaderCart, {
  back: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.GO_BACK,
        },
      ],
    } as any,
  },
})

export const MainHeaderRecommendedProducts: DefaultProps<MainHeaderProps> = {
  heading: {
    value: TextLibrary.RECOMMENDED_PRODUCTS_TITLE,
    variant: 'h2',
  },
  align: 'center',
  back: {
    icon: 'MdExpandMore',
    actions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.CART_CLOSE_RECOMMENDED_PRODUCTS_MODAL,
          args: {
            container: DefaultModalContainerNames.RECOMMENDED_PRODUCTS,
          },
        },
      ],
    } as any,
  },
  styles: {
    textAlign: 'center',
    paddingTop: '8px',
    alignItems: 'flex-start',
    '.Heading': {
      padding: '16px 0 8px',
    },
  },
}

export const MainHeaderPrefillCart: DefaultProps<MainHeaderProps> = {
  logo: '{{config.logo}}',
  heading: {
    value: '{{cart.prefillCart.title}}',
    variant: 'h2',
    size: 14,
  },
  subtitle: {
    value:
      `{{cart.prefillCart.itemsQuantity}} ${TextLibrary.PREFILL_CART_SUBTITLE}` as any,
    color: 'primary',
    variant: 'h2',
    size: 10,
    styles: { textTransform: 'uppercase' },
  },
  subTitleCount: '{{cart.prefillCart.itemsQuantity}}' as any,
  subtitleSingular:
    `{{cart.prefillCart.itemsQuantity}} ${TextLibrary.PREFILL_CART_SUBTITLE_SINGULAR}` as any,
  align: 'center',
  buttons: [
    {
      icon: 'MdClose',
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.CLOSE_MODAL,
            args: {
              container: DefaultModalContainerNames.PREFILL_CART,
            },
          },
        ],
      } as any,
    },
  ],
  styles: {
    textAlign: 'center',
    paddingTop: '12px',
    alignItems: 'flex-start',
    '.MainHeaderContent': {
      flexDirection: 'column',
    },
    '.Heading.Heading': {
      padding: '8px 0 6px 0',
    },
    '.Text.Heading': {
      padding: '0 0 8px 0',
    },
  },
}

export const MainHeaderSuggestedProducts: DefaultProps<MainHeaderProps> = {
  heading: {
    value: TextLibrary.SUGGESTED_ITEMS_TITLE,
    size: 20,
    lines: 1,
  },
  align: 'center',
  subtitle:
    `{{state.suggestedProductsSlice.numberOfItems}} ${TextLibrary.SUGGESTED_ITEMS_SUBTITLE}` as any,
  back: {
    icon: 'MdArrowBack',
    size: 'small',
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.GO_BACK,
          args: {
            path: CinnamonPaths.HOME,
          },
        },
      ],
    } as any,
    styles: {
      fontSize: '18px',
      marginTop: '-18px',
    },
  },
  styles: {
    gap: '10px',
    '@media(max-width: 374px)': {
      '.Heading': {
        fontSize: '15px',
      },
    },
  },
}

export const MainHeaderLinkedProducts: DefaultProps<MainHeaderProps> = {
  heading: {
    align: 'center',
    value: TextLibrary.LINKED_PRODUCTS_HEADING,
    variant: 'h2',
  },
  back: {
    icon: 'MdExpandMore',
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CLOSE_MODAL,
          args: {
            container: DefaultModalContainerNames.LINKED_PRODUCTS,
          },
        },
      ],
    } as any,
  },
  styles: {
    paddingTop: '16px',
    paddingRight: '48px !important',
    '.MainHeaderSideStart': {
      marginTop: '-16px',
    },
  },
}

export const MainHeaderChosenFreegoods: DefaultProps<MainHeaderProps> = {
  heading: {
    align: 'center',
    value: TextLibrary.CHOSEN_FREEGOODS_HEADER,
    variant: 'h2',
  },
  subtitle: TextLibrary.CHOSEN_FREEGOODS_DESCRIPTION,
  align: 'center',
}

export const MainHeaderCategoriesNav = deepMerge(MainHeaderDefault, {
  back: ButtonBack,
})
