import { ITextLibrary } from '../interfaces'

export enum AlertMessageType {
  outOfStock,
  AddItem = 1,
  RemoveItem,
  GetCart,
  Network,
}

export const getAlertErrorMessage = (
  errorType: AlertMessageType,
  texts?: ITextLibrary,
  extraData: number = 0,
): string => {
  const errors = texts?.errors
  const errorIsPlural = extraData > 1
  const stockError = errorIsPlural
    ? errors?.cartOutOfStockErrorPlural
    : errors?.cartOutOfStockErrorSingle
  switch (errorType) {
    case AlertMessageType.AddItem:
      return errors?.cartAddItemError || ''
    case AlertMessageType.RemoveItem:
      return errors?.cartRemoveItemError || ''
    case AlertMessageType.outOfStock:
      return stockError
        ? stockError.replace('{{stock}}', String(extraData))
        : ''
    case AlertMessageType.GetCart:
      return errors?.cartGetItemsError || ''
    case AlertMessageType.Network:
      return errors?.genericNetworkError || ''
    default:
      return ''
  }
}

export default getAlertErrorMessage
