import { SessionDataArgs } from '../graphQL/queries/getSession/types'
import { ICart, IConfig } from '../interfaces'
import { AnalyticsPurchaseItem } from './getAnalyticsItems'

const getAnalyticsPurchasePayload = (
  cart: ICart,
  analyticsItems: AnalyticsPurchaseItem[],
  session: SessionDataArgs,
  config?: IConfig,
) => {
  return {
    transaction_id: session.id || '',
    value: cart.total,
    currency: config?.options?.currencyPrefix || '',
    items: analyticsItems,
  }
}

const getAnalyticsCartPayload = (
  cart: ICart,
  analyticsItems: AnalyticsPurchaseItem[],
  config?: IConfig,
) => {
  return {
    value: cart.total,
    currency: config?.options?.currencyPrefix || '',
    items: analyticsItems,
  }
}

export { getAnalyticsPurchasePayload, getAnalyticsCartPayload }
