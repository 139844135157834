import { datadogRum } from '@datadog/browser-rum'
import ReactGA from 'react-ga4'
import Hotjar from '@hotjar/browser'
import useAcceptCheckoutRule from '../graphQL/mutations/acceptCheckoutRule'
import { SessionDataArgs } from '../graphQL/queries/getSession/types'
import useAppDispatch from './useAppDispatch'
import { ICart, LoadersType } from '../interfaces'
import useModal from './useModal'
import { validateIsAlcohol, validateIsReturnable } from '../utils/validations'
import { addLoader, removeLoader } from '../redux/slices/config'
import postCreateOrder from '../services/postCreateOrder'
import { generateDataDogContext } from '../utils/dataDog'
import { ICheckoutRulesConstraints } from '../graphQL/commons'
import { sendGoogleEvent } from '../utils/sendGoogleAnalytics'
import useAppSelector from './useAppSelector'
import getAnalyticsItems from '../utils/getAnalyticsItems'
import { getAnalyticsPurchasePayload } from '../utils/getAnalyticsPayload'

const useCreateOrderHook = () => {
  const { closeModal } = useModal()
  const dispatch = useAppDispatch()
  const [acceptCheckoutRule] = useAcceptCheckoutRule()
  const state = useAppSelector((stateRedux) => stateRedux)
  const { config } = state.defaultSlice

  const CreateOrder = async ({
    sessionId,
    storeFrontName,
    phone,
    cart,
    session,
  }: {
    sessionId: string
    storeFrontName: string
    phone: string
    cart: ICart
    session: SessionDataArgs
  }) => {
    const { configuration } = session

    const isAlcoholActive =
      configuration?.checkoutRules?.cartConditionPendingUserValidation.isActive
    const isReturnableActive =
      configuration?.checkoutRules?.cartWarningCheckReturnables.isActive
    // --> checkout rules
    if (validateIsReturnable(cart) && isReturnableActive) {
      acceptCheckoutRule({
        variables: {
          storefrontName: storeFrontName,
          sessionUid: sessionId,
          ruleType: ICheckoutRulesConstraints.CART_WARNING_CHECK_RETURNABLES,
        },
      })
    }
    if (validateIsAlcohol(cart) && isAlcoholActive) {
      acceptCheckoutRule({
        variables: {
          storefrontName: storeFrontName,
          sessionUid: sessionId,
          ruleType:
            ICheckoutRulesConstraints.CART_CONDITION_PENDING_USER_VALIDATION,
        },
      })
    }
    // <-- checkout rules
    closeModal({ container: 'confirmOrder' })
    dispatch(addLoader({ name: LoadersType.CREATE_ORDER, delay: 0 }))
    const category = 'cart'
    const action = 'Create Order'
    Hotjar.event(action)
    sendGoogleEvent(category, action)
    window.fbq('trackCustom', 'CreateOrder')
    const cartAnalyticsItems = getAnalyticsItems(cart)
    const analyticsPayload = getAnalyticsPurchasePayload(
      cart,
      cartAnalyticsItems,
      session,
      config,
    )
    ReactGA.event('purchase', analyticsPayload)
    window.fbq('track', 'Purchase', analyticsPayload)
    try {
      const workflowApi = process.env.REACT_APP_WORKFLOW_API || ''
      const botrunnerApi = process.env.REACT_APP_BOTRUNNER_API || ''
      await postCreateOrder({
        data: session,
        phoneNumber: phone,
        workflowApi,
        botrunnerApi,
      })
    } catch (error) {
      const context = generateDataDogContext({
        title: 'could not create order',
        extraInfo: { phone, action: 'postCreateOrder' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(error, context)
      dispatch(removeLoader(LoadersType.CREATE_ORDER))
    }
  }
  return CreateOrder
}

export default useCreateOrderHook
