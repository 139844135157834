import { ActivePromotion } from '../../graphQL/commons'
import {
  ICartProduct,
  IConfig,
  IProduct,
  IPromotion,
  IPromotionUiCatalog,
} from '../../interfaces'
import { getPromotionFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import formatPercentage from '../formatPercentage'
/**
 * Function declaration to get volume promotions ui details to be shown on catalog
 *
 * @internal
 * @param price - The product price
 * @param config - Cinnamon config file
 * @param promotion - The promotion to be displayed
 * @param activePromotion - Details of promotion applied to the product
 * @returns IProduct updated with promotion ui details
 */
export const getVolumePromotionUiDetails = ({
  product,
  promotion,
  config,
  activePromotion,
}: {
  product: IProduct | ICartProduct
  promotion: IPromotion
  config?: IConfig
  activePromotion?: ActivePromotion
}): IPromotionUiCatalog | undefined => {
  if (
    !config?.texts.promotions &&
    (!promotion.name || !promotion.description)
  ) {
    return undefined
  }

  const {
    customerBuysMinimum: minimum,
    customerGetsPercentage: percentage,
    customerGetsCash: cash,
  } = getPromotionFields(promotion)

  const { price, quantity } = product
  const validPrice = Number(price)
  const customerGetsPercentage: number = Number(percentage)
  const customerBuysMinimum: number = Number(minimum)
  const { count: countActivePromotion = 0, pricePerItem = 0 } =
    activePromotion || {}
  const promotionPercentageFormat = config?.options?.promotionPercentageFormat
  const percentageLabel = formatPercentage(
    customerGetsPercentage,
    promotionPercentageFormat,
  )
  const unitSaving = validPrice * customerGetsPercentage
  const initSaving = unitSaving * customerBuysMinimum
  const saving: number = countActivePromotion
    ? validPrice * quantity - pricePerItem
    : initSaving
  const {
    volumeDefaultLabel = '',
    volumeDefaultDescription = '',
    volumeDefaultNotes = '',
    defaultSaving = '',
  } = config?.texts.promotions || {}

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving,
    unitSaving,
    initSaving,
    placeholders: {
      discount: cash || percentageLabel,
      value: String(customerBuysMinimum),
    },
  }
  const promotionLabelMessage = promotion.name || volumeDefaultLabel
  const promotionDescriptionMessage =
    promotion.description || volumeDefaultDescription
  return {
    label: formatPromotionMessage({
      ...formatArgs,
      message: promotionLabelMessage as string,
    }),
    description: formatPromotionMessage({
      ...formatArgs,
      message: promotionDescriptionMessage as string,
    }),
    saving:
      promotion.name || cash
        ? ''
        : formatPromotionMessage({
            ...formatArgs,
            message: defaultSaving as string,
          }),
    notes: volumeDefaultNotes as string,
  }
}
