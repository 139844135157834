import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import getCartQuery from './query'
import { IGetCart, IGetCartVariables } from './types'

/**
 * Hook declaration to obtain a get cart lazy query.
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetCart = (
  options?: LazyQueryHookOptions<IGetCart, IGetCartVariables>,
) => {
  return useLazyQuery<IGetCart, IGetCartVariables>(getCartQuery, options)
}

export default useLazyGetCart
