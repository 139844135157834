import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import useMetaTags from 'react-metatags-hook'
import BaseContainer from '../containers/BaseContainer'
import ErrorBoundaries from '../containers/ErrorBoundaries'
import NotFound from '../containers/404'
import { useAppSelector } from '../hooks'
import { ITextLibrary } from '../interfaces'
import { WHATSAPP_ME_URL } from '../consts/defaultConfigValues/defaultConstants'
import Config from '../containers/Config'
import ErrorMessage, { ActionType } from '../components/ErrorMessage'
import getUrlParams from '../utils/getUrlParams'

interface IndexProps {
  children?: React.ReactNode
}

const Index = ({ children }: IndexProps) => {
  const {
    config,
    loading,
    globalError,
    errorMessage,
    showErrorTitle,
    sessionData,
    onErrorCallback,
  } = useAppSelector((state) => state.defaultSlice)
  const { errors = {} } = (config?.texts || {}) as ITextLibrary
  const {
    globalError: errorTitle = '',
    backToWhatsapp = `Send 'Hi' to Chatbot`,
    botMessage = 'Hi',
    tryAgain = 'Try Again',
  } = errors
  const onErrorAction: ActionType = onErrorCallback
    ? ActionType.reload
    : ActionType.navigate
  const buttonErrorText = onErrorCallback ? tryAgain : backToWhatsapp
  const phoneNumber = sessionData?.workflow?.channelUid || config?.phoneNumber
  const wameUrl = encodeURI(
    `${WHATSAPP_ME_URL}${phoneNumber}?text=${botMessage}`,
  )
  let errorCallBack = onErrorCallback
  if (phoneNumber) {
    errorCallBack = () => window.location.replace(wameUrl)
  }

  useMetaTags({
    title: config?.metatags?.title,
    description: config?.metatags?.description,
    charset: config?.metatags?.charset,
    lang: config?.metatags?.lang,
    metas: [{ name: 'keywords', content: config?.metatags?.keywords }],
    links: [{ rel: 'icon', href: config?.metatags?.favicon }],
  })

  const { baseName } = getUrlParams(window.location.pathname)
  return (
    <Router basename={baseName}>
      <Config>
        {globalError ? (
          <ErrorMessage
            message={errorMessage || ''}
            title={showErrorTitle ? errorTitle.toString() : ''}
            callbackMessage={buttonErrorText.toString()}
            callBack={errorCallBack}
            action={onErrorAction}
          />
        ) : (
          <Switch>
            {!loading
              ? config &&
                config.routes &&
                config.routes.map(({ route, exact, container }) => (
                  <Route exact={exact} path={route} key={route}>
                    <ErrorBoundaries>
                      <BaseContainer
                        container={config.containers[container]}
                        modals={config.modals || {}}
                      />
                      {children}
                    </ErrorBoundaries>
                  </Route>
                ))
              : null}
            <Route component={NotFound} />
          </Switch>
        )}
      </Config>
    </Router>
  )
}

export default Index
