/**
 * Sanitize a string value to be used as an URL slug
 * @param value
 * @returns
 */
export const sanitizeSlug = (value = ''): string =>
  value
    .normalize('NFD')
    // remove diatrics characters like 'á', 'ñ'...
    .replaceAll(/[\u0300-\u036f]/g, '')
    // remove special characters like '@', '+'...
    .replaceAll(/[^A-Za-z0-9 ]/g, '')
    // remove white spaces from the ends
    .trim()
    // convert white spaces to "-"
    .replaceAll(' ', '-')
    // pass all characters to lowercase
    .toLowerCase()
