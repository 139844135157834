import { gql } from '@apollo/client'

/**
 * Get's the string for `cartEmpty` mutation.
 * This mutation clears a cart.
 * @internal
 */

const cartEmpty = `mutation CartEmpty($storefrontName: String!, $sessionUid: ID!) {
    cartEmpty(storefrontName: $storefrontName, sessionUid: $sessionUid) {
      id
      status
      createdAt
      updatedAt
      items {
        sku
        price
        quantity
        name
        description
        packageType
        unitsPerPackage
        unitDivision
        imageURL
        divisionsByUnit
        discount
      }
      groups {
        groupBy
        items {
          sku
          quantity
          price
          name
          imageURL
          minQty
          maxQty
          description
          packageType
          unitsPerPackage
          unitDivision
          divisionsByUnit
          discount
          brand
          customFields
          attributes
          category
        }
        total
      }
    }
  }`

export default gql`
  ${cartEmpty}
`
