import { useEffect } from 'react'
import {
  TemplateActionNames,
  DefaultModalContainerNames,
} from '../../consts/defaultConfigValues/defaultConstants'
import { IPlaceOrderFlow } from '../../interfaces'
import {
  clearPlaceOrderActions,
  nextPlaceOrderAction,
  setPlaceOrderFlow,
} from '../../redux/slices/cart'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { usePlaceOrder, usePushView } from '..'
import { useFrequentlyBoughtTogether } from './useFrequentlyBoughtTogether'
import useLinkedProducts from '../linkedProducts/useLinkedProducts'

interface IExecuteCartActions
  extends Pick<IPlaceOrderFlow, 'actions' | 'pushAfter'> {}

export const usePlaceOrderFlow = () => {
  const dispatch = useAppDispatch()
  const { actions, pushAfter, isDone } = useAppSelector(
    (state) => state.cartSlice.placeOrderFlow,
  )

  // hooks
  const pushView = usePushView()
  const getFrequentlyBoughtTogether = useFrequentlyBoughtTogether()
  const placeOrder = usePlaceOrder()
  const { getLinkedProducts } = useLinkedProducts()

  // hooks map
  const hooksMap: Record<string, () => void> = {
    [TemplateActionNames.CART_GET_FREQUENTLY_BOUGHT_TOGETHER]: () => {
      getFrequentlyBoughtTogether({
        modalName: DefaultModalContainerNames.RECOMMENDED_PRODUCTS,
      })
    },
    [TemplateActionNames.CART_PLACE_ORDER]: () => {
      placeOrder()
    },
    [TemplateActionNames.LINKED_PRODUCTS_GET_ITEMS]: () => {
      getLinkedProducts()
    },
  }

  // set place order flow
  const placeOrderFlow = (args: IExecuteCartActions) => {
    const { actions: newActions, pushAfter: newPushAfter } = args
    dispatch(
      setPlaceOrderFlow({ actions: newActions, pushAfter: newPushAfter }),
    )
  }

  // next place order flow
  const placeOrderFlowNext = () => {
    dispatch(nextPlaceOrderAction())
  }

  // clear place order actions
  const placeOrderFlowClear = () => {
    dispatch(clearPlaceOrderActions())
  }

  // manage place order flow actions
  useEffect(() => {
    const currentAction = actions[0]
    if (currentAction && !isDone) {
      const action = hooksMap[currentAction]
      // if action is not defined, go to next action
      if (!action) {
        placeOrderFlowNext()
        return
      }
      action()
    }
    if (isDone && pushAfter) {
      pushView({ path: pushAfter })
      dispatch(clearPlaceOrderActions())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, isDone, dispatch])

  // clear place order actions on unmount
  useEffect(() => {
    return () => {
      dispatch(clearPlaceOrderActions())
    }
  }, [dispatch])

  return { placeOrderFlow, placeOrderFlowNext, placeOrderFlowClear }
}

export default usePlaceOrderFlow
