import {
  useLazyQuery,
  LazyQueryHookOptions,
  LazyQueryResultTuple,
} from '@apollo/client'
import getProductBySkuQuery from './query'
import { IGetProductBySku, IGetProductBySkuVariables } from './types'

/**
 * Hook declaration to obtain a lazy query of single product by a given sku.
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetProductBySku = (
  options?: LazyQueryHookOptions<IGetProductBySku, IGetProductBySkuVariables>,
): LazyQueryResultTuple<IGetProductBySku, IGetProductBySkuVariables> => {
  return useLazyQuery<IGetProductBySku, IGetProductBySkuVariables>(
    getProductBySkuQuery,
    options,
  )
}
