import { AlertProps } from '@engyalo/delivery-ui-components/lib/ui/components/Alert'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import { TemplateActionNames, TextLibrary } from '../defaultConstants'

export const AlertDefault: DefaultProps<AlertProps> = {
  align: 'center',
  icon: false,
  severity: 'error',
}

export const AlertMinQuantity = deepMerge(AlertDefault, {
  show: '{{default.cartAlerts.cartConditionPendingMinQty.isActive}}' as any,
  message: '{{checkoutRules.cartConditionPendingMinQty.message}}',
})

export const AlertMaxQuantity = deepMerge(AlertDefault, {
  show: '{{default.cartAlerts.cartConditionPendingMaxQty.isActive}}' as any,
  message: '{{checkoutRules.cartConditionPendingMaxQty.message}}',
})

export const AlertMaxAmount = deepMerge(AlertDefault, {
  show: '{{default.cartAlerts.cartConditionPendingMaxAmount.isActive}}' as any,
  message: '{{checkoutRules.cartConditionPendingMaxAmount.message}}',
})

export const AlertOnSummaryMinAmount = deepMerge(AlertDefault, {
  show: '{{summary.summaryAlerts.summaryAlertOnMinAmount.isActive}}' as any,
  message: TextLibrary.SUMMARY_ALERT_ON_MIN_AMOUNT,
  severity: 'warning',
  styles: {
    marginBottom: '16px',
  },
})

export const AlertCreditLimit = deepMerge(AlertDefault, {
  show: '{{default.cartAlerts.cartConditionPendingMaxAmount.isActive}}' as any,
  message: '{{texts.catalogue.creditLimitReached}}',
})

export const AlertGlobalPromotion: DefaultProps<AlertProps> = {
  show: '{{globalPromotions.show}}' as any,
  message: {
    value: '{{globalPromotions.message}}',
    sx: {
      color: '#FFF',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '130%',
    },
  },
  icon: {
    name: 'CmGlobalPromotion',
    iconset: 'cm',
    size: '24px',
    color: '#FFF',
  },
  styles: {
    marginTop: '8px',
    padding: '16px',
    height: '56px',
    gap: '8px',
    backgroundColor: '#162C78',
    backgroundImage: `url("https://storage.googleapis.com/commerce-cinnamon-staging/ui-components/alert/custom-alert-bg.svg")`,
    border: 'none',
    borderRadius: '0',
    color: '#FFF',
  },
  closeButton: {
    actions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.CLOSE_GLOBAL_PROMOTIONS_ALERT,
        },
      ],
    },
    iconButton: {},
  } as any,
}

export const AlertGlobalPromotionCart: DefaultProps<AlertProps> = {
  show: '{{globalPromotions.showCart}}' as any,
  message: {
    value: '{{globalPromotions.messageCart}}',
    sx: {
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '700',
      color: 'text.primary',
    },
  },
  icon: {
    name: 'CmGlobalPromotion',
    iconset: 'cm',
    size: '12px',
    color: '#000',
  },
  styles: {
    padding: '4px 8px',
    minHeight: '24px',
    height: 'auto',
    gap: '8px',
    backgroundColor: '#FFECDB',
    border: 'none',
    borderRadius: '0',
    boxShadow: 'inset 0 4px 8px rgba(0, 0, 0, 0.10)',
  },
}
