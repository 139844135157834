import { gql } from '@apollo/client'
import promotionsFieldsQuery from '../../commons/fields/promotions'
import cartProductsFieldsQuery from '../../commons/fields/cartProducts'

/**
 * Get cart fields
 * @internal
 */
export const cartFieldsQuery: string = `
  items {
    ${cartProductsFieldsQuery}
  }
  groups {
    groupBy
    items {
      ${cartProductsFieldsQuery}
    }
    total
    subtotal
  }
  id
  status
  customFields
  createdAt
  updatedAt
  total
  subtotal
  warnings
  activeFees {
    total
    fee
    {
      fee
      threshold
      type
    }
  }
  activePromotions {
    complexConfiguration {
      selectedGroupIdentifier
    }
    freeGoods {
      consumed {
        quantity
        sku
      }
      count
      items {
        quantity
        sku
      }
    }
    count
    pricePerItem
    itemsConsumed
    label
    promotion {
      ${promotionsFieldsQuery}
    }
  }
  deferredPromotions {
    count
    complexConfiguration {
      selectBy,
    }
    promotion {
      ${promotionsFieldsQuery}
    }
  }
`
/**
 * Get's the string for `cart` query.
 * This query gets all existing items in the current cart.
 * @internal
 */
const getCartQuery = gql`
  query cart(
    $cartStorefrontName: String!,
    $cartSessionUid: ID!
  ) {
    cart(storefrontName: $cartStorefrontName, sessionUid: $cartSessionUid) {
      ${cartFieldsQuery}
    }
  }
`

export default getCartQuery
