import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UpdateCartPayload } from './types'
import {
  ICart,
  ICartProduct,
  IPlaceOrderFlow,
  IProduct,
} from '../../../interfaces'
import { calculateCartQuantities } from '../../../utils/utilsForCartReducer'
import { TextLibrary } from '../../../consts/defaultConfigValues/defaultConstants'
import { ICounterActionArgs } from '../../../interfaces/actions'
import { ActivePromotion } from '../../../graphQL/commons'

export const initialState: ICart = {
  id: null,
  items: [],
  groups: [],
  status: null,
  warnings: [],
  customFields: null,
  createdAt: null,
  updatedAt: null,
  disablePlaceOrder: true,
  total: 0,
  itemsTotal: 0,
  alerts: [],
  subtotal: 0,
  recommendedProducts: {
    show: true,
    closeButton: '{{texts.recommendedProducts.decline}}',
    productsSkus: [],
  },
  placeOrderFlow: {
    actions: [],
    isDone: false,
  },
  quantitiesMap: {},
  activePromotions: [],
  prefillCart: {
    items: [],
  },
  linkedProducts: {
    show: false,
    items: [],
    continueButton: TextLibrary.LINKED_PRODUCTS_CONTINUE,
  },
  activeFees: [],
  totalFee: 0,
  deferredPromotions: null,
}

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload.id
    },
    addItem: (state, action) => {
      state.items = [...state.items, action.payload as ICartProduct]
    },
    setStatus: (state, action) => {
      state.status = action.payload.status
    },
    setWarnings: (state, action) => {
      state.warnings = action.payload.warnings
    },
    setPlaceOrderFlow: (
      state,
      action: PayloadAction<Pick<IPlaceOrderFlow, 'actions' | 'pushAfter'>>,
    ) => {
      state.placeOrderFlow.actions = action.payload.actions || []
      state.placeOrderFlow.pushAfter = action.payload.pushAfter
      state.placeOrderFlow.isDone = false
    },
    nextPlaceOrderAction: (state) => {
      state.placeOrderFlow.actions.shift()
      if (state.placeOrderFlow.actions.length === 0) {
        state.placeOrderFlow.isDone = true
      }
    },
    clearPlaceOrderActions: (state) => {
      state.placeOrderFlow.actions = []
      state.placeOrderFlow.isDone = false
    },
    setActivePromotions: (state, action: PayloadAction<ActivePromotion[]>) => {
      state.activePromotions = action.payload
    },
    setCustomFields: (state, action) => {
      state.customFields = action.payload.customFields
    },
    setCreatedAt: (state, action) => {
      state.createdAt = action.payload.createdAt
    },
    setUpdatedAt: (state, action) => {
      state.updatedAt = action.payload.updatedAt
    },
    setDisabledPlaceOrder: (state, action) => {
      state.disablePlaceOrder = action.payload
    },
    setShowRecommendedProducts: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.recommendedProducts.show = payload
    },
    setRecommendedProductsButtonLabel: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.recommendedProducts.closeButton = payload
    },
    setCart: (state, { payload }: PayloadAction<UpdateCartPayload>) => {
      if (payload) {
        state.items = payload.items
        state.groups = payload.groups
        state.itemsTotal = payload.itemsTotal
        state.total = payload.total
        state.subtotal = payload.subtotal
        state.disablePlaceOrder = payload.disablePlaceOrder
        state.recommendedProducts.productsSkus = payload.productsSkus
        state.quantitiesMap = payload.quantitiesMap
        state.activeFees = payload.activeFees
        state.totalFee = payload.totalFee
      }
    },
    setPrefillCart: (state, { payload }: PayloadAction<ICartProduct[]>) => {
      const itemsQuantity = payload.length
      const { quantity, total } = calculateCartQuantities(payload)
      const disabled = quantity <= 0
      state.prefillCart = {
        ...state.prefillCart,
        items: payload,
        itemsQuantity,
        quantity,
        total,
        disabled,
        wasShown: true,
      }
    },
    updatePrefillCart: (
      state,
      { payload }: PayloadAction<ICounterActionArgs>,
    ) => {
      const { items } = state.prefillCart
      const { sku, newValue } = payload
      const index = items.findIndex((item) => item.sku === sku)
      if (index !== -1) {
        items[index].quantity = newValue
        const { quantity, total } = calculateCartQuantities(items)
        const disabled = quantity <= 0
        state.prefillCart = {
          ...state.prefillCart,
          items,
          quantity,
          total,
          disabled,
        }
      }
    },
    setPrefillCartTitle: (state, { payload }: PayloadAction<string>) => {
      state.prefillCart.title = payload
    },
    setLinkedProducts: (state, { payload }: PayloadAction<IProduct[]>) => {
      state.linkedProducts.items = payload
    },
    setShowLinkedProducts: (state, { payload }: PayloadAction<boolean>) => {
      state.linkedProducts.show = payload
    },
    setLinkedProductsContinueButton: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.linkedProducts.continueButton = payload
    },
  },
})

export const {
  setId,
  addItem,
  setStatus,
  setWarnings,
  setPlaceOrderFlow,
  nextPlaceOrderAction,
  clearPlaceOrderActions,
  setCustomFields,
  setCreatedAt,
  setUpdatedAt,
  setDisabledPlaceOrder,
  setShowRecommendedProducts,
  setRecommendedProductsButtonLabel,
  setCart,
  setActivePromotions,
  setPrefillCart,
  updatePrefillCart,
  setPrefillCartTitle,
  setLinkedProducts,
  setShowLinkedProducts,
  setLinkedProductsContinueButton,
} = cartSlice.actions

export default cartSlice.reducer
