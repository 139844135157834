import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import suggestedItemsQuery from './query'
import {
  ISuggestedProductsVariables,
  ISuggestedProductsResponse,
} from './types'

/**
 * Hook declaration to obtain a lazy query of frequently bought together products
 * @param options: LazyQueryHookOptions
 * @internal
 */
const useLazyGetSuggestedItems = (
  options?: LazyQueryHookOptions<
    ISuggestedProductsResponse,
    ISuggestedProductsVariables
  >,
) => {
  return useLazyQuery<ISuggestedProductsResponse, ISuggestedProductsVariables>(
    suggestedItemsQuery,
    options,
  )
}

export { useLazyGetSuggestedItems }
