import useAppDispatch from './useAppDispatch'
import {
  openDialog as openDialogAction,
  closeDialog as closeDialogAction,
} from '../redux/slices/config'
import { IDialogActionsProps } from '../interfaces/actions'

const useDialog = () => {
  const dispatch = useAppDispatch()
  const openDialog = ({
    icon,
    title,
    buttons,
    message,
    id,
    container,
    item,
  }: IDialogActionsProps) => {
    dispatch(openDialogAction({ icon, title, buttons, message, id, container }))
  }

  const closeDialog = ({ item, container, rejected }: IDialogActionsProps) => {
    dispatch(closeDialogAction({ container }))
  }

  return { openDialog, closeDialog }
}

export default useDialog
