import { ComplexPromotionTypes } from '../../graphQL/commons'
import {
  IPromotionUiCatalog,
  IGetComplexPromotionDetailsArgs,
} from '../../interfaces'
import {
  getItemExtraInfo,
  getPromotionFields,
} from '../common/getPromotionFields'
import formatPrice from '../formatPrice'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'

/**
 * Function declaration to get complex promotions ui details to be shown on catalog
 *
 * @internal
 * @param promotion - Selected promotion, should be complex promotion
 * @param config - The config object for get texts
 * @param activePromotion - The active promotion if exists to calculate savings amounts
 * @returns IPromotionUiCatalog for append to catalog product
 */
export const getComplexComboPromotionUiDetails = ({
  promotion,
  config,
  activePromotion,
  price,
}: IGetComplexPromotionDetailsArgs): IPromotionUiCatalog | undefined => {
  const { customerBuysGroups, customerGetsGroups, itemsExtra } =
    getPromotionFields(promotion)

  const {
    comboDifferentDiscountLabel = '',
    comboGetsDiferentProductDescription = '',
    defaultSaving = '',
    defaultPromotionLabel = '',
    complexOneProductGroupAmountLabel = '',
    complexOneProductGroupAmountDescription = '',
  } = config?.texts.promotions || {}

  const isOneGroupPromotion =
    customerBuysGroups.length === 1 && customerGetsGroups?.length === 1

  if (isOneGroupPromotion) {
    const [{ items: customerBuysItems, type: customerBuysItemType }] =
      customerBuysGroups
    const [{ items: customerGetsItems, type: customerGetsItemType }] =
      customerGetsGroups
    const isOneProductPromotion =
      customerBuysItems.length === 1 && customerGetsItems.length === 1
    // one group one product use cases
    if (isOneProductPromotion) {
      const [{ quantity: customerBuysQuantity, amount: customerBuysAmount }] =
        customerBuysItems
      const [{ sku: customerGetsItemSku, quantity: customerGetsQuantity }] =
        customerGetsItems

      const [
        itemExtraExists,
        {
          price: itemExtraPrice,
          name: productDescription,
          imageURL: productImage,
        },
      ] = getItemExtraInfo(itemsExtra, customerGetsItemSku)
      const { count: countActivePromotion = 0 } = activePromotion || {}
      const isBuyQuantityGetsQuantity =
        customerBuysItemType === ComplexPromotionTypes.QUANTITY &&
        customerGetsItemType === ComplexPromotionTypes.QUANTITY

      const isBuyAmountGetsQuantity =
        customerBuysItemType === ComplexPromotionTypes.AMOUNT &&
        customerGetsItemType === ComplexPromotionTypes.QUANTITY

      if (
        itemExtraExists &&
        (isBuyQuantityGetsQuantity || isBuyAmountGetsQuantity)
      ) {
        const saving =
          itemExtraPrice *
          (customerGetsQuantity || 0) *
          (countActivePromotion || 1)
        const discount = String(
          (customerGetsQuantity || 0) + (customerBuysQuantity || 0),
        )

        const currency: string | undefined =
          config?.options?.currency || config?.options?.currencySymbol
        const format: string | undefined = config?.options?.format

        const customerBuysAmountFormatted = formatPrice({
          currency,
          format,
          price: customerBuysAmount,
        })

        const formatArgs: IFormatPromotionMessage = {
          message: '',
          promotion,
          config,
          saving,
          placeholders: {
            discount: String(discount),
            value: isBuyAmountGetsQuantity
              ? customerBuysAmountFormatted
              : String(customerBuysQuantity),
            product: productDescription,
            quantity: String(customerGetsQuantity),
          },
        }
        const savingLabel = formatPromotionMessage({
          ...formatArgs,
          message: defaultSaving as string,
        })

        const defaultLabel = (
          isBuyAmountGetsQuantity
            ? complexOneProductGroupAmountLabel
            : comboDifferentDiscountLabel
        ) as string
        const defaultDescription = (
          isBuyAmountGetsQuantity
            ? complexOneProductGroupAmountDescription
            : comboGetsDiferentProductDescription
        ) as string
        const promotionLabelMessage = promotion.name || defaultLabel
        const promotionDescriptionMessage =
          promotion.description || defaultDescription
        const label = formatPromotionMessage({
          ...formatArgs,
          message: promotionLabelMessage,
        })
        const description = formatPromotionMessage({
          ...formatArgs,
          message: promotionDescriptionMessage,
        })

        return {
          label,
          description,
          saving: promotion.name ? '' : savingLabel,
          image: productImage,
        }
      }
    }
  }

  return {
    label: defaultPromotionLabel,
    description: '',
  }
}
