import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import linkedProductsQuery from './query'
import { ILinkedProductsVariables, ILinkedProductsResponse } from './types'

/**
 * Hook declaration to obtain a lazy query of linked products related
 * @param options: LazyQueryHookOptions
 * @internal
 */
const useLazyLinkedProducts = (
  options?: LazyQueryHookOptions<
    ILinkedProductsResponse,
    ILinkedProductsVariables
  >,
) => {
  return useLazyQuery<ILinkedProductsResponse, ILinkedProductsVariables>(
    linkedProductsQuery,
    options,
  )
}

export { useLazyLinkedProducts }
