import { useMemo } from 'react'
import { IModalsObject } from '../../interfaces'

export const useModalsList = (modalsNames: string[], modals: IModalsObject) => {
  return useMemo(
    () =>
      modalsNames.map((name) => ({
        name,
        data: modals[name] || {},
      })),
    [modalsNames, modals],
  )
}
