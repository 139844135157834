import { gql } from '@apollo/client'
import promotionsFieldsQuery from '../../commons/fields/promotions'
import cartProductsFieldsQuery from '../../commons/fields/cartProducts'

/**
 * gets the string for `lastOrder` query
 * gets all the products to prefill cart based on the last order of the user
 * @internal
 */

const lastOrderQuery = gql`
  query LastOrder(
    $storefrontName: String!, 
    $sessionUid: ID!
  ) {
    lastOrder(storefrontName: $storefrontName, sessionUid: $sessionUid) {
      items {
        ${cartProductsFieldsQuery}
      }
      groups {
        items {
          ${cartProductsFieldsQuery}
        }
      }
    }
  }
`

export default lastOrderQuery
