import { IOptionsListProps } from '@engyalo/delivery-ui-components/lib/ui/components/OptionsList/OptionsList.types'
import { DefaultProps } from '../../../interfaces'
import { CinnamonActionNames } from '../defaultConstants'

export const OptionsListChosenFreegoods: DefaultProps<IOptionsListProps> = {
  options: '{{catalog.freegoodsConf.freegoods}}' as any,
  onOptionChange: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.CHOSEN_FREEGOODS,
        args: {
          id: '{{this.option.id}}',
          checked: '{{this.checked}}',
        },
      },
    ],
  } as any,
}
