import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import {
  CinnamonActionNames,
  DefaultModalContainerNames,
} from '../../consts/defaultConfigValues/defaultConstants'
import { CheckoutRules } from '../../interfaces'
import { generateDataDogContext } from '../../utils/dataDog'
import { nextPlaceOrderAction } from '../../redux/slices/cart'
import { closeModal } from '../../redux/slices/config'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import { useLazyCheckoutSessionRule } from '../../graphQL/queries/checkoutSessionRule'
import { ICheckoutSessionRuleResponse } from '../../graphQL/queries/checkoutSessionRule/types'
import useAppSelector from '../useAppSelector'
import useAppDispatch from '../useAppDispatch'
import useDialog from '../useDialog'

/**
 * This hook validates linked products before placing an order.
 * If the linked products are valid, it will dispatch the nextPlaceOrderAction.
 * If the linked products are not valid, it will show an alert dialog.
 */
export const useValidateLinkedProducts = () => {
  const dispatch = useAppDispatch()
  const { openDialog } = useDialog()
  const [checkoutSessionRuleQuery] = useLazyCheckoutSessionRule()

  const linkedProducts = useAppSelector(
    (state) => state.cartSlice.linkedProducts.items,
  )
  const texts = useAppSelector((state) => state.defaultSlice.config?.texts)
  const storefrontName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const sessionUid = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )

  const onCompleted = ({
    checkoutSessionRule,
  }: ICheckoutSessionRuleResponse) => {
    const { resultCheck } = checkoutSessionRule
    dispatch(setSynchronizingCart(false))
    if (resultCheck) {
      dispatch(
        closeModal({ container: DefaultModalContainerNames.LINKED_PRODUCTS }),
      )
      dispatch(nextPlaceOrderAction())
      return
    }
    // if resultCheck is false, show alert dialog
    openDialog({
      container: DefaultModalContainerNames.CHECKOUT_SESSION_RULE,
      id: 'checkout-session-rule',
      title: { value: texts?.linkedProducts?.stockErrorTitle || '' },
      message: { value: texts?.linkedProducts?.checkoutRuleFailed || '' },
      icon: { name: 'MdWarningAmber' },
      buttons: [
        {
          label: texts?.actions?.accept || '',
          actions: {
            cinnamonActions: [
              {
                name: CinnamonActionNames.CLOSE_DIALOG,
              },
            ],
          },
        },
      ],
    })
  }

  const onError = (error: ApolloError) => {
    dispatch(setSynchronizingCart(false))
    const context = generateDataDogContext({
      title: 'could not fetch checkout session rule response',
      extraInfo: { function: 'useCheckoutRuleLinkedProducts' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const validateLinkedProducts = () => {
    if (!linkedProducts || linkedProducts.length === 0) {
      dispatch(nextPlaceOrderAction())
      return
    }
    dispatch(setSynchronizingCart(true))
    checkoutSessionRuleQuery({
      fetchPolicy: 'no-cache',
      variables: {
        storefrontName,
        sessionUid,
        ruleType: CheckoutRules.CART_CONDITION_CHECK_LINKED_PRODUCTS,
      },
      onCompleted,
      onError,
    })
  }

  return { validateLinkedProducts }
}

export default useValidateLinkedProducts
