import { ItemStatus } from '@engyalo/delivery-ui-components/lib/interfaces/enums'
import { ICatalogItem, ICategoryPagination } from './catalog'
import { ICategoriesItem } from '../graphQL/queries/getCategories/types'

export enum ButtonType {
  GO_CATEGORY = 'GO_CATEGORY',
  GO_PRODUCTS = 'GO_PRODUCTS',
  GO_CATALOG = 'GO_CATALOG',
}

export interface ICategoryNavigation {
  /**
   * Category name
   */
  name: string
  /**
   * Category  id
   */
  id: string
  /**
   * URL navigate to
   */
  type: ButtonType
  /**
   * Category slug this is unique field
   */
  slug: string
  /**
   * Category image
   */
  image: string
  /**
   * Category description
   */
  category: string
}

export interface ICategoriesNav {
  categories: ICatalogItem[] | null
  categoriesNav: ICategoryNavigation[]
  categoryStatus?: ItemStatus
  fetchingMore: boolean
}

export interface IExpectedUrlParams {
  categoryId?: string
  categoryName?: string
}

export interface ITransformCategoriesArgs {
  isPaginatedCategories: boolean
  categoryId?: string
  existingCategories: ICategoryNavigation[]
  categoriesResponse: ICategoriesItem[]
  seeCategoryProductsItem: ICategoryNavigation
  seeAllCatalogItem: ICategoryNavigation
}
export interface ISetCategoriesArgs {
  pagination: ICategoryPagination
}

export interface INavigateToCategoryArgs {
  item: ICategoryNavigation
  isCategoriesView: boolean
}

export interface INavigateConditionArgs extends INavigateToCategoryArgs {
  homePath: string
  categoriesResultLength: number
  isCategoriesView: boolean
}

export interface IGetProductsNavArgs {
  categoryName: string
  forced?: boolean
}

export type PaginationForCategories = Record<
  string,
  ICategoryPagination | undefined
>
