import { ActivePromotion } from '../../graphQL/commons'
import {
  ICommonPromotionFields,
  IConfig,
  IPromotion,
  IPromotionUiCatalog,
} from '../../interfaces'
import { IFormatPromotionMessage } from '../formatPromotionMessage'

export enum DirectPromotionType {
  Individual = 'individual',
  Multiple = 'multiple',
  DifferentProduct = 'differentProduct',
}

export enum CustomerGetsDiscountType {
  Percentage = 'percentage',
  Amount = 'customerGetsAmount',
  Cash = 'customerGetsCash',
}

export enum CustomerBuysDiscountType {
  Amount = 'customerBuysAmount',
  Quantity = 'customerBuysQuantity',
}

export interface ITextsBase {
  label: string
  description: string
  defaultSaving: string
  defaultLimitLabel: string
}

export type ConditionForMap = (
  getsDiscount: CustomerGetsDiscountType,
  buysDiscount: CustomerBuysDiscountType,
  discountType: DirectPromotionType,
) => boolean
export interface IMapConditionGetType {
  condition: (promotionFields: ICommonPromotionFields) => boolean
  type: DirectPromotionType
}

export interface IMapBuysDiscountType {
  condition: (promotionFields: ICommonPromotionFields) => boolean
  type: CustomerBuysDiscountType
}

export interface IMapGetsDiscountType {
  condition: (promotionFields: ICommonPromotionFields) => boolean
  type: CustomerGetsDiscountType
}
export interface IMapTransformArgs {
  condition: ConditionForMap
  transformFunction: (
    formatArgs: IFormatPromotionMessage,
  ) => IFormatPromotionMessage
}

export interface IMapSaving {
  condition: ConditionForMap
  value: number
}

export interface IMapTransformResult {
  condition: () => boolean
  transformFunction: (result: IPromotionUiCatalog) => IPromotionUiCatalog
}

export interface IGetDirectPromotionDetailsParams {
  price: number
  config?: IConfig
  promotion: IPromotion
  activePromotion?: ActivePromotion
}
