import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import getCatalogQuery from './query'
import { IProductsPricedPerCollectionVariables, ICatalog } from './types'

/**
 * Hook declaration to obtain a lazy query of catalog data
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetCatalog = (
  options?: LazyQueryHookOptions<
    ICatalog,
    IProductsPricedPerCollectionVariables
  >,
) => {
  return useLazyQuery<ICatalog, IProductsPricedPerCollectionVariables>(
    getCatalogQuery,
    options,
  )
}
