import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import {
  ISuggestedProductsResponse,
  ISuggestedProductsVariables,
} from '../../graphQL/queries/suggestedItems/types'
import {
  setStatus,
  setSuggestedProducts,
} from '../../redux/slices/suggestedProducts'
import { generateDataDogContext } from '../../utils/dataDog'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { useLazyGetSuggestedItems } from '../../graphQL/queries/suggestedItems'
import {
  getFlattedCartItems,
  setProductsQuantity,
} from '../../utils/utilsForCatalogReducer'
import { castIProducts } from '../../utils/castICatalogItems'

export const useSuggestionItems = () => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.defaultSlice.config)
  const showSuggestedItems = useAppSelector(
    (state) => state.defaultSlice.showSuggestedItems,
  )
  const { items, groups, activePromotions } = useAppSelector(
    (state) => state.cartSlice,
  )

  const onCompleted = ({ suggestedItems }: ISuggestedProductsResponse) => {
    dispatch(setStatus('OK'))
    const { flattedCartItems } = getFlattedCartItems({
      items,
      groups,
    })
    const products = castIProducts(suggestedItems, config)

    const result = setProductsQuantity({
      products,
      flattedCartItems,
      config,
      activePromotions,
    })
    dispatch(setSuggestedProducts(result))
  }

  const onError = (error: ApolloError) => {
    const context = generateDataDogContext({
      title: 'Get suggested items data got an error',
      extraInfo: { function: 'useSuggestionItemsData > onError' },
    })
    dispatch(setStatus('ERROR'))
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }
  const [getSuggestedItems] = useLazyGetSuggestedItems({
    onCompleted,
    onError,
  })

  const fetchSuggestedItems = async ({
    storefrontName,
    sessionUid,
    limit,
  }: ISuggestedProductsVariables) => {
    try {
      // TODO: adding this validation cause the config cannot convert strings to numbers
      if (showSuggestedItems) {
        dispatch(setStatus('LOADING'))
        const parsedLimit = +limit
        await getSuggestedItems({
          variables: {
            storefrontName,
            sessionUid,
            limit: parsedLimit || 1000,
          },
        })
      }
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'Get suggested items data got an error',
        extraInfo: { function: 'getSuggestedItems' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(setStatus('OK'))
    }
  }

  return fetchSuggestedItems
}

export default useSuggestionItems
