/* eslint-disable no-plusplus */
import { ICart } from '../interfaces'

export const validateMinValues = (total: number, minAmount: number): boolean =>
  total >= minAmount

export const validateMaxValues = (total: number, maxAmount: number): boolean =>
  total <= maxAmount

export const runValidations = (list: Array<() => boolean>): boolean => {
  let isValid: boolean = true
  list.forEach((current) => {
    isValid = current()
  })
  return isValid
}

export const validateIsAlcohol = (cart: ICart) => {
  let hasAlcoholItem: boolean = false
  let acumulator: number = 0
  cart.items.forEach(({ attributes, quantity }) => {
    if (Array.isArray(attributes) && attributes.includes('alcohol')) {
      if (quantity > 0) acumulator++
      if (acumulator > 0) hasAlcoholItem = true
    }
  })

  return hasAlcoholItem
}

export const validateIsReturnable = (cart: ICart) => {
  let isReturnable: boolean = false
  let acumulator: number = 0
  cart.items.forEach(({ attributes, quantity }) => {
    if (Array.isArray(attributes) && attributes.includes('returnable')) {
      if (quantity > 0) acumulator++
      if (acumulator > 0) isReturnable = true
    }
  })

  return isReturnable
}
