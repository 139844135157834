import promotionsFieldsQuery from './promotions'
import packageFields from './packages'
/**
 * Product fields for catalog items
 * @internal
 */
const productsFieldsQuery: string = `
  id
  name
  sku
  category
  categories
  updatedAt
  createdAt
  customFields
  deactivationDate
  isActive
  divisionsByUnit
  unitDivision
  unitsPerPackage
  packageType
  unitPrice
  packagePrice
  stock
  outOfStock
  priority
  price
  imageURL
  size
  tags
  description
  categories
  attributes
  maxQtyAlert
  minQty
  maxQty
  brand
  isHidden
  createdAt
  updatedAt
  bundle {
    items
    totalItems
    minSkus
    maxSkus
    minQtyPerSku
    maxQtyPerSku
    displayOption
    label
    maxTotalQty
    minTotalQty
  }
  bundlesExtra {
    name
    sku
    stock
    price
    description
    unitsPerPackage
  }
  promotions {
    ${promotionsFieldsQuery}
  }
  extraPackages {
    ${packageFields}
  }
  hasDynamicPrice
  jumpQty
`

export default productsFieldsQuery
