import type { UnresolvedRecord } from '../interfaces'
import type { ITemplateActions } from '../interfaces/templates'
import { RootState } from '../redux/store'
import processActions from '../utils/processActions'
import processData from '../utils/processData'

const getCinnamonProps = (
  props: UnresolvedRecord,
  state: RootState,
  actions: ITemplateActions,
) => {
  const cinnamonProps = processData(props, { state })
  return processActions(cinnamonProps, actions)
}

export default getCinnamonProps
