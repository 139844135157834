import { ButtonProps } from '@engyalo/delivery-ui-components/lib/ui/components/Button'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  CinnamonActionNames,
  DefaultModalContainerNames,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'
import { PriceSimplified } from './childProps/Price'

export const ButtonDefault: DefaultProps<ButtonProps> = {}

export const ButtonFiltersCancel = deepMerge(ButtonDefault, {
  label: TextLibrary.FILTERS_MODAL_BUTTON_CANCEL,
  styles: {
    minWidth: '128px',
    background: '#FFFFFF',
    border: '1px solid #BABABA',
    color: '#2C2C2C',
    ':hover': {
      background: '#FFFFFF',
      border: '1px solid #BABABA',
      color: '#2C2C2C',
    },
  },
  onClick: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.CLOSE_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM,
        },
      },
    ],
  } as any,
})

export const ButtonFiltersClear = deepMerge(ButtonDefault, {
  label: TextLibrary.FILTERS_MODAL_BUTTON_OK,
  styles: {
    minWidth: '128px',
  },
  onClick: {
    cinnamonActions: [
      {
        name: TemplateActionNames.FILTERS_CLEAR_FILTERS,
      },
      {
        name: CinnamonActionNames.CLOSE_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM,
        },
      },
    ],
  } as any,
})

export const ButtonCartPriceDetailed: ButtonProps = {
  color: 'primary',
  label: '',
  size: 'large',
  startLabel: {
    counter: false,
    label: TextLibrary.CART_TOTAL_LABEL,
    price: {
      ...PriceSimplified,
      value: '{{cart.subtotal}}' as any,
      discount: '{{cart.total}}' as any,
      display: '{{globals.footer.priceDisplay}}',
      fee: '{{cart.totalFee}}' as any,
      feeDisplay: '{{globals.footer.feeDisplay}}',
      feeColor: 'primary.contrastText',
      discountColor: 'primary.contrastText',
    },
    styles: {
      padding: '0',
      justifyContent: 'end',
      alignItems: 'baseline',
      '.LabelStartContent:nth-child(1)': {
        textTransform: 'none',
        fontSize: '12px',
      },
      '.LabelStartContent:nth-child(2)': {
        textTransform: 'none',
        fontSize: '16px',
      },
    },
  },
  endLabel: {
    label: `{{cart.itemsTotal}} ${TextLibrary.CART_ITEMS_COUNTER}`,
    icon: 'CmArrowCircleRight',
    price: undefined,
    styles: {
      textTransform: 'none ',
    },
  },
}
