import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProduct } from '../../../interfaces'
import {
  ISuggestedProductsState,
  SuggestedItemsStatus,
} from '../../../interfaces/suggestedItems'

export const initialState: ISuggestedProductsState = {
  suggestedProducts: [],
  numberOfItems: 0,
  status: 'OK',
  hideCarousel: true,
}

// Slice and reducers
export const suggestedProductsSlice = createSlice({
  name: 'suggestedProductsSlice',
  initialState,
  reducers: {
    setSuggestedProducts(state, { payload }: PayloadAction<IProduct[]>) {
      state.suggestedProducts = payload
      state.numberOfItems = payload.length
      state.hideCarousel = payload.length === 0
    },
    setStatus(state, { payload }: PayloadAction<SuggestedItemsStatus>) {
      state.status = payload
    },
  },
})

export const { setSuggestedProducts, setStatus } =
  suggestedProductsSlice.actions

export default suggestedProductsSlice.reducer
