import { IFilter } from '@engyalo/delivery-ui-components/lib/ui/components/AdvancedFilters'

export const getActiveFilters = (
  filtersConfig: Record<string, IFilter>,
): Record<string, string[] | boolean> => {
  return Object.entries(filtersConfig).reduce(
    (checkedFilters, [filterKey, filterConfig]) => {
      const filterCheckedOptions = Object.entries(filterConfig.options).reduce(
        (checkedOptions, [, { checked, label }]) => {
          if (checked) {
            checkedOptions.push(label)
          }
          return checkedOptions
        },
        [] as string[],
      )
      if (filterCheckedOptions.length === 0) {
        return checkedFilters
      }
      return {
        ...checkedFilters,
        [filterKey]: filterCheckedOptions,
      }
    },
    {},
  )
}
