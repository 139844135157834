import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import getCategoriesQuery from './query'
import { ITotalPromotions, ITotalPromotionsVariables } from './types'

/**
 * Hook declaration to obtain a lazy query of categories data
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetTotalPromotions = (
  options?: LazyQueryHookOptions<ITotalPromotions, ITotalPromotionsVariables>,
) => {
  return useLazyQuery<ITotalPromotions, ITotalPromotionsVariables>(
    getCategoriesQuery,
    options,
  )
}
