import { useEffect, useRef, useState } from 'react'

export interface IScrollHiderProps {
  children: React.ReactNode
  offset?: number
}

/**
 * Hide its children when the user scrolls down and show them when the user reaches the top of the page.
 */
const ScrollHider = ({ children, offset = 20 }: IScrollHiderProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const childrenHeight = useRef<number>(-1)
  const [position, setPosition] = useState<number>(0)

  const handleScroll = (event: Event) => {
    const AppBody = event.target as HTMLDivElement
    const scrollPosition = AppBody?.scrollTop || 0
    if (childrenHeight.current === -1) {
      childrenHeight.current = wrapperRef.current?.offsetHeight || 0
    }

    if (scrollPosition <= offset) {
      setPosition(0)
      return
    }

    setPosition(Math.min(childrenHeight.current, scrollPosition - offset))
  }

  const style: React.CSSProperties = {
    marginTop: `-${position}px`,
    opacity: 1 - (position * 100) / childrenHeight.current / 100,
    transition: 'margin-top 0.15s ease, opacity 0.15s ease',
  }

  useEffect(() => {
    const AppBody = document.querySelector('.AppBody')
    AppBody?.addEventListener('scroll', handleScroll)

    return () => {
      AppBody?.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="ScrollHider" style={{ overflow: 'hidden' }}>
      <div className="ScrollHider-wrapper" style={style} ref={wrapperRef}>
        {children}
      </div>
    </div>
  )
}

export default ScrollHider
