import { PromotionType } from '../../graphQL/commons'
import {
  ICartProduct,
  IPromotionUiCart,
  IPromotionUiCartInput,
  ITransformCartProduct,
  TransformAction,
} from '../../interfaces'
import { getComboPromotionUiDetails } from './getComboPromotionUiDetails'
import { getComplexPromotionUiDetails } from './getComplexPromotionUiDetails'
import { getDirectPromotionUiDetails } from './getDirectPromotionUiDetails'
import { getVolumePromotionUiDetails } from './getVolumePromotionUiDetails'

export const promotionsMap: Record<
  PromotionType,
  (
    isTotalFreegods: boolean,
  ) => (args: IPromotionUiCartInput) => IPromotionUiCart | undefined
> = {
  [PromotionType.COMPLEX]: () => getComplexPromotionUiDetails,
  [PromotionType.COMBO]: () => getComboPromotionUiDetails,
  [PromotionType.DIRECT]: () => getDirectPromotionUiDetails,
  [PromotionType.VOLUME]: () => getVolumePromotionUiDetails,
  [PromotionType.TOTAL]: (isTotalFreegods) => (args: IPromotionUiCartInput) =>
    isTotalFreegods ? getComboPromotionUiDetails(args) : undefined,
}

export const getPromotionDetailsByType: TransformAction<
  ITransformCartProduct,
  ICartProduct
> = ({
  activePromotionFields: {
    fields: { type, customerGetsQuantity },
  },
  cartProduct,
  activePromotion,
  config,
  cartProducts,
}) => {
  const promotion = promotionsMap[type](!!customerGetsQuantity)({
    activePromotion,
    cartProduct,
    config,
    cartProducts,
  })!
  return {
    ...cartProduct,
    promotion: {
      ...promotion,
      label: activePromotion.label || promotion.label,
    },
  }
}
