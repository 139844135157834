import { SubItem } from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import { IProduct } from '../../interfaces'

interface IGetBundleTotalProps {
  product: IProduct
  subItem?: SubItem
}
const getBundleTotal = ({
  product: { bundlesExtra },
  subItem,
}: IGetBundleTotalProps): { total: number; subtotal: number } => {
  if (!subItem) {
    return { total: 0, subtotal: 0 }
  }

  const { items, quantity: bundleQuantity } = subItem

  const subtotal = bundlesExtra.reduce<number>((acc, { sku, price }) => {
    const validPrice = price || 0
    const quantity = items?.[sku] || 0
    return acc + validPrice * quantity
  }, 0)

  return { total: subtotal * bundleQuantity, subtotal }
}

export default getBundleTotal
