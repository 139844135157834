import { gql } from '@apollo/client'
import promotionsFieldsQuery from '../../commons/fields/promotions'
import productsFieldsQuery from '../../commons/fields/products'

/**
 * gets the string for `frequentlyBoughtTogetherQuery` query
 * gets all the products that are bought together with you current cart
 * @internal
 */

const frequentlyBoughtTogetherQuery = gql`
  query FrequentlyBoughtTogether(
    $storefrontName: String!
    $skus: [String]!
    $sessionUid: String!
  ) {
    frequentlyBoughtTogether(
      storefrontName: $storefrontName
      skus: $skus
      sessionUid: $sessionUid
    ) {
      ${productsFieldsQuery}
    }
  }
`

export default frequentlyBoughtTogetherQuery
