import { gql } from '@apollo/client'
import { cartFieldsQuery } from '../../queries/getCart/query'

/**
 * This mutation activate notification for back in stock for specific sku
 * Returns notification activated with id
 * @internal
 */
export const notifyBackInStockMutation = gql`
  mutation NotifyBackInStock(
    $storefrontName: String!
    $sessionUid: ID!
    $sku: String!
  ) {
    notifyBackInStock(
      storefrontName: $storefrontName
      sessionUid: $sessionUid
      sku: $sku
    ) {
      sku
      id
    }
  }
`

export default notifyBackInStockMutation
