import { ItemListProps } from '@engyalo/delivery-ui-components/lib/ui/components/ItemList'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  CinnamonActionNames,
  CinnamonPaths,
  DefaultModalContainerNames,
  Referrers,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'
import { CounterDefaultArgs } from './childProps/Counter'
import {
  ItemCardProduct,
  ItemCardProductRecommended,
  ItemCardCatalog,
  ItemCardPrefillCart,
  ItemCardLinkedProducts,
} from './ItemCard'

export const ItemListDefault: DefaultProps<ItemListProps> = {}

export const ItemListSearchResults = deepMerge(ItemListDefault, {
  layout: 'condensed',
  items: '{{catalog.search.items}}' as any,
  status: '{{catalog.search.status}}' as any,
  itemCard: {
    ...ItemCardProduct,
    image: {
      ...ItemCardProduct.image,
      dimensions: '{{globals.imageConfig.imageSizes.searchImage}}' as any,
    },
  },
  heading: {
    value: TextLibrary.SEARCH_RESULTS_TITLE,
    variant: 'h2',
    styles: {
      marginTop: '-8px',
    },
  },
  emptyMessage: {
    icon: {
      name: 'MdSearch',
    },
    title: TextLibrary.SEARCH_EMPTY_TITLE,
    message: TextLibrary.SEARCH_EMPTY_MESSAGE,
  },
  errorMessage: {
    icon: {
      name: 'MdOutlineErrorOutline',
    },
    title: TextLibrary.SEARCH_NO_RESULT_TITLE,
    message: TextLibrary.SEARCH_NO_RESULT_MESSAGE,
  },
})

export const ItemListRecommendedProducts = deepMerge(ItemListDefault, {
  'data-testid': 'recommended-products',
  items: '{{catalog.recommendedProducts}}' as any,
  layout: 'recommended',
  itemCard: {
    ...ItemCardProductRecommended,
    image: {
      ...ItemCardProductRecommended.image,
      dimensions:
        '{{globals.imageConfig.imageSizes.frequentlyBoughtTogetherImage}}' as any,
    },
    counter: {
      ...ItemCardProductRecommended.counter,
      minusButton: {
        ...ItemCardProductRecommended.counter?.minusButton,
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
              args: {
                ...CounterDefaultArgs,
                referrer: Referrers.FREQUENTLY_BOUGHT_TOGETHER,
              },
            },
          ],
        } as any,
      },
      plusButton: {
        ...ItemCardProductRecommended.counter?.plusButton,
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.ADD_ITEM_TO_CART,
              args: {
                ...CounterDefaultArgs,
                referrer: Referrers.FREQUENTLY_BOUGHT_TOGETHER,
              },
            },
            {
              name: TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON,
              args: {
                label: TextLibrary.RECOMMENDED_PRODUCTS_PROCEED,
              },
            },
          ],
        } as any,
      },
    },
    body: {
      ...ItemCardProductRecommended.body,
      fields: [
        'name',
        'description',
        'promotionTag',
        'promotionSavingLabel',
        'tags',
      ],
    },
    promotionTag: {
      wrap: true,
      size: 10,
    },
  },
})

export const ItemListPrefillCart = deepMerge(ItemListDefault, {
  'data-testid': 'prefill-cart-items',
  items: '{{cart.prefillCart.items}}' as any,
  layout: 'horizontal',
  itemCard: ItemCardPrefillCart,
})

export const ItemListFilteredProducts = deepMerge(ItemListDefault, {
  layout: '{{globals.catalog.layout}}' as any,
  itemCard: {
    ...ItemCardCatalog,
    image: {
      ...ItemCardCatalog.image,
      dimensions: '{{globals.imageConfig.imageSizes.filterImage}}' as any,
    },
  },
  styles: {
    '& .HeadingWrapper': {
      paddingTop: '0px',
    },
  },
  items: '{{filters.results.productsPrices}}' as any,
  runOnLoad: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_LIST,
        },
      },
    ],
  },
  status: '{{filters.status}}' as any,
  showEmptyExtraIcon: true,
  actionText: TextLibrary.FILTERS_CLEAR_BUTTON_TEXT,
  actionFunction: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_LIST,
        },
      },
    ],
  } as any,
  emptyMessage: {
    title: TextLibrary.FILTERS_EMPTY_RESULT,
    message: TextLibrary.FILTERS_EMPTY_RESULT_DESCRIPTION,
  },
})

export const ItemListSuggestedProducts = deepMerge(ItemListDefault, {
  layout: '{{globals.catalog.layout}}' as any,
  items: '{{state.suggestedProductsSlice.suggestedProducts}}' as any,
  status: '{{state.suggestedProductsSlice.status}}' as any,
  itemCard: {
    ...ItemCardCatalog,
    image: {
      ...ItemCardCatalog.image,
      dimensions:
        '{{globals.imageConfig.imageSizes.suggestedItemImage}}' as any,
    },
    counter: {
      ...ItemCardCatalog.counter,
      minusButton: {
        ...ItemCardCatalog.counter?.minusButton,
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
              args: {
                ...CounterDefaultArgs,
                referrer: 'SUGGESTED_ITEMS',
              },
            },
          ],
        } as any,
      },
      plusButton: {
        ...ItemCardCatalog.counter?.plusButton,
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.ADD_ITEM_TO_CART,
              args: {
                ...CounterDefaultArgs,
                referrer: Referrers.SUGGESTED_ITEMS,
              },
            },
          ],
        } as any,
      },
    },
  },
  runOnLoad: {
    cinnamonActions: [
      {
        name: TemplateActionNames.SUGGESTED_ITEMS_FETCH_PRODUCTS,
        args: {
          storefrontName: '{{state.defaultSlice.storeName}}',
          sessionUid: '{{state.defaultSlice.sessionId}}',
          limit:
            '{{env.REACT_APP_RECOMMENDED_ITEMS_ALL_ITEMS_LIMIT}} || {{config.globals.recommendedProducts.allRecommendations}}',
        },
      },
    ],
  },
  emptyMessage: {
    title: TextLibrary.SUGGESTED_ITEMS_EMPTY_RESULT,
    message: TextLibrary.SUGGESTED_ITEMS_EMPTY_RESULT_DESCRIPTION,
  },
  styles: {},
})

export const ItemListLinkedProducts: DefaultProps<ItemListProps> = {
  layout: 'horizontal',
  items: '{{cart.linkedProducts.items}}' as any,
  itemCard: ItemCardLinkedProducts,
}

export const ItemListCategoryNav = deepMerge(ItemListDefault, {
  styles: {
    '& .HeadingWrapper': {
      paddingTop: '0px',
    },
  },
  layout: '{{globals.catalog.layout}}' as any,
  itemCard: {
    ...ItemCardCatalog,
    image: {
      ...ItemCardCatalog.image,
      dimensions: '{{globals.imageConfig.imageSizes.catalogueImage}}' as any,
    },
  },
  items: '{{categoriesNav.category.productsPrices}}' as any,
  heading: {
    value: '{{categoriesNav.category.category}}',
  },
  status: '{{categoriesNav.categoryStatus}}' as any,
  emptyMessage: {
    icon: {
      name: 'ErrorOutline',
    },
    title: TextLibrary.EMPTY_CATEGORY_TITLE,
    message: TextLibrary.EMPTY_CATEGORY_MESSAGE,
    buttons: [
      {
        label: `${TextLibrary.EMPTY_CATEGORY_BACK_BUTTON}`,
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.GO_BACK,
              args: {
                path: `${CinnamonPaths.CATEGORIES_NAVIGATION}`,
              },
            },
          ],
        } as any,
      },
    ],
  },
})
