import linkedProductsFieldsQuery from './linkedProducts'
import promotionsFieldsQuery from './promotions'
import packageFields from './packages'
/**
 * Product fields for cart items
 */
const cartProductsFieldsQuery = `
  sku
  quantity
  promotionalQty
  price
  name
  description
  packageType
  packagePrice
  unitDivision
  unitsPerPackage
  unitPrice
  divisionsByUnit
  discount
  discounts
  imageURL
  customFields
  attributes
  stock
  totalFee
  isHidden
  maxQtyAlert
  jumpQty
  fees {
    fee
    threshold
    type
  }
  bundle {
    items
    totalItems
    minSkus
    maxSkus
    minQtyPerSku
    maxQtyPerSku
    maxTotalQty
    minTotalQty
    displayOption
    label
  }
  bundlesExtra {
    name
    sku
    stock
    price
    description
    unitsPerPackage
  }
  subItems {
    items
    quantity
    hash
  }
  promotions {
    ${promotionsFieldsQuery}
  }
  ${linkedProductsFieldsQuery}
  package {
    ${packageFields}
  }
`
export default cartProductsFieldsQuery
