/**
 * Returns a simple validation that checks if a value is a string
 * @param value: any
 * @returns true | false
 */
export function isString(value: any): value is string {
  return typeof value === 'string'
}

/**
 * Returns a simple validation that checks if a value is a number
 * @param value: any
 * @returns true | false
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number'
}

/**
 * Returns a simple validation that checks if a value is an valid object
 * @param value: any
 * @returns true | false
 */
export function isObject<T>(value: T): value is T {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

/**
 * Returns a simple validation that checks if a value is an valid array
 * @param value: any
 * @returns true | false
 */
export function isArray<T>(value: T): value is T {
  return Array.isArray(value)
}
