import { lazy, Dispatch, useEffect, useState } from 'react'
import getCinnamonProps from '../hooks/useCinnamonProps'
import { Icomponent } from '../interfaces'
import {
  IComponentWrapperProps,
  IComponentPromise,
} from '../interfaces/templates'
import ScrollHider from '../components/ScrollHider'

const importView = (route: string) =>
  lazy(() =>
    import('@engyalo/delivery-ui-components').then(
      (module: Record<string, any>) => ({ default: module[route] }),
    ),
  )

const getLoadViewsPromises = async (
  componentsList: Array<Icomponent>,
): Promise<Promise<IComponentPromise>[]> => {
  return componentsList.map(async ({ uid, importRoute, props = {} }) => {
    const View = await importView(importRoute)
    const Wrapper = ({ state, actions, parentUid }: IComponentWrapperProps) => {
      const [runnedOnloadAction, setRunnedOnLoadAction] =
        useState<boolean>(false)
      const [cinnamonProps, setCinnamonProps] = useState<any>()
      const { runOnLoad, hideOnScroll, hideOnScrollOffset, ...rest } =
        cinnamonProps || {}

      useEffect(() => {
        setCinnamonProps(getCinnamonProps(props, state, actions))
      }, [state])

      useEffect(() => {
        if (!runnedOnloadAction && runOnLoad) {
          runOnLoad()
          setRunnedOnLoadAction(true)
        }
      }, [runOnLoad, runnedOnloadAction])
      return hideOnScroll ? (
        <ScrollHider offset={hideOnScrollOffset}>
          <View id={`${uid}-${parentUid}`} {...rest} />
        </ScrollHider>
      ) : (
        <View id={`${uid}-${parentUid}`} {...rest} />
      )
    }
    return { wrapper: Wrapper, uid }
  })
}

const loadViews = async (
  componentsList: Array<Icomponent>,
  stateSetter: Dispatch<[]>,
): Promise<void> => {
  const componentPromises: Promise<IComponentPromise>[] =
    await getLoadViewsPromises(componentsList)

  Promise.all(componentPromises).then(stateSetter as any)
}

export { importView, getLoadViewsPromises }
export default loadViews
