import {
  SubItem,
  SubItems,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'

const isSubItem = (subItem: unknown): subItem is SubItem => {
  const typedObj = subItem as SubItem
  return (
    typeof typedObj.quantity === 'number' &&
    typeof typedObj.items === 'object' &&
    typeof typedObj.hash === 'string' &&
    typedObj.items !== null
  )
}

const isSubItems = (subItems: unknown): subItems is SubItems => {
  const typedObj = subItems as SubItems
  return (
    Array.isArray(typedObj) &&
    typedObj.every((item) => isSubItem(item) as boolean)
  )
}

export { isSubItem, isSubItems }
