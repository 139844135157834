import { ICart, ICartProduct, IProduct } from '../interfaces'

export interface AnalyticsPurchaseItem {
  item_id: string
  item_name: string
  discount?: number
  price: number
  quantity: number
  index: number
}

const getAnalyticsItems = (cart: ICart): AnalyticsPurchaseItem[] => {
  const items = cart.items.map(parseAnalyticsItems)
  return items
}

const getAnalyticsItem = (item: IProduct): AnalyticsPurchaseItem => {
  return parseAnalyticProductItem(item, 0)
}

const parseAnalyticProductItem = (
  item: IProduct,
  index: number,
): AnalyticsPurchaseItem => {
  return {
    item_id: item.sku,
    item_name: item.name,
    price: item.price || 0,
    quantity: item.quantity || 1,
    index,
  }
}

const parseAnalyticsItems = (
  item: ICartProduct,
  index: number,
): AnalyticsPurchaseItem => {
  return {
    item_id: item.sku,
    item_name: item.name,
    discount: item.discounts || 0,
    price: item.price,
    quantity: item.quantity,
    index,
  }
}

export default getAnalyticsItems
export { getAnalyticsItem }
