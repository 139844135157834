import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import { DefaultModalContainerNames } from '../../consts/defaultConfigValues/defaultConstants'
import useActivateDeferredPromotion from '../../graphQL/mutations/activateDeferredPromotion/hook'
import { IActivateDeferredPromotionResponse } from '../../graphQL/mutations/activateDeferredPromotion/types'
import {
  setAvailableFreegoods,
  setSynchronizingCart,
} from '../../redux/slices/catalog'
import {
  getAlertErrorMessage,
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { onCartResponse } from '../../utils/onCartResponse'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import useModal from '../useModal'
import { addAlert } from '../../redux/slices/config'
import { generateDataDogContext } from '../../utils/dataDog'
import { PromotionSelectionCriteria } from '../../graphQL/commons'

export const useChosenFreeGoods = () => {
  const { closeModal } = useModal()
  const dispatch = useAppDispatch()

  const availableFreegoods = useAppSelector(
    (stateRedux) => stateRedux.catalogSlice.freegoodsConf?.freegoods || [],
  )
  const promotionUid = useAppSelector(
    (stateRedux) => stateRedux.catalogSlice.freegoodsConf?.promotion?.id || '',
  )
  const state = useAppSelector((stateRedux) => stateRedux)
  const { config } = state.defaultSlice
  const sessionUid = useAppSelector(
    (stateRedux) => stateRedux.defaultSlice.sessionId || '',
  )
  const storefrontName = useAppSelector(
    (stateRedux) => stateRedux.defaultSlice.storeName || '',
  )

  const onCompleted = (data: IActivateDeferredPromotionResponse) => {
    if (data.activateDeferredPromotion) {
      onCartResponse({
        data: data.activateDeferredPromotion,
        state,
        dispatch,
      })
      closeModal({ container: DefaultModalContainerNames.CHOSEN_FREEGOODS })
    }
  }

  const onError = (error: ApolloError) => {
    const message = getAlertErrorMessage(
      error.networkError ? AlertMessageType.Network : AlertMessageType.AddItem,
      config?.texts,
    )
    dispatch(addAlert({ message }))
    const context = generateDataDogContext({
      title: 'Add product mutation error',
      extraInfo: { function: 'useAddItemToCart > onError' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [activateDeferredPromotion, { data, loading, error }] =
    useActivateDeferredPromotion({
      onError,
      onCompleted,
    })

  const chosenFreegoods = ({ id }: { id: string }) => {
    try {
      dispatch(setSynchronizingCart(true))

      const newAvailableFreegoods = availableFreegoods.map((item) => ({
        ...item,
        isChecked: item.id === id,
      }))
      dispatch(
        setAvailableFreegoods({
          freegoods: newAvailableFreegoods,
          isConfirmDisabled: !newAvailableFreegoods.some(
            ({ isChecked }) => isChecked,
          ),
        }),
      )
    } finally {
      dispatch(setSynchronizingCart(false))
    }
  }

  const confirmChosenFreegoods = async ({
    selectBy,
  }: {
    selectBy: PromotionSelectionCriteria
  }) => {
    const selectedGroup = availableFreegoods.find((item) => item.isChecked)

    if (
      selectBy === PromotionSelectionCriteria.CUSTOMER_SELECTION &&
      !selectedGroup
    ) {
      return
    }

    const selectedGroupIdentifier =
      selectBy === PromotionSelectionCriteria.CUSTOMER_SELECTION
        ? selectedGroup?.id
        : null
    await activateDeferredPromotion({
      variables: {
        sessionUid,
        storefrontName,
        data: {
          selectBy,
          selectedGroupIdentifier: selectedGroupIdentifier || undefined,
          promotionUid,
        },
      },
    })
  }

  return { chosenFreegoods, confirmChosenFreegoods }
}
