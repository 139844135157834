import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import type { IPrefillCartVariables, IPrefillCartResponse } from './types'
import prefillCartQuery from './query'

/**
 * Hook declaration to obtain a lazy query of prefill cart products
 * @param options: LazyQueryHookOptions
 * @returns
 */
const useLazyGetPrefillCart = (
  options?: LazyQueryHookOptions<IPrefillCartResponse, IPrefillCartVariables>,
) => {
  return useLazyQuery<IPrefillCartResponse, IPrefillCartVariables>(
    prefillCartQuery,
    options,
  )
}

export { useLazyGetPrefillCart }
