import { IPromotionUiCart, IPromotionUiCartInput } from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import formatPercentage from '../formatPercentage'

export const getComplexDirectPromotionUiDetails = ({
  activePromotion,
  cartProduct,
  config,
}: IPromotionUiCartInput): IPromotionUiCart | undefined => {
  const texts = config?.texts?.cartPromotions

  const {
    fields: { limitQuantity, type, customerGetsGroups, customerBuysGroups },
    promotion,
    count,
  } = getActivePromotionsFields(activePromotion)

  const [getsFirstGroup] = customerGetsGroups
  const [buysFirstGroup] = customerBuysGroups

  const { price, discounts, quantity: cartProductQuantity } = cartProduct
  const validDiscounts = discounts || 0

  const itemRelated = getsFirstGroup.items.find(
    ({ sku }) => sku === cartProduct.sku,
  )

  const itemRelatedBuys = buysFirstGroup.items.find(
    ({ sku }) => sku === cartProduct.sku,
  )

  const itemRelatedQuantity = (itemRelated?.quantity || 0) * count
  const itemRelatedQuantityBuys = (itemRelatedBuys?.quantity || 0) * count

  const productsWithDiscount = itemRelatedQuantity || itemRelatedQuantityBuys
  const promotionPercentageFormat = config?.options?.promotionPercentageFormat

  const percentage = formatPercentage(
    itemRelated?.percentage,
    promotionPercentageFormat,
  )

  const {
    directProductsLabel = '',
    defaultSaving = '',
    defaultLimitLabel = '',
  } = texts || {}

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving: discounts,
    amount: discounts,
    placeholders: {
      discount: percentage,
      count: String(productsWithDiscount),
    },
  }

  const result: IPromotionUiCart = {
    label: formatPromotionMessage({
      ...formatArgs,
      message: directProductsLabel as string,
    }),
    saving: formatPromotionMessage({
      ...formatArgs,
      message: defaultSaving as string,
    }),
    type,
  }

  if (limitQuantity) {
    result.limit = formatPromotionMessage({
      ...formatArgs,
      limitQuantity,
      message: defaultLimitLabel as string,
    })
  }

  if (validDiscounts) {
    const value = price - validDiscounts / cartProductQuantity
    result.value = value
  }

  result.label = formatPromotionMessage({
    ...formatArgs,
    message: directProductsLabel as string,
  })

  return result
}
