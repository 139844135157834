import { SideContentMap } from '@engyalo/delivery-ui-components/lib/interfaces/sideContent'
import { TextLibrary } from '../defaultConstants'

export const sideContentMap: SideContentMap['map'] = {
  returnable: {
    props: {
      icon: 'MdAutorenew',
      label: TextLibrary.TAGS_SHORT_RETURNABLE,
    },
  },
  alcohol: {
    props: {
      icon: 'MdLocalBar',
      label: TextLibrary.TAGS_SHORT_ALCOHOL,
    },
  },
}
