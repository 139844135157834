import { useLazyGetCatalog } from '../../graphQL/queries/getCatalog'
import { useAppDispatch, useAppSelector } from '..'
import {
  fetchProducts,
  fetchProductsResponse,
  fetchProductsResponseError,
} from '../../utils/fetchProducts'
import { ICatalog } from '../../graphQL/queries/getCatalog/types'
import { castICatalogItems } from '../../utils/castICatalogItems'
import { sanitizeSlug } from '../../utils/sanitize'

interface IFetchProductsArgs {
  categoryName: string
}

const useProductsLazyLoader = () => {
  const dispatch = useAppDispatch()
  const sessionId = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )
  const storeName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const config = useAppSelector((state) => state.defaultSlice.config)
  const existingCategories = useAppSelector(
    (state) => state.catalogSlice.categories,
  )
  const pagination = useAppSelector((state) => state.catalogSlice.pagination)
  const cartItems = useAppSelector((state) => state.cartSlice.items)
  const groups = useAppSelector((state) => state.cartSlice.groups)
  const activePromotions = useAppSelector(
    (state) => state.cartSlice.activePromotions,
  )
  const currentCategory = useAppSelector(
    (state) => state.catalogSlice.currentCategory,
  )

  const onCompleted = (data: ICatalog) => {
    const categoriesResponse = castICatalogItems(data.catalog, config)
    fetchProductsResponse({
      categoriesResponse,
      currentCategory,
      dispatch,
      config,
      existingCategories,
      cartItems,
      groups,
      activePromotions,
    })
  }

  const [getCatalog] = useLazyGetCatalog({
    onError: fetchProductsResponseError,
    onCompleted,
  })

  const fetchProductsCallback = ({ categoryName }: IFetchProductsArgs) => {
    const currentPagination = pagination[sanitizeSlug(categoryName)] || {}
    const { hasMore = false } = currentPagination
    if (hasMore) {
      fetchProducts({
        storeName,
        sessionId,
        pagination,
        categoryName,
        dispatch,
        getCatalog,
      })
    }
  }

  return fetchProductsCallback
}

export default useProductsLazyLoader
