import { useEffect, useState } from 'react'
import { Snackbar } from '@engyalo/delivery-ui-components'
import { useAppDispatch, useAppSelector } from '../hooks'
import { removeAlert } from '../redux/slices/config'

const Alert = () => {
  const dispatch = useAppDispatch()
  const [alert] = useAppSelector((state) => state.defaultSlice.alerts)
  const [open, setOpen] = useState(false)
  const {
    origin = 'top',
    severity = 'error',
    closeOnClick = true,
    autoHideDuration = 4000,
    ...alertProps
  } = alert || {}

  const handleOnClose = (
    _event: React.SyntheticEvent | Event,
    reason: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    setTimeout(() => {
      dispatch(removeAlert())
    }, 300)
  }

  useEffect(() => {
    if (alert?.key) {
      setOpen(true)
    }
  }, [alert?.key])

  return (
    <Snackbar
      open={open}
      origin={origin}
      severity={severity}
      autoHideDuration={autoHideDuration}
      closeOnClick={closeOnClick}
      onClose={handleOnClose}
      {...alertProps}
    />
  )
}

export default Alert
