import { CarouselProps } from '@engyalo/delivery-ui-components/lib/ui/components/Carousel'
import { HeaderListTypes } from '@engyalo/delivery-ui-components/lib/ui/components/HeaderList/HeaderList.types'
import { DefaultProps } from '../../../interfaces'
import {
  TemplateActionNames,
  TextLibrary,
  CinnamonActionNames,
  CinnamonPaths,
  Referrers,
} from '../defaultConstants'
import { CounterDefaultArgs } from './childProps/Counter'
import { ItemCardCart, ItemCardProductRecommendedCarousel } from './ItemCard'

export const CarouselBanners: DefaultProps<CarouselProps> = {
  hideOnScroll: true,
  images: '{{banners.banners}}' as any,
  imageActions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATALOG_BANNERS_CLICK,
        args: {
          data: '{{this.data}}',
          navigateToCategory: '{{config.options.usePaginatedCategories}}',
        },
      },
    ],
  } as any,
  slidesPerView: 1,
  image: { size: '100%', ratio: 50, cover: true, radius: 4 } as any,
  'data-testid': 'banners',
  styles: {
    margin: '0 8px',
    width: 'auto',
    '.swiper-pagination': {
      position: 'sticky',
    },
  },
}

export const CarouselRecomendedProducts: DefaultProps<CarouselProps> = {
  items: '{{state.suggestedProductsSlice.suggestedProducts}}' as any,
  itemCardOrientation: 'horizontal',
  slidesPerView: 1.08,
  spaceBetweenSlides: 8,
  runOnLoad: {
    cinnamonActions: [
      {
        name: TemplateActionNames.SUGGESTED_ITEMS_FETCH_PRODUCTS,
        args: {
          storefrontName: '{{state.defaultSlice.storeName}}',
          sessionUid: '{{state.defaultSlice.sessionId}}',
          limit:
            '{{env.REACT_APP_RECOMMENDED_ITEMS_QUANTITY}} || {{config.globals.recommendedProducts.widgetHome}}',
        },
      },
    ],
  },
  itemCard: ItemCardProductRecommendedCarousel,
  styles: {
    margin: '4px',
    width: 'auto',
    marginRight: '-5px',
    '.swiper-pagination': {
      position: 'sticky',
    },
    '@media(max-width: 374px)': {
      padding: 0,
    },
  },
}

export const CarouselRecomendedProductsHead: DefaultProps<HeaderListTypes> = {
  label: TextLibrary.RECOMMENDED_PRODUCTS_HEADER_TITLE,
  emoji: '🔥',
  iconLabelPosition: 'after',
  buttonHeader: {
    label: TextLibrary.RECOMMENDED_PRODUCTS_HEADER_BUTTON,
    onClick: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.PUSH_VIEW,
          args: {
            path: `${CinnamonPaths.HOME}/recommended-products`,
            category: '{{catalog.categoryList}}',
          },
        },
      ],
    } as any,
  },
  hidden: '{{state.suggestedProductsSlice.hideCarousel}}' as any,
  styles: {
    marginLeft: '4px',
    '.MuiTypography-root': {
      fontWeight: 600,
    },
  },
}
