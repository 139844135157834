import { datadogRum } from '@datadog/browser-rum'
import { RemoveProductParams } from '../redux/slices/cart/types'
import { setSynchronizingCart } from '../redux/slices/catalog'
import { generateDataDogContext } from './dataDog'

const onRemoveProduct = async ({
  sessionId,
  storeName,
  removeProductFunction,
  sku,
  quantity,
  dispatch,
  referrer,
  signal,
  subItems,
  replace,
  packageName,
}: RemoveProductParams) => {
  try {
    dispatch(setSynchronizingCart(true))

    await removeProductFunction({
      variables: {
        cartRemoveProductSessionUid: sessionId,
        cartRemoveProductStorefrontName: storeName,
        cartRemoveProductData: {
          sku,
          quantity,
          replace,
          referrer: referrer || undefined,
          subItems,
          package: packageName,
        },
      },
      context: { fetchOptions: { signal } },
    })
  } catch (error) {
    const context = generateDataDogContext({
      title: 'could not remove product from cart',
      extraInfo: { sku, quantity, function: 'onRemoveProduct' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  } finally {
    dispatch(setSynchronizingCart(false))
  }
}

export default onRemoveProduct
