import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { IFilter } from '@engyalo/delivery-ui-components/lib/ui/components/AdvancedFilters'
import { useLazySearchProduct } from '../../graphQL/queries/searchProducts'
import {
  MAX_PAGINATION_SIZE,
  useLazyGetFiltersValues,
} from '../../graphQL/queries/getFiltersValues'
import {
  setActiveFiltersCount,
  setFiltersConfig,
  clearFilters,
  setFilterCheckboxValue,
  clearCategoryFilters,
  setFilterResults,
  setTags,
  setStatus,
} from '../../redux/slices/filters'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { buildFilterOptions } from '../../utils/filtersArrayToRecord'
import { getActiveFilters } from '../../utils/getActiveFilters'
import { generateDataDogContext } from '../../utils/dataDog'
import { ISearchProduct } from '../../graphQL/queries/searchProducts/types'
import { IProduct } from '../../interfaces'
import {
  getFlattedCartItems,
  setProductsQuantity,
} from '../../utils/utilsForCatalogReducer'
import { IGetFiltersValuesResponse } from '../../graphQL/queries/getFiltersValues/types'

export interface SetFilterTypeData {
  filterName: string
  checkboxName: string
  checked: boolean
}

export const useSetFilters = () => {
  const dispatch = useAppDispatch()

  return ({ data }: { data: SetFilterTypeData }) => {
    dispatch(setFilterCheckboxValue(data))
  }
}
interface ISetFilterCategoryArgs {
  data: SetFilterTypeData
  usePaginatedCategories: boolean
}
export const useSetFilterCategory = (): ((
  args: ISetFilterCategoryArgs,
) => void) => {
  const dispatch = useAppDispatch()

  return ({ data, usePaginatedCategories }: ISetFilterCategoryArgs) => {
    if (usePaginatedCategories) {
      dispatch(clearCategoryFilters())
      dispatch(setFilterCheckboxValue(data))
    }
  }
}

export const useClearFilters = () => {
  const dispatch = useAppDispatch()
  return () => {
    dispatch(clearFilters())
  }
}

export const useFetchFilterResults = () => {
  const dispatch = useAppDispatch()

  const sessionId = useAppSelector((state) => state.defaultSlice.sessionId)
  const storeName = useAppSelector((state) => state.defaultSlice.storeName)
  const filtersConfig = useAppSelector(
    (state) => state.filtersSlice.filtersConfig,
  )

  const cartItems = useAppSelector((state) => state.cartSlice.items)

  const groups = useAppSelector((state) => state.cartSlice.groups)
  const activePromotions = useAppSelector(
    (state) => state.cartSlice.activePromotions,
  )

  const config = useAppSelector((state) => state.defaultSlice.config)
  const history = useHistory()

  const onCompleted = (data: ISearchProduct) => {
    const items = data?.searchProduct as unknown as IProduct[]

    const activeFilters = getActiveFilters(filtersConfig)
    const tags = Object.entries(activeFilters).reduce(
      (filters, [, checkedFilters]) => {
        if (typeof checkedFilters !== 'boolean') {
          return [...filters, ...checkedFilters]
        }
        return filters
      },
      [] as string[],
    )

    const { flattedCartItems } = getFlattedCartItems({
      items: cartItems,
      groups: groups ?? [],
    })

    if (items) {
      dispatch(
        setFilterResults({
          productsPrices: setProductsQuantity({
            products: items,
            flattedCartItems,
            config,
            activePromotions,
          }),
          slug: ' ',
        }),
      )
      dispatch(setTags(tags))
    }
    dispatch(setStatus('OK'))
  }

  const onError = (error: ApolloError) => {
    const context = generateDataDogContext({
      title: 'could not fetch filtered products response',
      extraInfo: { function: 'fetchFilteredProductsResponse' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
    dispatch(setStatus('ERROR'))
  }
  const [searchProduct] = useLazySearchProduct({ onError, onCompleted })

  const getFilterResults = async () => {
    const activeFilters = getActiveFilters(filtersConfig)
    if (Object.keys(activeFilters).length === 0) {
      dispatch(clearFilters())
    }
    const activeFilterCount = Object.entries(activeFilters).reduce(
      (count, [, filter]) => {
        if (typeof filter === 'boolean') {
          return count + 1
        }
        return count + filter.length
      },
      0,
    )
    // @Todo: remove this when promotions is a list of strings and not boolean
    if (
      activeFilters.promotions &&
      typeof activeFilters.promotions !== 'boolean'
    ) {
      activeFilters.promotions = activeFilters.promotions?.length <= 1
    }
    dispatch(setActiveFiltersCount(activeFilterCount))
    if (activeFilterCount === 0) {
      history.push(`/${storeName}/${sessionId}`)
    } else {
      dispatch(setStatus('LOADING'))
      searchProduct({
        variables: {
          sessionId: sessionId || '',
          storefrontName: storeName || '',
          filter: activeFilters,
        },
      })
    }
  }
  return { getFilterResults }
}

export const useAdvancedFilters = () => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.defaultSlice.config)
  const storeName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionData = useAppSelector((state) => state.defaultSlice.sessionData)

  const onCompleted = (data: IGetFiltersValuesResponse) => {
    const { categories, brands } = data

    const filtersSession = sessionData?.configuration?.filters

    const filtersConfig: Record<string, IFilter> = {}
    if (
      filtersSession?.categories.active ||
      config?.options?.usePaginatedCategories
    ) {
      filtersConfig.categories = {
        uid: 'categories',
        title: 'Categories',
        type: 'checkbox',
        options: buildFilterOptions(categories),
        disabled: config?.options?.usePaginatedCategories || false,
      }
    }
    if (filtersSession?.brands.active) {
      filtersConfig.brands = {
        uid: 'brands',
        title: 'Brands',
        type: 'checkbox',
        options: buildFilterOptions(brands),
        disabled: false,
      }
    }
    if (filtersSession?.attributes?.length) {
      filtersConfig.attributes = {
        uid: 'attributes',
        title: 'Attributes',
        type: 'checkbox',
        options: filtersSession?.attributes.reduce((acc, label) => {
          return {
            ...acc,
            [label]: {
              label,
              checked: false,
            },
          }
        }, {}),
        disabled: false,
      }
    }
    if (filtersSession?.promotions.active) {
      // @Todo: remove this when filters are fully implemented by BE
      filtersConfig.promotions = {
        uid: 'promotions',
        title: 'Promotions',
        type: 'checkbox',
        options: {
          All: {
            label: 'All',
            checked: false,
          },
        },
        disabled: false,
      }
    }

    dispatch(setFiltersConfig(filtersConfig))
  }

  const onError = (error: ApolloError) => {
    const context = generateDataDogContext({
      title: 'could not fetch filters',
      extraInfo: { function: 'useAdvancedFilters' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [getFiltersValues] = useLazyGetFiltersValues({ onError, onCompleted })

  return async () => {
    try {
      await getFiltersValues({
        variables: {
          storefrontName: storeName || '',
          pagination: {
            pageSize: MAX_PAGINATION_SIZE,
          },
        },
      })
    } catch (exception) {
      datadogRum.addError(exception, {
        where: 'cinnamon > fetchFiltersOptions',
      })
    }
  }
}
