import { TextProps } from '@engyalo/delivery-ui-components/lib/ui/components/Text'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  CinnamonActionNames,
  DefaultModalContainerNames,
  TextLibrary,
} from '../defaultConstants'

export const TextDefault: DefaultProps<TextProps> = {}
export const TextFilters = deepMerge(TextDefault, {
  value: TextLibrary.FILTERS_CLEAR_BUTTON_TEXT,
  styles: {
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
    background: '#F7F9F9',
    paddingBottom: '30px',
    userSelect: 'none',
  },
  onClick: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_MODAL,
        args: {
          container: DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM,
        },
      },
    ],
  } as any,
})

export const TextFiltersClearTitle = deepMerge(TextDefault, {
  value: TextLibrary.FILTERS_MODAL_CLEAR_TITLE,
  styles: {
    align: 'center',
    size: 16,
    fontWeight: 700,
  },
})

export const RestrictionsText = deepMerge(TextDefault, {
  value: TextLibrary.CART_RESTRICTIONS_LABEL,
  styles: {
    textAlign: 'center',
    background: '#F7F9F9',
    padding: '12px 0 12px 0',
    userSelect: 'none',
  },
  hidden: '{{config.options.hideCartRestrictions}}' as any,
})
