import numberFormat from 'number-format.js'

interface FormatPriceProps {
  currency?: string
  price: number
  format?: string
  prefix?: string
  suffix?: string
  after?: boolean
  display?: string
  code?: string
}

const replaceHandlebars = (content = '', symbol = '', code = ''): string => {
  if (content && content.length < 1) {
    return ''
  }
  let newContent = content
  if (newContent.includes('{{symbol}}'))
    newContent = newContent.replace('{{symbol}}', symbol)
  if (newContent.includes('{{code}}'))
    newContent = newContent.replace('{{code}}', code)
  return newContent
}

const formatPrice = ({
  currency = '',
  price,
  format = '#,##0.00',
  prefix = '',
  suffix = '',
  after = false,
  code = '',
  display = '{{symbol}}{{price}}{{code}}',
}: FormatPriceProps): string => {
  if (format.length === 0) {
    return ''
  }

  let prefixContent = ''
  let suffixContent = ''
  // TODO: this code should be remove when all clients use display
  if (!!suffix || !!prefix) {
    prefixContent = `${prefix} ${!after ? currency : ''}`
    suffixContent = `${suffix} ${after ? currency : ''}`
  } else {
    const priceDisplay =
      display.length > 0
        ? replaceHandlebars(display, currency, code)
        : replaceHandlebars('{{symbol}}{{price}}{{code}}', currency, code)
    const displayWithoutPrice = priceDisplay.split('{{price}}')
    const [beforePriceContent, afterPriceContent] = displayWithoutPrice
    prefixContent = beforePriceContent
    suffixContent = afterPriceContent
  }
  const formattedPrice = numberFormat(
    `${prefixContent}${format}${suffixContent}`,
    price,
    { enforceMaskSign: true },
  )
  return formattedPrice
}

export default formatPrice
