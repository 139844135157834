import { useDispatch } from 'react-redux'
import { setShow } from '../../redux/slices/globalPromotions'

const useCloseGlobalPromotion = () => {
  const dispatch = useDispatch()

  return () => {
    dispatch(setShow(false))
  }
}

export default useCloseGlobalPromotion
