import { DropdownMenuProps } from '@engyalo/delivery-ui-components/lib/ui/components/DropdownMenu'
import { DefaultProps } from '../../../interfaces'
import {
  CinnamonActionNames,
  CinnamonPaths,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'

export const NavMenuDefault: DefaultProps<DropdownMenuProps> = {
  options: '{{catalog.categoryList}}' as any,
  value: '{{catalog.currentCategoryCatalog}}',
  changeActions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATALOG_SCROLL_TO_CATEGORY,
        args: {
          category: '{{this.newValue}}',
        },
      },
    ],
  } as any,
  heading: {
    hidden: '{{config.options.hideSubcategories}}',
    label: TextLibrary.CATEGORIES_LABEL,
    iconHeader: {
      iconButton: {
        text: {
          marginRight: '4px',
          value: TextLibrary.SEE_ALL_CATEGORIES_BUTTON,
          fontSize: '12px',
          color: 'text.primary',
        },
        icon: 'CmSquareArrowUpRight',
        iconset: 'cm',
        onClick: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.PUSH_VIEW,
              args: {
                path: CinnamonPaths.CATEGORIES_NAVIGATION,
              },
            },
          ],
        },
        styles: {
          fontSize: 16,
          padding: '0 8px 0 4px',
        },
      },
      firstOnly: true,
    },
  },
}
