import { IContainers, IModal } from '../../interfaces'
import { DefaultModalContainerNames } from './defaultConstants'

export const DEFAULT_CONTAINERS_PROPS: Record<string, Partial<IContainers>> = {
  FilteredProducts: {
    body: {
      props: {
        gap: 6,
        styles: {
          '& > div': {
            gridTemplateRows: 'max-content',
          },
        },
      },
      components: [],
    },
  },
  CategoryDetail: {
    body: {
      props: {
        gap: 6,
        styles: {
          '& > div': {
            gridTemplateRows: 'max-content',
          },
        },
      },
      components: [],
    },
  },
  ShoppingCart: {
    body: {
      props: {
        gap: 6,
        styles: {
          padding: '16px',
          '& > div': {
            gridTemplateRows: 'max-content',
          },
          '& .MuiCollapse-root.MuiCollapse-hidden': {
            display: 'none',
          },
        },
      },
      components: [],
    },
  },
  SuggestedProducts: {
    body: {
      props: {
        styles: {
          '& > div': {
            gridTemplateRows: 'max-content',
          },
        },
      },
      components: [],
    },
  },
  CatalogueDefault: {
    header: {
      props: {
        styles: {
          '& .MuiCollapse-root': {
            backgroundColor: '#F7F9F9',
          },
        },
      },
      components: [],
    },
  },
  CartSummary: {
    body: {
      props: {
        styles: {
          padding: '16px',
        },
      },
      components: [],
    },
  },
  TestContainer: {
    header: {
      props: {
        radius: 8,
      },
      components: [],
    },
  },
}

export const DEFAULT_MODALS_PROPS: Record<string, Partial<IModal>> = {
  [DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM]: {
    props: {
      fullScreen: false,
      styles: {
        zIndex: '1500',
      },
      align: 'bottom',
    },
    header: {
      props: {
        show: false,
      },
      components: [],
    },
    body: {
      props: {
        styles: {
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
          paddingTop: '32px',
        },
      },
      components: [],
    },
    footer: {
      props: {
        styles: {
          padding: '32px 0',
          boxShadow: 'none',
          display: 'flex',
          justifyContent: 'space-around',
        },
      },
      components: [],
    },
  },
  [DefaultModalContainerNames.PRODUCT_DETAIL]: {
    props: {
      padding: '56px 0 0 0',
      align: 'bottom',
      radius: '18px 18px 0 0',
      fullScreen: false,
    },
    header: {
      props: {
        show: false,
      },
      components: [],
    },
    body: {
      props: {
        padding: 0,
        styles: {
          bgcolor: 'background.paper',
        },
      },
      components: [],
    },
  },
  [DefaultModalContainerNames.CHOSEN_FREEGOODS]: {
    props: {
      padding: '56px 0 0 0',
      align: 'bottom',
      radius: '18px 18px 0 0',
      fullScreen: false,
    },
    header: {
      props: {
        styles: {
          boxShadow: 'none',
          paddingTop: '16px',
        },
      },
      components: [],
    },
    body: {
      props: {
        padding: '12px 14px 12px 14px',
        styles: {
          bgcolor: 'background.paper',
        },
      },
      components: [],
    },
  },
  [DefaultModalContainerNames.RECOMMENDED_PRODUCTS]: {
    props: {
      padding: '56px 0 0 0',
      align: 'bottom',
      radius: '18px 18px 0 0',
      styles: {
        zIndex: '1500',
      },
      fullScreen: false,
    },
    header: {
      props: {
        elevation: 0,
        styles: {
          bgcolor: 'background.default',
        },
      },
      components: [],
    },
    body: {
      props: {
        padding: '12px',
      },
      components: [],
    },
    footer: {
      props: {
        elevation: 0,
        styles: {
          bgcolor: 'background.default',
        },
      },
      components: [],
    },
  },
  [DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL]: {
    props: {
      padding: 16,
      radius: 8,
      align: 'center',
      fullScreen: false,
      styles: {
        zIndex: '1501',
      },
    },
    header: {
      props: {
        show: false,
      },
      components: [],
    },
    body: {
      props: {
        padding: 0,
        gap: 4,
        styles: {
          bgcolor: 'background.paper',
        },
      },
      components: [],
    },
  },
  [DefaultModalContainerNames.PREFILL_CART]: {
    props: {
      padding: '56px 0 0 0',
      align: 'bottom',
      radius: '16px 16px 0 0',
      fullScreen: false,
      styles: {
        zIndex: '1501',
      },
    },
    header: {
      props: {
        elevation: 0,
        styles: {
          bgcolor: 'background.default',
        },
      },
      components: [],
    },
    body: {
      props: {
        props: {
          padding: '12px',
        },
      },
      components: [],
    },
    footer: {
      props: {
        elevation: 0,
        styles: {
          bgcolor: 'background.default',
        },
      },
      components: [],
    },
  },
  [DefaultModalContainerNames.LINKED_PRODUCTS]: {
    props: {
      padding: '56px 0 0 0',
      align: 'bottom',
      radius: '16px 16px 0 0',
    },
    header: {
      props: {
        elevation: 0,
        styles: {
          bgcolor: 'background.default',
          marginBottom: '8px',
        },
      },
      components: [],
    },
    footer: {
      props: {
        elevation: 0,
        styles: {
          bgcolor: 'background.default',
        },
      },
      components: [],
    },
  },
}
