import ReactGA from 'react-ga4'

const sendGoogleEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  })
}

export { sendGoogleEvent }
