import { ActivePromotion, PromotionType } from '../../graphQL/commons'
import {
  IConfig,
  IPromotion,
  IProduct,
  ICartProduct,
  IPromotionUiCatalog,
} from '../../interfaces'
import { getComboPromotionUiDetails } from './getComboPromotionUiDetails'
import { getDirectPromotionUiDetails } from './getDirectPromotionUiDetails'
import { getVolumePromotionUiDetails } from './getVolumePromotionUiDetails'
import sortByKey from '../sort'
import { getComplexPromotionUiDetails } from './getComplexPromotionUiDetails'
import {
  getActivePromotionsFields,
  getPromotionFields,
} from '../common/getPromotionFields'
import { getMultiPromotionTag } from './getMultiPromotionUiDetails'

/**
 * Function declaration to set promotions ui details to be shown on catalog
 *
 * @internal
 * @param product - The product to be updated
 * @param categories - The categories
 * @returns IProduct updated with promotion ui details
 */
export const setPromotionUiDetailsCatalog = ({
  product,
  config,
  activePromotions,
}: {
  product: IProduct | ICartProduct
  config?: IConfig
  activePromotions?: ActivePromotion[]
}): IPromotionUiCatalog | undefined => {
  if (product.promotions?.length && config) {
    const sortedPromotions = sortByKey<IPromotion>(
      product.promotions,
      'priority',
    )

    if (sortedPromotions.length > 1) {
      return getMultiPromotionTag(config)
    }

    const [promotionToBeDisplayed] = sortedPromotions

    const [activePromotion] =
      activePromotions?.filter((currentActivePromotion) => {
        const {
          fields: { customerGetsItems },
        } = getActivePromotionsFields(currentActivePromotion)
        const { customerGetsItems: customerGetsItemsToBeDisplayed } =
          getPromotionFields(promotionToBeDisplayed)

        const [customerGetsItem] = customerGetsItems
        const [customerGetsItemToBeDisplayed] = customerGetsItemsToBeDisplayed

        return customerGetsItem === customerGetsItemToBeDisplayed
      }) || []
    switch (promotionToBeDisplayed.type) {
      case PromotionType.DIRECT:
        return getDirectPromotionUiDetails({
          price: product.price || 0,
          config,
          promotion: promotionToBeDisplayed,
          activePromotion,
        })
      case PromotionType.VOLUME:
        return getVolumePromotionUiDetails({
          product,
          config,
          promotion: promotionToBeDisplayed,
          activePromotion,
        })
      case PromotionType.COMPLEX:
        return getComplexPromotionUiDetails({
          promotion: promotionToBeDisplayed,
          config,
          activePromotion,
          price: product.price || 0,
        })
      case PromotionType.COMBO:
        return getComboPromotionUiDetails({
          promotion: promotionToBeDisplayed,
          config,
          activePromotion,
        })
      default:
        break
    }
  }
}
