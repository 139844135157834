import { gql } from '@apollo/client'
import cartProductsFieldsQuery from '../../commons/fields/cartProducts'

/**
 * gets the string for `prefillCart` query
 * gets all the products to prefill cart based on ML suggestions
 * @internal
 */

const prefillCartQuery = gql`
  query PrefillCart(
    $storefrontName: String!, 
    $sessionUid: String!
  ) {
    prefillCart(storefrontName: $storefrontName, sessionUid: $sessionUid) {
      ${cartProductsFieldsQuery}
    }
  }
`

export default prefillCartQuery
