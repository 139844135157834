import { gql } from '@apollo/client'
import productsFieldsQuery from '../../commons/fields/products'

/**
 * Get's the string for `searchProduct` query.
 * This query gets all existing items that match with the searchTerm.
 * The searchTerm could be a SKU or name of product
 * @internal
 */
const searchProductsByNameQuery = gql`
  query searchProduct(
    $storefrontName: String!
    $sessionId: String!
    $searchTerm: String
    $skipAddon: Boolean
    $filter: SearchFilterInput
  ) {
    searchProduct(
      storefrontName: $storefrontName
      sessionUid: $sessionId
      searchTerm: $searchTerm
      skipAddon: $skipAddon
      filter: $filter
    ) {
      ${productsFieldsQuery}
    }
  }
`

export default searchProductsByNameQuery
