import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { IAcceptCheckoutRuleMutationParams } from './types'
import acceptCheckoutRuleMutation from './mutation'
import { ICheckoutRule } from '../../commons'

/**
 * Hook declaration to set a checkout rule for a given session.
 * This hooks validates the required params are present and then calls the graphql endpoint.
 * @param options: MutationHookOptions
 * @internal
 */
const useAcceptCheckoutRule = (
  options?: MutationHookOptions<
    ICheckoutRule,
    IAcceptCheckoutRuleMutationParams
  >,
): MutationTuple<ICheckoutRule, IAcceptCheckoutRuleMutationParams> => {
  return useMutation<ICheckoutRule, IAcceptCheckoutRuleMutationParams>(
    acceptCheckoutRuleMutation,
    options,
  )
}

export default useAcceptCheckoutRule
