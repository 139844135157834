import {
  IConditionAction,
  IPromotionUiCart,
  IPromotionUiCartInput,
} from '../../interfaces'
import { getComboPromotionUiDetails } from './getComboPromotionUiDetails'
import { getComplexDirectPromotionUiDetails } from './getComplexDirectPromotionUiDetails'

const transformerActions: IConditionAction<
  IPromotionUiCartInput,
  IPromotionUiCart | undefined
>[] = [
  {
    condition: ({ cartProduct: { promotionalQty } }) => !!promotionalQty,
    transformAction: (args) => getComboPromotionUiDetails(args),
  },
  {
    condition: ({ cartProduct: { discounts } }) => !!discounts,
    transformAction: (args) => getComplexDirectPromotionUiDetails(args),
  },
]

export const getComplexPromotionUiDetails: (
  args: IPromotionUiCartInput,
) => IPromotionUiCart | undefined = (args) =>
  transformerActions
    .find(({ condition }) => condition(args))
    ?.transformAction(args)
