import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { ICatalogItem } from '../interfaces'
import { setCurrentCategory } from '../redux/slices/catalog'
import { RootState } from '../redux/store'

export const updateCurrentCategory = ({
  categories,
  currentCategory,
  dispatch,
}: {
  categories: ICatalogItem[]
  currentCategory?: ICatalogItem
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
}) => {
  if (currentCategory) {
    const [updatedCategory] = categories.filter(
      (categoryFilter) => categoryFilter.slug === currentCategory.slug,
    )
    dispatch(setCurrentCategory(updatedCategory))
  }
}

export default updateCurrentCategory
