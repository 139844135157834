import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBanners, Banner } from '../../../interfaces/banners'

export const initialState: IBanners = {
  banners: [],
}

export const { actions, reducer } = createSlice({
  name: 'bannersSlice',
  initialState,
  reducers: {
    setBanners: (state, { payload }: PayloadAction<Banner[]>) => {
      state.banners = payload
    },
  },
})

export const { setBanners } = actions

export default reducer
