import { MainFooterProps } from '@engyalo/delivery-ui-components/lib/ui/components/MainFooter'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  DefaultModalContainerNames,
  CinnamonActionNames,
  CinnamonPaths,
  TextLibrary,
  TemplateActionNames,
  CinnamonVariables,
} from '../defaultConstants'
import {
  CounterProductDetail,
  CounterRecommendedDetail,
  CounterProductDetailBundle,
} from './childProps/Counter'
import { PriceSimplified } from './childProps/Price'
import { PromotionSelectionCriteria } from '../../../graphQL/commons'
import { ButtonCartPriceDetailed } from './Button'

const bundleButtonClickArgs = {
  product: '{{catalog.selectedProduct}}',
  value: '{{catalog.currentBundleCount}}',
  newValue: '{{catalog.currentBundleCount}}',
  currency:
    '{{config.options.currencyPrefix}} || {{config.options.currencyCode}}',
  isBundle: '{{catalog.selectedProduct.isBundle}}',
  newSubItems: '{{catalog.bundlesCount}}',
  currentSubItems: '{{catalog.subItemsList}}',
  currentRoute: 'catalog',
}

export const MainFooterDefault: DefaultProps<MainFooterProps> = {
  button: {
    label: TextLibrary.CART_GO_TO_BUTTON,
    loading: '{{catalog.synchronizingCart}}' as any,
    startLabel: {
      label: '{{cart.itemsTotal}}',
      counter: true,
    },
    endLabel: {
      price: {
        ...PriceSimplified,
        display: '{{globals.footer.priceDisplay}}',
        value: '{{cart.subtotal}}' as any,
        discount: '{{cart.total}}' as any,
        fee: '{{cart.totalFee}}' as any,
        feeDisplay: '{{globals.footer.feeDisplay}}',
        vertical: true,
        feeColor: 'primary.contrastText',
        discountColor: 'primary.contrastText',
        gap: 2,
        align: 'right',
        styles: {
          '& .PriceFee': {
            marginTop: 0,
          },
        },
      },
    },
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.PUSH_VIEW,
          args: {
            path: CinnamonPaths.CART,
          },
        },
      ],
    } as any,
  },
}

export const MainFooterBaseProductDetail = deepMerge(MainFooterDefault, {
  counter: CounterProductDetail,
  button: {
    label: TextLibrary.CART_GO_TO_BUTTON,
    startLabel: undefined,
    loading: '{{catalog.synchronizingCart}}' as any,
  },
  bundleData: {
    counter: CounterProductDetailBundle,
    isBundle: '{{catalog.selectedProduct.isBundle}}' as any,
    button: {
      disabled: '{{catalog.disabledAddOnBundle}}' as any,
      label: `${TextLibrary.CART_ADD_TO_CART}{{catalog.currentBundlePrice}}`,
      startLabel: undefined,
      loading: '{{catalog.synchronizingCart}}' as any,
      onClick: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.ADD_ITEM_TO_CART,
            args: bundleButtonClickArgs,
          },
          {
            name: CinnamonActionNames.CLOSE_MODAL,
            args: {
              container: DefaultModalContainerNames.PRODUCT_DETAIL,
            },
          },
          {
            name: CinnamonActionNames.CLEAR_SUBITEMS,
          },
        ],
      } as any,
    },
  },
  hideCounterFields: ['extraPackages'],
  product: '{{catalog.selectedProduct}}' as any,
})

export const MainFooterProductDetail = deepMerge(MainFooterBaseProductDetail, {
  fullWidth: false,
  resumeData: {
    title: TextLibrary.PRODUCT_TOTAL_AMOUNT,
    amount: {
      ...PriceSimplified,
      value: '{{catalog.selectedProduct.subtotal}}' as any,
    },
  },
  isCounterOnTop: true,
  button: ButtonCartPriceDetailed,
  bundleData: {
    button: {
      label: `${TextLibrary.CART_ADD_TO_CART}`,
      onClick: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.ADD_ITEM_TO_CART,
            args: bundleButtonClickArgs,
          },
          {
            name: CinnamonActionNames.GO_BACK,
          },
          {
            name: CinnamonActionNames.CLEAR_SUBITEMS,
          },
        ],
      } as any,
    },
    resumeData: {
      title: TextLibrary.PRODUCT_TOTAL_AMOUNT,
      amount: {
        ...PriceSimplified,
        value: '{{catalog.currentBundlePriceValue}}' as any,
      },
    },
  },
})

export const MainFooterProductDetailModal = deepMerge(
  MainFooterBaseProductDetail,
  {
    fullWidth: false,
    onlyShowCounterOnDataReady: true,
    product: '{{catalog.selectedProduct}}' as any,
    button: {
      label: TextLibrary.CART_GO_TO_BUTTON,
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.PUSH_VIEW,
            args: {
              path: CinnamonPaths.CART,
            },
          },
          {
            name: CinnamonActionNames.CLOSE_MODAL,
            args: {
              container: DefaultModalContainerNames.PRODUCT_DETAIL,
            },
          },
        ],
      } as any,
    },
  },
)

export const MainFooterCart = deepMerge(MainFooterDefault, {
  button: {
    label: TextLibrary.CART_PLACE_BUTTON,
    color: 'success',
    disabled: '{{cart.disablePlaceOrder}}' as any,
    disabledOnLoading: true,
    actions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.PLACE_ORDER_FLOW,
          args: {
            actions: '{{globals.placeOrderFlow.actions}}',
            pushAfter: '{{globals.placeOrderFlow.pushAfter}}',
          },
        },
      ],
    } as any,
  },
})

export const MainFooterCartPriceDetailed = deepMerge(MainFooterCart, {
  fullWidth: false,
  button: ButtonCartPriceDetailed,
})

export const MainFooterSummary = deepMerge(MainFooterCart, {
  button: {
    label: TextLibrary.SUMMARY_CONTINUE_BUTTON,
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CREATE_ORDER,
          args: {
            sessionId: CinnamonVariables.SESSION_ID,
            storeFrontName: CinnamonVariables.STOREFRONT_NAME,
            phone: CinnamonVariables.WORKFLOW_ID,
            cart: CinnamonVariables.CART,
            session: CinnamonVariables.SESSION_DATA,
          },
        },
      ],
    } as any,
    styles: {
      '& .LabelEnd': {
        visibility: 'hidden',
      },
    },
  },
})

export const MainFooterFilters = deepMerge(MainFooterDefault, {
  button: {
    startLabel: undefined,
    endLabel: undefined,
    label: TextLibrary.FILTERS_APPLY_BUTTON_TEXT,
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CLOSE_MODAL,
          args: {
            container: DefaultModalContainerNames.FILTERS_LIST,
          },
        },
        {
          name: TemplateActionNames.FILTERS_GET_FILTERS_RESULTS,
        },
      ],
    } as any,
  },
})

export const MainFooterRecommendedProducts: DefaultProps<MainFooterProps> = {
  fullWidth: false,
  button: {
    label: '{{cart.recommendedProducts.closeButton}}',
    variant: 'outlined',
    color: 'inherit',
    loading: '{{catalog.synchronizingCart}}' as any,
    actions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.CART_CLOSE_RECOMMENDED_PRODUCTS_MODAL,
          args: {
            container: DefaultModalContainerNames.RECOMMENDED_PRODUCTS,
            continue: true,
          },
        },
      ],
    } as any,
  },
  styles: {
    padding: '0 12px 16px',
  },
}

export const MainFooterPrefillCart = deepMerge(MainFooterDefault, {
  fullWidth: false,
  button: {
    color: 'success',
    label: TextLibrary.CART_ADD_TO_CART,
    startLabel: {
      label: '{{cart.prefillCart.quantity}}',
      counter: true,
    },
    endLabel: {
      price: {
        value: '{{cart.prefillCart.total}}' as any,
        discount: undefined,
      },
    },
    loading: '{{catalog.synchronizingCart}}' as any,
    disabled: '{{cart.prefillCart.disabled}}' as any,
    actions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.PREFILL_CART_TO_CART,
        },
      ],
    } as any,
  },
})

export const MainFooterRecommendedProuctDetailModal = deepMerge(
  MainFooterProductDetailModal,
  {
    counter: CounterRecommendedDetail,
    button: {
      label: TextLibrary.RECOMMENDED_PRODUCTS_PROCEED,
      startLabel: undefined,
      endLabel: undefined,
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.CLOSE_MODAL,
            args: {
              container: DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL,
            },
          },
          {
            name: TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON,
            args: {
              label: TextLibrary.RECOMMENDED_PRODUCTS_PROCEED,
            },
          },
        ],
      } as any,
    },
  },
)

export const MainFooterRecommendedProuctDetail = deepMerge(
  MainFooterRecommendedProuctDetailModal,
  {
    isCounterOnTop: true,
    resumeData: {
      title: TextLibrary.PRODUCT_TOTAL_AMOUNT,
      amount: {
        ...PriceSimplified,
        value: '{{catalog.selectedProduct.subtotal}}' as any,
      },
    },
    button: {
      ...ButtonCartPriceDetailed,
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.GO_BACK,
          },
          {
            name: CinnamonActionNames.CLOSE_MODAL,
            args: {
              container: DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL,
            },
          },
          {
            name: TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON,
            args: {
              label: TextLibrary.RECOMMENDED_PRODUCTS_PROCEED,
            },
          },
        ],
      } as any,
    },
    bundleData: {
      button: {
        label: `${TextLibrary.CART_ADD_TO_CART}`,
        onClick: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.ADD_ITEM_TO_CART,
              args: bundleButtonClickArgs,
            },
            {
              name: CinnamonActionNames.GO_BACK,
            },
            {
              name: CinnamonActionNames.CLEAR_SUBITEMS,
            },
          ],
        } as any,
      },
      resumeData: {
        title: TextLibrary.PRODUCT_TOTAL_AMOUNT,
        amount: {
          ...PriceSimplified,
          value: '{{catalog.currentBundlePriceValue}}' as any,
        },
      },
    },
  },
)

export const MainFooterLinkedProducts: DefaultProps<MainFooterProps> = {
  fullWidth: false,
  button: {
    label: CinnamonVariables.LINKED_PRODUCTS_BUTTON_LABEL,
    color: 'success',
    loading: '{{catalog.synchronizingCart}}' as any,
    disabledOnLoading: true,
    actions: {
      cinnamonActions: [
        {
          name: TemplateActionNames.LINKED_PRODUCTS_VALIDATE_STOCK,
        },
      ],
    } as any,
  },
  styles: {
    padding: '0 12px 16px',
  },
}

export const MainFooterChosenFreegoods: DefaultProps<MainFooterProps> = {
  fullWidth: false,
  button: {
    label: TextLibrary.CHOSEN_FREEGOODS_CONFIRM,
    color: 'primary',
    loading: '{{catalog.synchronizingCart}}' as any,
    disabled: '{{catalog.freegoodsConf.isConfirmDisabled}}' as any,
    disabledOnLoading: true,
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CONFIRM_CHOSEN_FREEGOODS,
          args: {
            selectBy: PromotionSelectionCriteria.CUSTOMER_SELECTION,
          },
        },
      ],
    } as any,
  },
  text: {
    value: TextLibrary.CHOSEN_FREEGOODS_REJECT,
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.CONFIRM_CHOSEN_FREEGOODS,
          args: {
            selectBy: PromotionSelectionCriteria.REFUSED,
          },
        },
      ],
    } as any,
  },
  styles: {
    padding: '0 12px 16px',
  },
}
