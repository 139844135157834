import useAppDispatch from '../useAppDispatch'
import { setSubItem, setSubItems } from '../../redux/slices/catalog'
import { isSubItem, isSubItems } from '../../utils/isSubItem'
import { UnresolvedRecord } from '../../interfaces'

interface ISetSubitemsProps {
  state: UnresolvedRecord
}

const useSetSubitems = () => {
  const dispatch = useAppDispatch()

  const setSubitems = ({ state }: ISetSubitemsProps) => {
    if (
      state?.subItem &&
      isSubItem(state.subItem) &&
      state?.subItems &&
      isSubItems(state?.subItems)
    ) {
      dispatch(setSubItem(state.subItem))
      dispatch(setSubItems(state.subItems))
    }
  }

  const clearSubitems = () => {
    dispatch(setSubItem(null))
    dispatch(setSubItems([]))
  }

  return { setSubitems, clearSubitems }
}

export default useSetSubitems
