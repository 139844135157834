import { EMPTY_STRING } from '../../consts/defaultConfigValues/defaultConstants'
import { ICatalogItem } from '../../interfaces'

interface IParentLooking {
  founded: boolean
  parentSlug: string
}

export const getSlugParentCategory = (
  categories: ICatalogItem[],
  categorySlug: string,
): string => {
  const parentCategory = categories.reduce<IParentLooking>(
    (acc, category) => {
      if (acc.founded) {
        return acc
      }
      const result = acc
      if (!category.parentId) {
        result.parentSlug = category.slug || EMPTY_STRING
      }
      if (category.slug === categorySlug) {
        result.founded = true
      }
      return result
    },
    { founded: false, parentSlug: '' },
  )

  return parentCategory.parentSlug
}
