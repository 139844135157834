/**
 * Promotion fields
 * @internal
 */
const promotionsFieldsQuery: string = `
  id
  type
  priority
  usageLimit {
    perCustomer
    perOrder
  }
  customerBuys {
    groups {
      identifier
      type
      items {
        amount
        quantity
        sku
        type
      }
    }
    value {
      amount
      minimum
      quantity
      minimumAmount
    }
    items
  }
  name
  description
  customerGets {
    groups {
      identifier
      items {
        amount
        percentage
        quantity
        sku
        type
      }
      type
    }
    value {
      percentage
      quantity
      cash
      amount
    }
    items
    itemsExtra {
      id
      name
      sku
      category
      categories
      description
      tags
      size
      imageURL
      price
      priority
      packageType
      unitsPerPackage
      unitDivision
      divisionsByUnit
      isActive
      stock
      minQty
      maxQty
      outOfStock
      deactivationDate
      customFields
      attributes
      brand
      createdAt
      updatedAt
      isHidden
    }
  }
 `
export default promotionsFieldsQuery
