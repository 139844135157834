import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import { splitReducer, initSplitSdk } from '@splitsoftware/splitio-redux'
import defaultSlice from './slices/config/config'
import catalogSlice from './slices/catalog/catalog'
import cartSlice from './slices/cart/cart'
import summarySlice from './slices/summary/summary'
import filtersSlice from './slices/filters/filters'
import bannersSlice from './slices/banners/banners'
import suggestedProductsSlice from './slices/suggestedProducts/suggestedProducts'
import globalPromotionsSlice from './slices/globalPromotions/globalPromotions'
import categoriesNavSlice from './slices/categoriesnav/categoriesnav'

const sdkBrowserConfig = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLIT_IO_API_KEY,
    key: 'key',
  },
}

const rootReducer = combineReducers({
  defaultSlice,
  catalogSlice,
  cartSlice,
  summarySlice,
  filtersSlice,
  bannersSlice,
  suggestedProductsSlice,
  splitReducer,
  globalPromotionsSlice,
  categoriesNavSlice,
})

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

store.dispatch(initSplitSdk({ config: sdkBrowserConfig }))

export type AppDispatch = typeof store.dispatch
export default store
