import {
  IPromotion,
  GetComplexPromotionsUiDetails,
  IPromotionUiCatalog,
  IConfig,
} from '../../interfaces'
import { ComplexPromotionTypes } from '../../graphQL/commons'
import { getPromotionFields } from '../common/getPromotionFields'
import { getComplexDirectPromotionUiDetails } from './getComplexDirectPromotionUiDetails'
import formatPromotionMessage from '../formatPromotionMessage'
import { getComplexComboPromotionUiDetails } from './getComplexComboPromotionUiDetails'

interface ConditionGetters {
  condition: (promotion: IPromotion) => boolean
  getter: GetComplexPromotionsUiDetails
}

const mapComplexGetters: ConditionGetters[] = [
  {
    condition: (promotion) => {
      const { customerBuysGroups, customerGetsGroups } =
        getPromotionFields(promotion)
      const [{ type: customerBuysItemType }] = customerBuysGroups
      const [{ type: customerGetsItemType }] = customerGetsGroups
      return (
        customerBuysItemType === ComplexPromotionTypes.QUANTITY &&
        customerGetsItemType === ComplexPromotionTypes.DIRECT_PERCENTAGE
      )
    },
    getter: getComplexDirectPromotionUiDetails,
  },
]

interface ITransformPromotionDetailsArgs {
  promotionDetails: IPromotionUiCatalog
  promotion: IPromotion
  config?: IConfig
}

interface IConditionsTransformPromotionDetails {
  condition: (args: ITransformPromotionDetailsArgs) => boolean
  transformer: (args: ITransformPromotionDetailsArgs) => IPromotionUiCatalog
}

const transformProductDetails: IConditionsTransformPromotionDetails[] = [
  {
    condition: ({ promotion }) => !!promotion.name,
    transformer: ({ promotion, promotionDetails }) => ({
      ...promotionDetails,
      label: promotion.name!,
    }),
  },
  {
    condition: ({ promotion }) => !!promotion.description,
    transformer: ({ promotion, promotionDetails }) => ({
      ...promotionDetails,
      description: promotion.description!,
    }),
  },
  {
    condition: ({ promotion }) => !!getPromotionFields(promotion).limitQuantity,
    transformer: ({ promotion, promotionDetails, config }) => {
      const { defaultLimitLabel = '' } = config?.texts.promotions || {}

      return {
        ...promotionDetails,
        limit: formatPromotionMessage({
          promotion,
          limitQuantity: getPromotionFields(promotion).limitQuantity!,
          message: defaultLimitLabel,
        }),
      }
    },
  },
]

export const getComplexPromotionUiDetails: GetComplexPromotionsUiDetails = (
  args,
) => {
  const { promotion, config } = args
  const promotionUiDetailsBase =
    mapComplexGetters
      .find(({ condition }) => condition(promotion))
      ?.getter(args) || getComplexComboPromotionUiDetails(args)

  return !promotionUiDetailsBase
    ? promotionUiDetailsBase
    : transformProductDetails.reduce<IPromotionUiCatalog>(
        (acc, { condition, transformer }) =>
          condition({
            promotion,
            promotionDetails: acc,
            config,
          })
            ? transformer({
                promotion,
                promotionDetails: acc,
                config,
              })
            : acc,
        promotionUiDetailsBase,
      )
}
