import { gql } from '@apollo/client'

/**
 * checkoutSessionRuleQuery
 * @internal
 */
const checkoutSessionRuleQuery = gql`
  query CheckoutSessionRule(
    $storefrontName: String!
    $sessionUid: ID!
    $ruleType: String
  ) {
    checkoutSessionRule(
      storefrontName: $storefrontName
      sessionUid: $sessionUid
      ruleType: $ruleType
    ) {
      resultCheck
      rules {
        ruleType
        blocks
        checked
        data
      }
    }
  }
`

export default checkoutSessionRuleQuery
