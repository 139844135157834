import numberFormat from 'number-format.js'

const defaultPromotionPercentageFormat = '#0%'

const formatPercentage = (
  initialValue: number | null | undefined,
  format?: string,
): string => {
  const percentage = (initialValue || 0) * 100
  return numberFormat(format || defaultPromotionPercentageFormat, percentage, {
    enforceMaskSign: true,
  })
}

export default formatPercentage
