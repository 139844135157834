import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import useAppDispatch from '../useAppDispatch'
import { addAlert, manageErrors } from '../../redux/slices/config'
import { ErrorMessageTranslation } from '../../interfaces'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import generateAlerts from '../../utils/summary/generateAlerts'
import { useLazyGetSummary } from '../../graphQL/queries/getSummary'
import { IGetSummary } from '../../graphQL/queries/getSummary/types'
import { setSummary, setAlerts } from '../../redux/slices/summary'

export const useGetSummary = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((stateRedux) => stateRedux)
  const { config } = state.defaultSlice
  const { summaryAlerts } = state.summarySlice

  const onCompleted = (data: IGetSummary) => {
    dispatch(setSummary(data.summary))
    const alerts = generateAlerts(
      summaryAlerts,
      config?.globals?.summaryList?.summaryAlerts,
      data.summary.total,
    )
    dispatch(setAlerts(alerts))
  }
  const onError = (error: ApolloError) => {
    const message = getAlertErrorMessage(
      error.networkError ? AlertMessageType.Network : AlertMessageType.GetCart,
      config?.texts,
    )
    dispatch(addAlert({ message }))
    dispatch(
      manageErrors({
        errorMessageTranslation: ErrorMessageTranslation.GetCart,
      }),
    )
    const context = generateDataDogContext({
      title: 'get summary query throws an exception',
      extraInfo: { function: 'getSummary' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }
  const [getSummaryFn] = useLazyGetSummary({
    onCompleted,
    onError,
    fetchPolicy: 'no-cache',
  })

  const getSummaryAction = async () => {
    try {
      dispatch(setSynchronizingCart(true))
      await getSummaryFn({
        variables: {
          summarySessionUid: state.defaultSlice.sessionId || '',
          summaryStorefrontName: state.defaultSlice.storeName || '',
        },
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not get summary',
        extraInfo: { function: 'getSummaryAction' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(setSynchronizingCart(false))
    }
  }
  return getSummaryAction
}

export default useGetSummary
