import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import { ILastOrderResponse, ILastOrderVariables } from './types'
import lastOrderQuery from './query'

const useLazyGetLastOrder = (
  options?: LazyQueryHookOptions<ILastOrderResponse, ILastOrderVariables>,
) => {
  return useLazyQuery<ILastOrderResponse, ILastOrderVariables>(
    lastOrderQuery,
    options,
  )
}

export { useLazyGetLastOrder }
