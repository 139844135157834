import {
  IPromotionUiCatalog,
  ICommonPromotionFields,
  ITextLibrary,
} from '../../interfaces'
import {
  getItemExtraInfo,
  getPromotionFields,
} from '../common/getPromotionFields'
import formatPrice from '../formatPrice'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import {
  CustomerBuysDiscountType,
  CustomerGetsDiscountType,
  DirectPromotionType,
  IGetDirectPromotionDetailsParams,
  IMapConditionGetType,
  IMapBuysDiscountType,
  IMapSaving,
  IMapTransformArgs,
  IMapTransformResult,
  ITextsBase,
  IMapGetsDiscountType,
} from './typesForDirect'
import formatPercentage from '../formatPercentage'

const conditionsForTypes: IMapConditionGetType[] = [
  {
    condition: (promotion: ICommonPromotionFields) =>
      promotion.customerBuysItems?.length === 1 &&
      promotion.customerGetsItems?.length === 1 &&
      promotion.customerBuysItems?.[0] !== promotion.customerGetsItems?.[0],
    type: DirectPromotionType.DifferentProduct,
  },
  {
    condition: (promotion: ICommonPromotionFields) =>
      promotion.customerBuysQuantity === 1,
    type: DirectPromotionType.Individual,
  },
]

const getTypePromotion = (
  promotionFields: ICommonPromotionFields,
): DirectPromotionType => {
  const result = conditionsForTypes.find(({ condition }) =>
    condition(promotionFields),
  )

  return result?.type || DirectPromotionType.Multiple
}

const getCustomerGetsDiscountType = (
  promotionFields: ICommonPromotionFields,
) => {
  const mapDiscountType: IMapGetsDiscountType[] = [
    {
      condition: ({ customerGetsAmount }) => customerGetsAmount > 0,
      type: CustomerGetsDiscountType.Amount,
    },
    {
      condition: ({ customerGetsCash }) => customerGetsCash > 0,
      type: CustomerGetsDiscountType.Cash,
    },
  ]

  const result = mapDiscountType.find(({ condition }) =>
    condition(promotionFields),
  )

  return result?.type || CustomerGetsDiscountType.Percentage
}

const getCustomerBuysDiscountType = (
  promotionFields: ICommonPromotionFields,
) => {
  const mapDiscountType: IMapBuysDiscountType[] = [
    {
      condition: ({ customerBuysQuantity }) => customerBuysQuantity > 0,
      type: CustomerBuysDiscountType.Quantity,
    },
    {
      condition: ({ customerBuysAmount }) => customerBuysAmount > 0,
      type: CustomerBuysDiscountType.Amount,
    },
  ]

  const result = mapDiscountType.find(({ condition }) =>
    condition(promotionFields),
  )

  return result?.type || CustomerBuysDiscountType.Quantity
}

const getBaseTexts: (
  promotionType: DirectPromotionType,
  texts?: ITextLibrary,
) => ITextsBase = (promotionType, texts) => {
  if (!texts?.promotions) {
    return {
      label: '',
      description: '',
      defaultSaving: '',
      defaultLimitLabel: '',
    }
  }

  const {
    directIndividualLabel = '',
    directDefaultLabel = '',
    directIndividualDescription = '',
    directDefaultDescription = '',
    defaultSaving = '',
    defaultLimitLabel = '',
    comboDifferentPercentageDiscountLabel = '',
    comboPercentageDiscountDescription = '',
  } = texts.promotions

  const textsMap: Record<
    DirectPromotionType,
    Pick<ITextsBase, 'label' | 'description'>
  > = {
    [DirectPromotionType.Individual]: {
      label: directIndividualLabel,
      description: directIndividualDescription,
    },
    [DirectPromotionType.Multiple]: {
      label: directDefaultLabel,
      description: directDefaultDescription,
    },
    [DirectPromotionType.DifferentProduct]: {
      label: comboDifferentPercentageDiscountLabel,
      description: comboPercentageDiscountDescription,
    },
  }

  return {
    ...textsMap[promotionType],
    defaultSaving,
    defaultLimitLabel,
  }
}
/**
 * Function declaration to get direct promotions ui details to be shown on catalog
 *
 * @internal
 * @param price - The product price
 * @param config - Cinnamon config file
 * @param promotion - The promotion to be displayed
 * @param activePromotion - Details of promotion applied to the product
 * @returns IPromotionUiCatalog with promotion details
 */
export const getDirectPromotionUiDetails = ({
  price,
  config,
  promotion,
  activePromotion,
}: IGetDirectPromotionDetailsParams): IPromotionUiCatalog | undefined => {
  const promotionFields = getPromotionFields(promotion)
  const {
    customerBuysAmount,
    customerBuysQuantity,
    customerGetsPercentage,
    customerGetsCash,
    customerGetsAmount,
    limitQuantity,
    name: promotionName,
    description: promotionDescription,
    itemsExtra,
  } = promotionFields

  if (config?.texts.promotions || (promotionName && promotionDescription)) {
    const promotionType: DirectPromotionType = getTypePromotion(promotionFields)
    const [, itemExtra] = getItemExtraInfo(itemsExtra)
    const {
      name: itemExtraDescription,
      imageURL: itemExtraImage,
      price: itemExtraPrice,
    } = itemExtra
    const {
      label: labelText,
      description: descriptionText,
      defaultLimitLabel,
      defaultSaving,
    } = getBaseTexts(promotionType, config?.texts)
    const customerGetsDiscountType =
      getCustomerGetsDiscountType(promotionFields)
    const customerBuysDiscountType =
      getCustomerBuysDiscountType(promotionFields)

    const calculateUnitSaving = () => {
      const mapUnitSaving: IMapSaving[] = [
        {
          condition: (_, _buysDiscount, discountType) =>
            discountType === DirectPromotionType.DifferentProduct,
          value: itemExtraPrice * customerGetsPercentage,
        },
        {
          condition: (getsDiscount) =>
            getsDiscount === CustomerGetsDiscountType.Cash,
          value: customerGetsCash,
        },
        {
          condition: (getsDiscount) =>
            getsDiscount === CustomerGetsDiscountType.Amount,
          value: customerGetsAmount,
        },
        {
          condition: (getsDiscount) =>
            getsDiscount === CustomerGetsDiscountType.Percentage,
          value: price * customerGetsPercentage,
        },
      ]

      const result = mapUnitSaving.find(({ condition }) =>
        condition(
          customerGetsDiscountType,
          customerBuysDiscountType,
          promotionType,
        ),
      )

      return result?.value || price * customerGetsPercentage
    }

    const unitSaving = calculateUnitSaving()
    const calculateInitSaving = (): number => {
      const conditionsForInitSaving: IMapSaving[] = [
        {
          condition: (getsDiscount, _) =>
            getsDiscount === CustomerGetsDiscountType.Cash,
          value: customerGetsCash,
        },
        {
          condition: (getsDiscount, _) =>
            getsDiscount === CustomerGetsDiscountType.Amount,
          value: customerGetsAmount,
        },
        {
          condition: (_, buysDiscount) =>
            buysDiscount === CustomerBuysDiscountType.Amount,
          value: customerBuysAmount * customerGetsPercentage,
        },
      ]

      const result = conditionsForInitSaving.find(({ condition }) =>
        condition(
          customerGetsDiscountType,
          customerBuysDiscountType,
          promotionType,
        ),
      )

      return result?.value || unitSaving * customerBuysQuantity
    }
    const initSaving = calculateInitSaving()
    // at least 1 to calculate the discount
    const { count: countActivePromotion = 1 } = activePromotion || {}

    const value = price - unitSaving
    // format values
    const currency: string | undefined =
      config?.options?.currency || config?.options?.currencySymbol
    const format: string | undefined = config?.options?.format
    const formattedCustomerBuysAmount = formatPrice({
      currency,
      price: customerBuysAmount,
      format,
    })
    const formattedCustomerGetsAmount = formatPrice({
      currency,
      price: customerGetsAmount,
      format,
    })
    const formattedCashDiscount = formatPrice({
      currency,
      price: customerGetsCash,
      format,
    })
    const promotionPercentageFormat = config?.options?.promotionPercentageFormat

    const formattedPercentageDiscount = formatPercentage(
      customerGetsPercentage,
      promotionPercentageFormat,
    )

    const mapTransformFormatArgs: IMapTransformArgs[] = [
      {
        condition: (getsDiscount) =>
          getsDiscount === CustomerGetsDiscountType.Cash,
        transformFunction: (currentFormatArgs) => ({
          ...currentFormatArgs,
          placeholders: {
            ...currentFormatArgs.placeholders,
            discount: formattedCashDiscount,
          },
        }),
      },
      {
        condition: (getsDiscount) =>
          getsDiscount === CustomerGetsDiscountType.Amount,
        transformFunction: (currentFormatArgs) => ({
          ...currentFormatArgs,
          placeholders: {
            ...currentFormatArgs.placeholders,
            discount: formattedCustomerGetsAmount,
          },
        }),
      },
      {
        condition: (_, buysDiscount) =>
          buysDiscount === CustomerBuysDiscountType.Amount,
        transformFunction: (currentFormatArgs) => ({
          ...currentFormatArgs,
          placeholders: {
            ...currentFormatArgs.placeholders,
            value: formattedCustomerBuysAmount,
          },
        }),
      },
      {
        condition: (_, _buysDiscount, typeDiscount) =>
          typeDiscount === DirectPromotionType.Individual,
        transformFunction: (currentFormatArgs) => ({
          ...currentFormatArgs,
          placeholders: {
            ...currentFormatArgs.placeholders,
            amount: value,
            initSaving: unitSaving,
            saving: unitSaving * countActivePromotion,
          },
        }),
      },
      {
        condition: (_, _buysDiscount, typeDiscount) =>
          typeDiscount === DirectPromotionType.DifferentProduct,
        transformFunction: (currentFormatArgs) => ({
          ...currentFormatArgs,
          placeholders: {
            ...currentFormatArgs.placeholders,
            amount: value,
            initSaving: unitSaving,
            saving: unitSaving * countActivePromotion,
          },
        }),
      },
    ]

    const formatArgs = mapTransformFormatArgs.reduce<IFormatPromotionMessage>(
      (acc, current) => {
        if (
          current.condition(
            customerGetsDiscountType,
            customerBuysDiscountType,
            promotionType,
          )
        ) {
          return current.transformFunction(acc)
        }
        return acc
      },
      {
        message: '',
        promotion,
        config,
        unitSaving,
        initSaving,
        saving: initSaving * countActivePromotion,
        placeholders: {
          discount: formattedPercentageDiscount,
          value: String(customerBuysQuantity),
          quantity: String(customerBuysQuantity),
          product: itemExtraDescription,
        },
      },
    )

    const mapTransformResult: IMapTransformResult[] = [
      {
        condition: () => !!limitQuantity,
        transformFunction: (result) => ({
          ...result,
          limit: formatPromotionMessage({
            ...formatArgs,
            limitQuantity: limitQuantity || 0,
            message: defaultLimitLabel,
          }),
        }),
      },
      {
        condition: () => !promotionName && !customerGetsCash,
        transformFunction: (result) => ({
          ...result,
          saving: formatPromotionMessage({
            ...formatArgs,
            message: defaultSaving,
          }),
        }),
      },
      {
        condition: () => !promotionName,
        transformFunction: (result) => ({
          ...result,
          label: formatPromotionMessage({
            ...formatArgs,
            message: labelText,
          }),
        }),
      },
      {
        condition: () => !promotionDescription,
        transformFunction: (result) => ({
          ...result,
          description: formatPromotionMessage({
            ...formatArgs,
            message: descriptionText,
          }),
        }),
      },
      {
        condition: () => promotionType === DirectPromotionType.Individual,
        transformFunction: (result) => ({
          ...result,
          value,
        }),
      },
      {
        condition: () => promotionType === DirectPromotionType.DifferentProduct,
        transformFunction: (result) => ({
          ...result,
          image: itemExtraImage,
        }),
      },
    ]

    const result = mapTransformResult.reduce<IPromotionUiCatalog>(
      (acc, current) => {
        if (current.condition()) {
          return current.transformFunction(acc)
        }
        return acc
      },
      {
        label: promotionName,
        description: promotionDescription,
        saving: '',
        limit: '',
      },
    )
    return result
  }
}
