import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ItemStatus } from '@engyalo/delivery-ui-components/lib/interfaces/enums'
import type {
  ICatalogItem,
  ICategoriesNav,
  ICategoryNavigation,
} from '../../../interfaces'

export const initialState: ICategoriesNav = {
  categories: null,
  categoriesNav: [],
  fetchingMore: false,
}

export const categoriesNavSlice = createSlice({
  name: 'categoriesNavSlice',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<ICatalogItem[] | null>) => {
      state.categories = action.payload
    },
    setCategoriesNav: (
      state,
      { payload }: PayloadAction<ICategoryNavigation[]>,
    ) => {
      state.categoriesNav = payload
    },
    setCategoryStatus: (state, action: PayloadAction<ItemStatus>) => {
      state.categoryStatus = action.payload
    },
    setFetchingMore: (state, action: PayloadAction<boolean>) => {
      state.fetchingMore = action.payload
    },
  },
})

export const {
  setCategoriesNav,
  setCategories,
  setCategoryStatus,
  setFetchingMore,
} = categoriesNavSlice.actions

export default categoriesNavSlice.reducer
