import useBackInStockMutation from '../graphQL/mutations/backInStockNotification'
import useAppSelector from './useAppSelector'
import useDialog from './useDialog'

const dialogProps = {
  icon: {
    name: 'MdCheck',
  },
}
const useBackInStock = () => {
  const storefrontName = useAppSelector(
    (state: any) => state.defaultSlice.storeName,
  )
  const texts = useAppSelector((state: any) => state.defaultSlice.config.texts)
  const stockTexts = texts.productOutOfStock
  const actionsTexts = texts.actions
  const { openDialog, closeDialog } = useDialog()
  const sessionID = useAppSelector((state) => state.defaultSlice.sessionId)
  const dialogPropsSuccess = {
    ...dialogProps,
    title: stockTexts.dialogSuccessTitle,
    message: stockTexts.dialogSuccessBody,
    buttons: [
      {
        label: actionsTexts.accept,
        actions: closeDialog,
      },
    ],
  }
  const dialogPropsError = {
    ...dialogProps,
    title: stockTexts.dialogErrorTitle,
    message: stockTexts.dialogErrorBody,
    buttons: [
      {
        label: actionsTexts.accept,
        actions: closeDialog,
      },
    ],
  }
  const onCompleted = () => {
    openDialog(dialogPropsSuccess)
  }
  const onError = () => {
    openDialog(dialogPropsError)
  }

  const [action] = useBackInStockMutation({ onCompleted, onError })

  const backInStockNotification = ({ sku }: { sku: string }) => {
    action({
      variables: {
        storefrontName,
        sku,
        sessionUid: sessionID || '',
      },
    })
  }
  return backInStockNotification
}

export default useBackInStock
