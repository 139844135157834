import {
  useLazyQuery,
  LazyQueryResultTuple,
  LazyQueryHookOptions,
} from '@apollo/client'
import searchProductsByNameQuery from './query'
import { ISearchProduct, ISearchProductVariables } from './types'

/**
 * Hook declaration to obtain a search lazy query.
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazySearchProduct = (
  options?: LazyQueryHookOptions<ISearchProduct, ISearchProductVariables>,
): LazyQueryResultTuple<ISearchProduct, ISearchProductVariables> => {
  return useLazyQuery<ISearchProduct, ISearchProductVariables>(
    searchProductsByNameQuery,
    options,
  )
}
