import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UpdateSummaryPayload } from './types'
import { ISummary, ISummaryAlertsStatus } from '../../../interfaces'
import { TextLibrary } from '../../../consts/defaultConfigValues/defaultConstants'
import { AlertConstrainsSummary } from '../../../interfaces/summary'

export const initialState: ISummary = {
  id: null,
  items: [],
  groups: [],
  status: null,
  warnings: [],
  customFields: null,
  createdAt: null,
  updatedAt: null,
  disablePlaceOrder: true,
  total: 0,
  itemsTotal: 0,
  alerts: [],
  summaryAlerts: {
    [AlertConstrainsSummary.summaryAlertOnMinAmount]: {
      isActive: false,
    },
  },
  subtotal: 0,
  recommendedProducts: {
    show: true,
    closeButton: '{{texts.recommendedProducts.decline}}',
    productsSkus: [],
  },
  placeOrderFlow: {
    actions: [],
    isDone: false,
  },
  quantitiesMap: {},
  activePromotions: [],
  prefillCart: {
    items: [],
  },
  linkedProducts: {
    show: false,
    items: [],
    continueButton: TextLibrary.LINKED_PRODUCTS_CONTINUE,
  },
  activeFees: [],
  totalFee: 0,
  deferredPromotions: [],
}

export const summarySlice = createSlice({
  name: 'summarySlice',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload.id
    },
    setCustomFields: (state, action) => {
      state.customFields = action.payload.customFields
    },
    setDisabledPlaceOrder: (state, action) => {
      state.disablePlaceOrder = action.payload
    },
    setSummary: (state, { payload }: PayloadAction<UpdateSummaryPayload>) => {
      if (payload) {
        state.items = payload.items
        state.groups = payload.groups
        state.itemsTotal = payload.itemsTotal
        state.total = payload.total
        state.subtotal = payload.subtotal
        state.disablePlaceOrder = payload.disablePlaceOrder
        state.quantitiesMap = payload.quantitiesMap
        state.activeFees = payload.activeFees
        state.totalFee = payload.totalFee
        state.customFields = payload.customFields
      }
    },
    setAlerts: (state, action: PayloadAction<ISummaryAlertsStatus>) => {
      state.summaryAlerts = action.payload
    },
  },
})

export const {
  setId,
  setCustomFields,
  setDisabledPlaceOrder,
  setSummary,
  setAlerts,
} = summarySlice.actions

export default summarySlice.reducer
