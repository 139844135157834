import { gql } from '@apollo/client'
import { cartFieldsQuery } from '../../queries/getCart/query'

/**
 * Get's the string for `prefillCart` mutation.
 * This mutation adds a list of products to an existing cart.
 * Returns the cart with the updated products.
 * @internal
 */
export const prefillCartMutationQuery = gql`
  mutation PrefillCart(
    $storefrontName: String!
    $sessionUid: ID!
    $referrer: String
    $items: [CartProductInput]
  ) {
    prefillCart(
      storefrontName: $storefrontName
      sessionUid: $sessionUid
      referrer: $referrer
      items: $items
    ) {
      ${cartFieldsQuery}
    }
  }
`

export default prefillCartMutationQuery
