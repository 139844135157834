import { IFilterInformation } from '@engyalo/delivery-ui-components/lib/ui/components/AdvancedFilters'

export const buildFilterOptions = (
  options: { name: string }[],
): Record<string, IFilterInformation> => {
  return options.reduce((acc, curr) => {
    const { name: label } = curr
    return {
      ...acc,
      [label]: {
        label,
        checked: false,
      },
    }
  }, {})
}
