import { IPromotionUiCart, IPromotionUiCartInput } from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import formatPercentage from '../formatPercentage'

export const getDirectPromotionUiDetails = ({
  activePromotion,
  cartProduct,
  config,
}: IPromotionUiCartInput): IPromotionUiCart | undefined => {
  const texts = config?.texts?.cartPromotions
  const promotionPercentageFormat = config?.options?.promotionPercentageFormat
  const {
    fields: {
      customerBuysQuantity,
      customerGetsPercentage,
      limitQuantity,
      customerBuysAmount,
      customerGetsAmount,
      customerGetsCash,
      type,
    },
    promotion,
    count,
  } = getActivePromotionsFields(activePromotion)

  if (customerGetsCash) {
    return undefined
  }

  const { price, discounts, quantity: cartProductQuantity } = cartProduct
  const validDiscounts = discounts || 0

  const productsWithDiscount = count * (customerBuysQuantity || 0)
  const allProductsHaveDiscount = productsWithDiscount === cartProductQuantity
  const percentage = formatPercentage(
    customerGetsPercentage,
    promotionPercentageFormat,
  )
  const {
    directIndividualLabel = '',
    directProductsLabel = '',
    directAmountLabel = '',
    defaultSaving = '',
    defaultLimitLabel = '',
    directProductsAmountLabel = '',
  } = texts || {}

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving: discounts,
    amount: discounts,
    placeholders: {
      discount: percentage,
      count: String(productsWithDiscount),
    },
  }

  const result: IPromotionUiCart = {
    label: formatPromotionMessage({
      ...formatArgs,
      message: directProductsLabel as string,
    }),
    saving: formatPromotionMessage({
      ...formatArgs,
      message: defaultSaving as string,
    }),
    type,
  }

  if (limitQuantity) {
    result.limit = formatPromotionMessage({
      ...formatArgs,
      limitQuantity,
      message: defaultLimitLabel as string,
    })
  }

  if (validDiscounts) {
    const value = price - validDiscounts / cartProductQuantity
    result.value = value
  }

  if (allProductsHaveDiscount) {
    result.label = formatPromotionMessage({
      ...formatArgs,
      message: directIndividualLabel as string,
    })
  } else {
    result.label = formatPromotionMessage({
      ...formatArgs,
      message: directProductsLabel as string,
    })
  }

  if (customerBuysAmount) {
    result.label = formatPromotionMessage({
      ...formatArgs,
      message: directAmountLabel as string,
    })
  }

  if (customerGetsAmount) {
    if (allProductsHaveDiscount || !customerBuysQuantity) {
      result.label = formatPromotionMessage({
        ...formatArgs,
        message: directAmountLabel as string,
      })
    } else {
      result.label = formatPromotionMessage({
        ...formatArgs,
        message: directProductsAmountLabel as string,
      })
    }
  }

  return result
}
