import { useCallback } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import Hotjar from '@hotjar/browser'
import {
  SubItem,
  SubItems,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import { IProductCart } from '../../graphQL/mutations/cartRemoveProduct/types'
import useCartRemoveProduct from '../../graphQL/mutations/cartRemoveProduct'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { useGetCart } from './useGetCart'
import { onCartResponse } from '../../utils/onCartResponse'
import onRemoveProduct from '../../utils/onRemoveProduct'
import { generateDataDogContext } from '../../utils/dataDog'
import { IProduct } from '../../interfaces'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { addAlert } from '../../redux/slices/config'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'

export const useDeleteProductFromCart = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((stateRedux) => stateRedux)
  const {
    defaultSlice: { sessionId = '', storeName = '' },
  } = state
  const { config } = state.defaultSlice

  const getCartAction = useGetCart()

  const onCompleted = (data: IProductCart) => {
    if (data.cartRemoveProduct) {
      onCartResponse({
        data: data.cartRemoveProduct,
        state,
        dispatch,
      })
    }
  }

  const onError = (error: ApolloError) => {
    const message = getAlertErrorMessage(
      error.networkError
        ? AlertMessageType.Network
        : AlertMessageType.RemoveItem,
      config?.texts,
    )
    dispatch(addAlert({ message }))
    getCartAction()
    const context = generateDataDogContext({
      title: 'Remove product mutation error',
      extraInfo: { function: 'useDeleteProductFromCart > onError' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }
  const [removeProduct] = useCartRemoveProduct({
    onError,
    onCompleted,
  })

  const execRemoveCartMutation = (
    quantity: number,
    sku: string,
    product: IProduct,
    currency: string,
    referrer?: Referrers,
    subItems?: SubItems,
  ) => {
    let actionLabel = 'Delete from cart'
    if (referrer) {
      actionLabel = `Delete from cart on ${referrer}`
    }
    const { package: productPackage } = product
    const packageName = productPackage?.name || undefined
    const category = 'cart'
    Hotjar.event(actionLabel)
    sendGoogleEvent(category, actionLabel, sku, quantity)
    onRemoveProduct({
      removeProductFunction: removeProduct,
      sku,
      quantity,
      sessionId,
      storeName,
      dispatch,
      replace: true,
      subItems,
      packageName,
    })
  }
  const callBackRemoveProductToCart = useCallback(execRemoveCartMutation, [
    dispatch,
    removeProduct,
    sessionId,
    storeName,
  ])

  const removeItemAction = ({
    product,
    referrer,
    currency,
    currentSubItem,
  }: {
    product: IProduct
    currency: string
    referrer?: Referrers
    currentSubItem?: SubItem
  }) => {
    try {
      const hasSubItems = !!product?.subItems?.length
      // const that contains the subItems to be removed, if it exists
      let subItemsToRemove: SubItems = []
      if (hasSubItems && currentSubItem) {
        const subItems = product?.subItems || []
        const handledSubItems: SubItems = subItems || []
        const indexOfBundle = handledSubItems?.findIndex(
          (item) => item.hash === currentSubItem.hash,
        )
        handledSubItems.splice(indexOfBundle, 1)
        subItemsToRemove = handledSubItems
      }
      const cleanedSubItems = subItemsToRemove.map(
        ({ hash, ...subItemValue }) => subItemValue,
      )
      const handledValue = hasSubItems
        ? cleanedSubItems.reduce((acc, item) => acc + item.quantity, 0)
        : 0
      callBackRemoveProductToCart(
        handledValue,
        product.sku,
        product,
        currency,
        referrer,
        cleanedSubItems,
      )
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not delete product from cart',
        extraInfo: { product, function: 'removeItemAction' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }
  return removeItemAction
}

export default useDeleteProductFromCart
