import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import cartEmpty from './mutation'
import { ICartEmpty, ICartEmptyParams } from './types'

/**
 * Hook declaration to clear the cart.
 * This hooks validates the required params are present and then calls the graphql endpoint.
 * @internal
 */
const useCartEmpty = (
  options?: MutationHookOptions<ICartEmpty, ICartEmptyParams>,
): MutationTuple<ICartEmpty, ICartEmptyParams> => {
  return useMutation<ICartEmpty, ICartEmptyParams>(cartEmpty, options)
}

export default useCartEmpty
