import {
  SlashSymbol,
  TrueEnv,
} from '../consts/defaultConfigValues/defaultConstants'
import { IConditionAction, Localization, TransformAction } from '../interfaces'

interface UrlParamsResult {
  baseName: string
  storeName: string
  sessionId: string
  language?: Localization
}
type GetUrlParams = (pathname: string) => UrlParamsResult

interface IConditionGetParamsArgs {
  isEphemeralEnv: boolean
  pathNameAsArray: string[]
  isUsingLanguage: boolean
}

const defaultTransformAction: TransformAction<
  IConditionGetParamsArgs,
  UrlParamsResult
> = ({ pathNameAsArray }) => {
  const [storeName, sessionId] = pathNameAsArray
  return { baseName: SlashSymbol, storeName, sessionId }
}

const conditionsGetParams: IConditionAction<
  IConditionGetParamsArgs,
  UrlParamsResult
>[] = [
  {
    condition: ({ isEphemeralEnv, isUsingLanguage }) =>
      isEphemeralEnv && !isUsingLanguage,
    transformAction: ({ pathNameAsArray }) => {
      const [commitSha, storeName, sessionId] = pathNameAsArray
      return { baseName: commitSha, storeName, sessionId }
    },
  },
  {
    condition: ({ isEphemeralEnv, isUsingLanguage }) =>
      !isEphemeralEnv && isUsingLanguage,
    transformAction: ({ pathNameAsArray }) => {
      const [language, storeName, sessionId] = pathNameAsArray
      return { language, baseName: language, storeName, sessionId }
    },
  },
  {
    condition: ({ isEphemeralEnv, isUsingLanguage }) =>
      isEphemeralEnv && isUsingLanguage,
    transformAction: ({ pathNameAsArray }) => {
      const [commitSha, language, storeName, sessionId] = pathNameAsArray
      return {
        language,
        baseName: `${commitSha}${SlashSymbol}${language}`,
        storeName,
        sessionId,
      }
    },
  },
]

export const isLanguage = (value: any): value is Localization =>
  Object.values(Localization).includes(value as Localization)

export const getUrlParams: GetUrlParams = (pathname) => {
  const isEphemeralEnv = process.env.REACT_APP_EPHEMERAL === TrueEnv

  const pathNameAsArray = pathname.split(SlashSymbol).splice(1)

  const [maybeLanguage] = isEphemeralEnv
    ? [...pathNameAsArray].splice(1)
    : pathNameAsArray

  const isUsingLanguage = isLanguage(maybeLanguage)

  const args = { isEphemeralEnv, pathNameAsArray, isUsingLanguage }

  const transformAction =
    conditionsGetParams.find(({ condition }) => condition(args))
      ?.transformAction || defaultTransformAction

  return transformAction(args)
}

export default getUrlParams
