import { EMPTY_STRING } from '../../consts/defaultConfigValues/defaultConstants'
import { ICategoriesItem } from '../../graphQL/queries/getCategories/types'
import { ButtonType, ICategoryNavigation } from '../../interfaces'
import { sanitizeSlug } from '../sanitize'

export const castICategoriesToICategoryNavigation = ({
  name,
  id,
  imageURL,
}: ICategoriesItem): ICategoryNavigation => {
  const validName = name || EMPTY_STRING
  return {
    name: validName,
    category: validName,
    id,
    type: ButtonType.GO_CATEGORY,
    image: imageURL?.[0] || EMPTY_STRING,
    slug: sanitizeSlug(validName),
  }
}
