import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import { LoadersType, ErrorMessageTranslation, ICart } from '../../interfaces'
import {
  setSessionData,
  manageErrors,
  removeLoader,
  addLoader,
} from '../../redux/slices/config'
import useAppDispatch from '../useAppDispatch'
import { useAdvancedFilters } from '../filters/useFilters'
import { setBanners } from '../../redux/slices/banners/banners'
import castBanners from '../../utils/castBanners'
import { onCartResponse } from '../../utils/onCartResponse'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'
import { useLazyGetSession } from '../../graphQL/queries/getSession'
import { useLazyGetCart } from '../../graphQL/queries/getCart'
import { useLazyGetTotalPromotions } from '../../graphQL/queries/getTotalPromotions'
import {
  ISession,
  SessionDataArgs,
} from '../../graphQL/queries/getSession/types'
import { setMessage, setShow } from '../../redux/slices/globalPromotions'
import { getTotalPromotionsLabel } from '../../utils/promotions/getTotalPromotionsLabel'
import { ITotalPromotions } from '../../graphQL/queries/getTotalPromotions/types'
import { CartProductResponse } from '../../graphQL/commons'

const useFetchInitialCategoriesData = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((reduxState) => reduxState)
  const getAdvancedFiltersValues = useAdvancedFilters()
  const globalErrorConfig = {
    errorMessageTranslation: ErrorMessageTranslation.InitialData,
    showErrorTitle: true,
    globalError: true,
  }

  const onCompleted = (
    sessionData: SessionDataArgs,
    cartData: CartProductResponse,
    totalPromotions?: ITotalPromotions,
  ) => {
    onCartResponse({
      data: cartData,
      sessionData,
      dispatch,
      state,
    })

    if (totalPromotions?.getTotalPromotions?.length) {
      dispatch(
        setMessage(
          getTotalPromotionsLabel(
            totalPromotions?.getTotalPromotions,
            state.defaultSlice.config,
          ),
        ),
      )
      dispatch(setShow(true))
    }
  }

  const onError = (error: ApolloError) => {
    dispatch(manageErrors(globalErrorConfig))
    const context = generateDataDogContext({
      title: 'Get initial categories data got an error',
      extraInfo: { function: 'useFetchInitialCategoriesData > onError' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const onSessionCompleted = ({ session }: ISession) => {
    dispatch(setSessionData(session))
    dispatch(setBanners(castBanners(session.banners || [])))

    if (
      session.configuration?.filters?.promotions.active ||
      session.configuration?.filters?.categories.active ||
      session.configuration?.filters?.brands.active
    ) {
      getAdvancedFiltersValues()
    }
  }

  const [getSession] = useLazyGetSession({
    onCompleted: onSessionCompleted,
    onError,
  })

  const [getCart] = useLazyGetCart({
    onError,
  })

  const [getTotalPromotions] = useLazyGetTotalPromotions({
    onError,
  })

  const fetchInitialDataAction = async (
    sessionId: string,
    storeName: string,
  ) => {
    dispatch(addLoader(LoadersType.INITIAL_DATA))

    try {
      if (!sessionId) {
        dispatch(manageErrors(globalErrorConfig))
        return
      }
      await Promise.all([
        getSession({
          variables: {
            storefrontName: storeName,
            sessionUid: sessionId,
          },
        }),
        getCart({
          variables: {
            cartStorefrontName: storeName,
            cartSessionUid: sessionId,
          },
        }),
        getTotalPromotions({
          variables: {
            storefrontName: storeName,
            sessionID: sessionId,
          },
        }),
      ]).then((values) => {
        const [
          { data: sessionData },
          { data: cartData },
          { data: totalPromotionsData },
        ] = values

        if (sessionData?.session && cartData?.cart) {
          onCompleted(sessionData.session, cartData.cart, totalPromotionsData)
        }
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not load initial categories data',
        extraInfo: { function: 'fetchInitialCategoriesData' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(removeLoader(LoadersType.INITIAL_DATA))
    }
  }

  return fetchInitialDataAction
}

export default useFetchInitialCategoriesData
