import { useState } from 'react'
import { ApolloError } from '@apollo/client'
import ReactGA from 'react-ga4'
import { datadogRum } from '@datadog/browser-rum'
import Hotjar from '@hotjar/browser'
import { config } from 'process'
import { ISearchProps } from '../interfaces/actions'
import useAppDispatch from './useAppDispatch'
import { clearSearchData, updateSearchData } from '../redux/slices/catalog'
import { useLazySearchProduct } from '../graphQL/queries/searchProducts'
import { IProduct } from '../interfaces'
import { useAppSelector } from '.'
import { ISearchProduct } from '../graphQL/queries/searchProducts/types'
import { generateDataDogContext } from '../utils/dataDog'
import { sendGoogleEvent } from '../utils/sendGoogleAnalytics'
import { setPromotionUiDetailsCatalog } from '../utils/catalog/setPromotionUiDetailsCatalog'
import { castIProduct } from '../utils/castICatalogItems'

const useSearch = () => {
  const [term, setTerm] = useState<string>()
  const category = 'search'
  let action = ''

  const sessionId = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )
  const storeName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const state = useAppSelector((reduxState) => reduxState)
  const dispatch = useAppDispatch()

  const onCompleted = (data: ISearchProduct) => {
    if (data.searchProduct) {
      const items: IProduct[] = data.searchProduct.map((item) => {
        const product = castIProduct(item, state.defaultSlice.config)
        return {
          ...product,
          promotion: setPromotionUiDetailsCatalog({
            product,
            config: state.defaultSlice.config,
          }),
        } as IProduct
      })
      const status = items?.length ? 'OK' : 'ERROR'
      const counter = items?.length || 0
      const activeItems = items?.filter((item) => item.isActive)
      const isValidTerm = term !== ''
      dispatch(
        updateSearchData({
          items: isValidTerm ? activeItems : [],
          loading: false,
          status,
          counter,
        }),
      )
    }
  }

  const onError = (error: ApolloError) => {
    const context = generateDataDogContext({
      title: 'could not fetch search products response',
      extraInfo: { function: 'useSearch' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }
  const [searchProduct] = useLazySearchProduct({ onCompleted, onError })

  const search = async ({ searchTerm }: ISearchProps) => {
    try {
      setTerm(searchTerm)
      dispatch(
        updateSearchData({
          term: searchTerm,
          items: [],
          loading: true,
          status: 'LOADING',
          counter: 0,
        }),
      )
      ReactGA.event(category, { search_term: searchTerm })
      action = 'search_term'
      window.fbq('track', 'Search', { search_term: searchTerm })
      await searchProduct({
        variables: {
          sessionId,
          storefrontName: storeName,
          searchTerm: searchTerm.trim() ? searchTerm : ' ',
        },
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not fetch search products response',
        extraInfo: { function: 'useSearch' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }

  const clearSearch = () => {
    action = 'Clear Search'
    setTerm('')
    Hotjar.event(action)
    sendGoogleEvent(category, action)
    dispatch(clearSearchData())
  }

  return { search, clearSearch }
}

export default useSearch
