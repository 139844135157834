import { CategoryListProps } from '@engyalo/delivery-ui-components/lib/ui/components/CategoryList'
import { DefaultProps } from '../../../interfaces'
import { TemplateActionNames } from '../defaultConstants'

export const CategoryListDefault: DefaultProps<CategoryListProps> = {
  categories: '{{categoriesNav.categoriesNav}}' as any,
  loading: '{{categoriesNav.fetchingMore}}' as any,
  displayImages: true,
  clickActions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.FILTERS_CLEAR_FILTERS,
        args: {},
      },
      {
        name: TemplateActionNames.CATEGORIES_NAVIGATION_NAVIGATE_TO_CATEGORY,
        args: {
          item: '{{this.category}}',
          isCategoriesView: true,
        },
      },
    ],
  } as any,
  reachBottomActions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATEGORIES_NAVIGATION_SET_CATEGORIES,
      },
    ],
  } as any,
}
