import { ActivePromotion, IProductCatalog } from '../../graphQL/commons'
import {
  ICommonActivePromotionFields,
  ICommonPromotionFields,
  IPromotion,
} from '../../interfaces'
import { isNumber } from '../validTypes'

export const getPromotionFields = (
  promotion: IPromotion,
): ICommonPromotionFields => {
  const {
    customerBuys: {
      value: customerBuysValue,
      items: customerBuysItems,
      groups: customerBuysGroups,
    },
    customerGets: {
      groups: customerGetsGroups,
      value: customerGetsValue,
      items: customerGetsItems,
      itemsExtra,
    },
    usageLimit,
    type,
    name,
    description,
  } = promotion

  let limitQuantity: number | null | undefined

  if (
    usageLimit &&
    isNumber(usageLimit?.perCustomer) &&
    isNumber(usageLimit?.perOrder)
  ) {
    limitQuantity = Math.min(usageLimit.perCustomer, usageLimit.perOrder)
  } else {
    limitQuantity = usageLimit?.perCustomer ?? usageLimit?.perOrder
  }

  return {
    limitQuantity,
    customerBuysQuantity: customerBuysValue?.quantity ?? 0,
    customerBuysAmount: customerBuysValue?.amount ?? 0,
    customerBuysMinimum: customerBuysValue?.minimum ?? 0,
    customerBuysMinimumAmount: customerBuysValue?.minimumAmount ?? 0,
    customerBuysGroups: customerBuysGroups ?? [],
    customerGetsPercentage: customerGetsValue?.percentage ?? 0,
    customerGetsQuantity: customerGetsValue?.quantity ?? 0,
    customerGetsCash: customerGetsValue?.cash ?? 0,
    customerGetsAmount: customerGetsValue?.amount ?? 0,
    customerGetsGroups: customerGetsGroups ?? [],
    usageLimit,
    customerBuysItems,
    customerGetsItems: customerGetsItems ?? [],
    itemsExtra: itemsExtra ?? [],
    type,
    name: name ?? '',
    description: description ?? '',
  }
}

export const getActivePromotionsFields = (
  activePromotion: ActivePromotion,
): ICommonActivePromotionFields => {
  const { promotion, count, pricePerItem, freeGoods } = activePromotion

  return {
    promotion,
    fields: getPromotionFields(promotion),
    count,
    pricePerItem,
    freeGoods: freeGoods || [],
  }
}
/**
 * Function declaration to get an item from itemsExtra array
 * if sku is not provided, it will return the first item
 *
 * @internal
 * @param itemsExtra - IProductCatalog[] array of items extra
 * @param sku - The sku you're looking for
 * @returns two values, first one a boolean that says if item you're looking for exists and details
 */
export const getItemExtraInfo = (
  itemsExtra: IProductCatalog[] = [],
  sku?: string | null,
): [boolean, { price: number; name: string; imageURL: string }] => {
  const itemExtra = itemsExtra.reduce<IProductCatalog | null>(
    (prevValue, currentItemExtra, currentIndex) => {
      if (currentIndex === 0 && !sku) {
        return currentItemExtra
      }
      if (currentItemExtra.sku === sku) {
        return currentItemExtra
      }
      return prevValue
    },
    null,
  )

  const itemExtraExists = !!itemExtra

  if (itemExtraExists) {
    const { price, imageURL, name } = itemExtra
    return [
      itemExtraExists,
      {
        price: Number(price),
        imageURL: imageURL?.length ? imageURL[0] : '',
        name: name || '',
      },
    ]
  }

  return [
    itemExtraExists,
    {
      price: 0,
      imageURL: '',
      name: '',
    },
  ]
}
