import { IActionButton } from '@engyalo/delivery-ui-components/lib/interfaces/actions'
import {
  CinnamonActionNames,
  DefaultDialogsContainerNames,
  TemplateActionNames,
  TextLibrary,
} from '../../defaultConstants'

export const ClearCartButton: IActionButton = {
  label: TextLibrary.CART_CLEAR_BUTTON,
  actions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_DIALOG,
        args: {
          container: DefaultDialogsContainerNames.CLEAR_CART,
          id: 'clearCartdialog-0001',
          icon: {
            name: 'MdWarningAmber',
          },
          title: TextLibrary.CART_CLEAR_TITLE,
          message: TextLibrary.CART_CLEAR_MESSAGE,
          buttons: [
            {
              label: TextLibrary.ACTIONS_ACCEPT,
              actions: {
                cinnamonActions: [
                  {
                    name: TemplateActionNames.CART_CLEAR_CART,
                  },
                  {
                    name: CinnamonActionNames.CLOSE_DIALOG,
                  },
                ],
              },
            },
            {
              label: TextLibrary.ACTIONS_CANCEL,
              color: 'inherit',
              variant: 'outlined',
              actions: {
                cinnamonActions: [
                  {
                    name: CinnamonActionNames.CLOSE_DIALOG,
                    args: {
                      container: DefaultDialogsContainerNames.CLEAR_CART,
                      rejected: true,
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as any,
}
