import { IPromotionUiCart, IPromotionUiCartInput } from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'

export const getCashPromotionUiDetails = ({
  activePromotion,
  config,
}: IPromotionUiCartInput): IPromotionUiCart => {
  const texts = config?.texts?.cartPromotions

  const {
    promotion,
    count,
    fields: { customerGetsCash: cash, type },
  } = getActivePromotionsFields(activePromotion)

  const { defaultCashDiscountLabel } = texts || {}
  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    placeholders: {
      discount: Number(cash * count),
    },
  }
  const promotionLabelMessage = promotion.name || defaultCashDiscountLabel
  const label = formatPromotionMessage({
    ...formatArgs,
    message: promotionLabelMessage as string,
  })

  return {
    label,
    type,
  }
}
