import { ICinnamonAction } from '../interfaces/actions'
import { isObject } from './validTypes'
import { makeObjectRecursion } from './recursions'
import processData from './processData'
import type { IGenericFunction, UnresolvedRecord } from '../interfaces'

/** no evaluate props */
const noEvaluate = ['styles', 'badge']

/**
 * Convert an object to an arrow function that performs other functions
 * @param actionsList: ICinnamonAction[]
 * @param actions: any
 * @returns
 */
function createFunction(actionsList: ICinnamonAction[], actions: any) {
  return (callbackArgs: any) => {
    const compData = { ...callbackArgs }
    // compData is the data that component returns when its handleAction is executed
    const sources = {
      this: compData,
    }
    // execute the array of actions
    actionsList.forEach(({ name, args = {} }) => {
      const action = actions[name]
      const newArgs = processData(args, sources) // process {{this}}} variables
      action?.(newArgs)
    })
  }
}

/**
 * Check for cinnamon actions into an object
 * @param props: object
 * @param actions: any
 * @returns
 */
const processActions = (
  props: UnresolvedRecord,
  actions: Record<string, IGenericFunction>,
) => {
  const newProps: any = { ...props }

  Object.keys(newProps).forEach((prop: string) => {
    // if prop is not into the noEvaluate list
    if (!noEvaluate.includes(prop)) {
      const propValue = newProps[prop]

      // if value is Object or Array
      if (typeof propValue === 'object') {
        let newPropValue: any = propValue

        // if is Object and contains 'cinnamonActions'
        if (isObject(propValue) && propValue.cinnamonActions) {
          const { cinnamonActions } = propValue
          newProps[prop] = createFunction(cinnamonActions, actions)
          return // skip to next prop
        }

        // recursion
        newPropValue = makeObjectRecursion(
          propValue,
          processActions as IGenericFunction,
          [actions],
        )

        // update prop
        newProps[prop] = newPropValue
      }
    }
  })

  return newProps
}

export default processActions
