import { gql } from '@apollo/client'
import promotionsFieldsQuery from '../../commons/fields/promotions'
/**
 * Get's the string for `getTotalPromotions` query.
 * This query gets all existing GMV promotions.
 * @internal
 */
const getTotalPromotionsQuery = gql`
  query TotalPromotions($storefrontName: String!, $sessionID: ID!) {
    getTotalPromotions(storefrontName: $storefrontName, sessionUid: $sessionID) {
      ${promotionsFieldsQuery}
    }
  }
`

export default getTotalPromotionsQuery
