import { gql } from '@apollo/client'
/**
 * Get categories fields
 * @internal
 */
export const categoriesFieldsQuery: string = `
  id
  name
  isActive
  priority
  imageURL
  createdAt
  updatedAt
  parentId
`
/**
 * Get's the string for `categories` query.
 * This query gets all existing categories in catalog.
 * @internal
 */
const getCategoriesQuery = gql`
  query Categories($storefrontName: String!, $pagination: PaginationInput, $sessionID: String!, $parentId: ID) {
    categories(storefrontName: $storefrontName, pagination: $pagination, parentId: $parentId, sessionUid: $sessionID) {
      ${categoriesFieldsQuery}
    }
  }
`

export default getCategoriesQuery
