import { SessionNotificationsArray } from '../../../interfaces/config'

export interface ISessionVariables {
  storefrontName?: string
  sessionUid?: string
}

export enum RecommendedProductsValues {
  ML = 'ML',
  NONE = 'NONE',
}

export enum PrefillCartValues {
  LAST_ORDER = 'LAST_ORDER',
  ML = 'ML',
  NONE = 'NONE',
}

interface WorkflowDictionary
  extends Record<
    string,
    {
      name?: string
      channel?: string
      channelUid?: string
      stephook?: any
      jwtToken?: string
      body?: any
      ng: boolean
    }
  > {}

export interface CheckoutRulesFields {
  quantity: number
  message: string
  isActive: boolean
}

interface ProductCheckoutRules extends CheckoutRulesFields {
  sku: string[]
}

interface StockConfig {
  id: string
  warningMessage: string
  quantity: number
  enabled: boolean
}

interface SessionConfiguration {
  stock?: {
    hideOutOfStock?: boolean
    enabled?: boolean
    stockThreshold?: number
    key?: string
    threshold: {
      criticalLowWarning: StockConfig
      lowWarning: StockConfig
    }
  }
  i18n?: {
    language?: string
    currencyFormat?: string
    currencySymbol?: string
    currencyCode?: string
    country?: string
  }
  workflow?: WorkflowDictionary
  checkoutRules?: {
    cartConditionPendingMinAmount: CheckoutRulesFields
    cartConditionPendingMaxAmount: CheckoutRulesFields
    cartConditionPendingMinQty: CheckoutRulesFields
    cartConditionPendingMaxQty: CheckoutRulesFields
    cartConditionPendingUserValidation: ProductCheckoutRules
    cartWarningCheckReturnables: ProductCheckoutRules
  }
  groupers?: {
    cart: {
      active: boolean
      session: string[]
      product: string[]
    }
    order: {
      active: boolean
      session: string[]
      product: string[]
    }
  }
  showRecommendations?: boolean
  filters?: {
    attributes: string[]
    promotions: {
      active?: boolean
    }
    categories: {
      active?: boolean
    }
    brands: {
      active?: boolean
    }
  }
  promotions?: {
    autoAdd: boolean
    disableFreeGoodsAsDiscount: boolean | null
  }
  recommendedProducts?: {
    prefillCart: PrefillCartValues
    frequentlyBoughtTogether: RecommendedProductsValues
    suggestedItems: RecommendedProductsValues
  }
  notifications?: SessionNotificationsArray
}

export interface Banner {
  id: string
  imagesURL: string[]
  customerType: string
  reference: string
  type: string
  isActive: boolean
  priority: number
  startDate: Date
  expirationDate: Date
}

export interface Customer {
  email?: string
  phoneNumber?: string[]
  code?: string
  type?: string
}

export interface SessionDataArgs {
  id?: string
  customerUid?: string
  workflow?: {
    name?: string
    channel?: string
    channelUid?: string
    userUid?: string
    workflowName?: string
  }
  configuration?: SessionConfiguration
  banners?: Banner[]
  customer?: Customer
}

export interface ISession {
  session: SessionDataArgs
}
