import {
  ComplexPromotionTypes,
  IDeferredPromotion,
  PromotionSelectionCriteria,
} from '../../graphQL/commons'
import { IFreeGood, IFreegoodsConf } from '../../interfaces'

export const getFreegoodsFromDeferredPromotions = (
  deferredPromotions: IDeferredPromotion[] | null,
): IFreegoodsConf | undefined => {
  const deferredPromotion = deferredPromotions?.find(
    (currentPromotion) =>
      currentPromotion.complexConfiguration?.selectBy !==
      PromotionSelectionCriteria.REFUSED,
  )

  if (deferredPromotion) {
    const {
      promotion,
      count,
      promotion: {
        customerGets: { groups, itemsExtra },
      },
    } = deferredPromotion
    const availableFreegoods: IFreeGood[] = groups.reduce<IFreeGood[]>(
      (availableFreegoodsAcc, { items, type, identifier }, index) => {
        const shouldAddDescription = items.length === 1
        const result = items.reduce<IFreeGood>(
          (freeGoodAcc, { sku, quantity: quantityExtra }) => {
            const product = itemsExtra?.find(
              ({ sku: skuItemExtra }) => skuItemExtra === sku,
            )
            if (product) {
              const { name, description, imageURL } = product
              const {
                namesDescriptions,
                imageUrl: currentImage,
                ...rest
              } = freeGoodAcc

              const finalCount = count || 1
              const totalQuantity = Number(quantityExtra) * finalCount
              return {
                ...rest,
                imageUrl: currentImage || imageURL?.[0],
                namesDescriptions: [
                  ...namesDescriptions,
                  {
                    name:
                      totalQuantity > 1 ? `(${totalQuantity}) ${name}` : name,
                    description: shouldAddDescription ? description : '',
                  },
                ],
              }
            }
            return freeGoodAcc
          },
          {
            id: identifier || `freegood-${index}`,
            isChecked: false,
            namesDescriptions: [],
          },
        )

        if (
          result.namesDescriptions.length > 0 &&
          type === ComplexPromotionTypes.QUANTITY
        ) {
          return [...availableFreegoodsAcc, result]
        }

        return availableFreegoodsAcc
      },
      [],
    )
    return { promotion, freegoods: availableFreegoods, isConfirmDisabled: true }
  }
}
