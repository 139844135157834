import {
  EMPTY_STRING,
  PRODUCTS_PAGE_SIZE,
} from '../../consts/defaultConfigValues/defaultConstants'
import {
  ICatalogItem,
  ICategoryPagination,
  PaginationForCategories,
} from '../../interfaces'

export interface IGetPaginationArgs {
  initialPagination: ICategoryPagination
  currentPagination: PaginationForCategories
  categoriesResponse: ICatalogItem[]
}

export const getPaginationForCategories = ({
  initialPagination,
  currentPagination,
  categoriesResponse,
}: IGetPaginationArgs): PaginationForCategories => {
  return categoriesResponse.reduce<PaginationForCategories>(
    (acc, { category, productsPrices }) => {
      const validCategoryName = category || EMPTY_STRING
      const hasExistingPagination = !!acc[validCategoryName]
      const { pageNumber, ...rest }: ICategoryPagination = hasExistingPagination
        ? acc[validCategoryName]!
        : initialPagination
      const validPageNumber = hasExistingPagination
        ? pageNumber + 1
        : pageNumber

      const paginationResult = {
        ...rest,
        pageNumber: validPageNumber,
        hasMore: productsPrices?.length === PRODUCTS_PAGE_SIZE,
      }
      return {
        ...acc,
        [validCategoryName]: paginationResult,
      }
    },
    currentPagination,
  )
}
