import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { IBackInStockNotificationMutationParams } from './types'
import prefillCartMutationQuery from './mutation'

const useBackInStockMutation = (
  options?: MutationHookOptions<any, IBackInStockNotificationMutationParams>,
): MutationTuple<any, IBackInStockNotificationMutationParams> => {
  return useMutation<any, IBackInStockNotificationMutationParams>(
    prefillCartMutationQuery,
    options,
  )
}

export default useBackInStockMutation
