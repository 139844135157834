import { isArray, isObject } from './validTypes'
/**
 * Create a deep merge between two objects and their children objects
 * @param obj1
 * @param obj2
 * @returns T
 */
export function deepMerge<T>(obj1: T, obj2: T) {
  const newObject = { ...obj2 }
  const childObjects = Object.entries(newObject).filter((child) =>
    isObject(child[1]),
  )
  childObjects.forEach((child) => {
    const key = child[0] as keyof typeof newObject
    const item1 = obj1?.[key]
    const item2 = newObject?.[key]
    if (isObject(item1) && isObject(item2)) {
      newObject[key] = deepMerge(item1, item2) as never
    }
  })
  return {
    ...obj1,
    ...newObject,
  }
}

export default deepMerge
