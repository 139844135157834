import { ActivePromotion, PromotionType } from '../../graphQL/commons'
import {
  ICartProduct,
  IConditionAction,
  Condition,
  IConfig,
  ITransformCartProductsParams,
} from '../../interfaces'
import { getActivePromotionsFields } from '../common/getPromotionFields'
import { promotionsMap } from './getPromotionDetailsByType'
import { transformProductArray } from './setPromotionUiDetailsCart'

export const PREFIX_GMV_FREE_PRODUCT = 'GMV_FREE_PRODUCT_'
type ParentChildMap = Record<string, ICartProduct[] | undefined>

const conditionsUsePromotionAsItemExtra: Condition<ActivePromotion>[] = [
  ({ freeGoods }) => Number(freeGoods?.length) > 0,
]

interface IMatchProductPromotionArgs {
  activePromotionMatchProductAsFreegood?: boolean
  cartProduct: ICartProduct
  activePromotion: ActivePromotion
  parentChildMapping: ParentChildMap
  config?: IConfig
  cartProducts?: ICartProduct[]
}

const conditionsMapParents: IConditionAction<
  IMatchProductPromotionArgs,
  ParentChildMap
>[] = [
  {
    condition: ({
      activePromotion: {
        promotion: { type },
      },
      activePromotionMatchProductAsFreegood,
    }) =>
      !!activePromotionMatchProductAsFreegood && type === PromotionType.TOTAL,
    transformAction: ({
      activePromotion,
      cartProduct: { sku: cartProductSku },
      cartProduct,
      parentChildMapping,
      config,
    }) => {
      const {
        fields: { customerGetsQuantity, type },
        freeGoods,
      } = getActivePromotionsFields(activePromotion)
      const freegoods = freeGoods.filter(({ items }) =>
        items?.some(({ sku: currentSku }) => currentSku === cartProductSku),
      )

      const promotionalQty = freegoods.reduce<number>(
        (accFreegoods, { items }) => {
          const totalItems =
            items?.reduce<number>(
              (accItems, { sku, quantity }) =>
                sku === cartProductSku ? accItems + (quantity || 0) : accItems,
              accFreegoods,
            ) || 0
          return totalItems
        },
        0,
      )

      const product: ICartProduct = {
        ...cartProduct,
        sku: `${PREFIX_GMV_FREE_PRODUCT}${cartProductSku}`,
        readOnly: true,
        quantity: promotionalQty,
        promotion: promotionsMap[type](!!customerGetsQuantity)({
          activePromotion,
          cartProduct: {
            ...cartProduct,
            promotionalQty,
          },
          config,
        }),
      }
      const existingChilds: ICartProduct[] =
        parentChildMapping[PromotionType.TOTAL] || []

      return {
        ...parentChildMapping,
        [PromotionType.TOTAL]: [...existingChilds, product],
      }
    },
  },
  {
    condition: ({ activePromotionMatchProductAsFreegood }) =>
      !!activePromotionMatchProductAsFreegood,
    transformAction: ({
      activePromotion,
      cartProduct: { sku: cartProductSku },
      cartProduct,
      parentChildMapping,
      config,
      cartProducts,
    }) => {
      const {
        fields: { customerGetsQuantity, type },
        freeGoods,
        count: activePromotionCount,
      } = getActivePromotionsFields(activePromotion)
      const freegoods = freeGoods.filter(({ items }) =>
        items?.some(({ sku: currentSku }) => currentSku === cartProductSku),
      )
      return freegoods.reduce<ParentChildMap>((acc, freegood) => {
        const freegoodItemQuantity =
          freegood.items?.find(
            ({ sku: currentSku }) => currentSku === cartProductSku,
          )?.quantity || 0

        const promotionalQty = freegoodItemQuantity
        const product: ICartProduct = {
          ...cartProduct,
          sku: cartProductSku,
          readOnly: false,
          quantity: cartProduct.quantity,
          promotion: promotionsMap[type](!!customerGetsQuantity)({
            activePromotion,
            cartProduct: {
              ...cartProduct,
              promotionalQty,
            },
            config,
            cartProducts,
          }),
        }
        const skuParent = freegood?.consumed?.find(
          ({ sku }) => sku !== undefined,
        )?.sku

        const existingChilds: ICartProduct[] = skuParent
          ? acc[skuParent] || []
          : []
        return skuParent
          ? {
              ...acc,
              [skuParent]: [...existingChilds, product],
            }
          : acc
      }, parentChildMapping)
    },
  },
]

interface IFilterFreegoodsArgs {
  cartProduct: ICartProduct
  activePromotions: ActivePromotion[]
  parentChildMapping: ParentChildMap
  config?: IConfig
  cartProducts?: ICartProduct[]
}

const conditionsFilterFreegoods: IConditionAction<
  IFilterFreegoodsArgs,
  ParentChildMap
>[] = [
  {
    condition: ({ cartProduct: { promotionalQty } }) => promotionalQty > 0,
    transformAction: ({
      cartProduct,
      activePromotions,
      parentChildMapping,
      config,
      cartProducts,
    }) =>
      activePromotions.reduce<ParentChildMap>((acc, activePromotion) => {
        const activePromotionMatchProductAsFreegood =
          !!activePromotion.freeGoods?.some(({ items }) =>
            items?.some(
              ({ sku: currentSku }) => currentSku === cartProduct.sku,
            ),
          )
        const validCondition = conditionsMapParents.find(({ condition }) =>
          condition({
            activePromotionMatchProductAsFreegood,
            cartProduct,
            activePromotion,
            parentChildMapping: acc,
          }),
        )
        return (
          validCondition?.transformAction({
            cartProduct,
            activePromotion,
            parentChildMapping: acc,
            config,
            cartProducts,
          }) || acc
        )
      }, parentChildMapping),
  },
]

export const mapFreegoodsAndParents = (
  cartProducts: ICartProduct[],
  activePromotions: ActivePromotion[],
  config?: IConfig,
): ParentChildMap => {
  const activePromotionsFiltered = activePromotions.filter((activePromotion) =>
    conditionsUsePromotionAsItemExtra.every((condition) =>
      condition(activePromotion),
    ),
  )
  return cartProducts.reduce<ParentChildMap>(
    (acc, cartProduct) =>
      conditionsFilterFreegoods
        .find(({ condition }) =>
          condition({
            cartProduct,
            activePromotions: activePromotionsFiltered,
            parentChildMapping: acc,
          }),
        )
        ?.transformAction({
          cartProduct,
          activePromotions: activePromotionsFiltered,
          parentChildMapping: acc,
          config,
          cartProducts,
        }) || acc,
    {},
  )
}

const conditionsProductosFirstLevel: Condition<ICartProduct>[] = [
  ({ quantity }) => quantity > 0,
]

export const getCartProductsWithSubitems = (
  cartProducts: ICartProduct[],
  activePromotions: ActivePromotion[],
  config?: IConfig,
): ICartProduct[] => {
  const parentChildMapping = mapFreegoodsAndParents(
    cartProducts,
    activePromotions,
    config,
  )
  const freegoodsGMV: ICartProduct[] =
    parentChildMapping[PromotionType.TOTAL] || []

  const filteredProducts = cartProducts
    .filter((cartProduct) =>
      conditionsProductosFirstLevel.every((condition) =>
        condition(cartProduct),
      ),
    )
    .map<ICartProduct>((cartProduct) => {
      const itemsExtraResultFreeGood = parentChildMapping[cartProduct.sku]
      delete parentChildMapping[cartProduct.sku]
      const result: ICartProduct = {
        ...cartProduct,
        itemsExtra: itemsExtraResultFreeGood,
      }

      return result
    })
  return [...filteredProducts, ...freegoodsGMV]
}

export const setPromotionUiDetailsCartSubitems = ({
  cartProducts,
  activePromotions,
  config,
}: ITransformCartProductsParams): ICartProduct[] => {
  const activePromotionsFiltered = activePromotions.filter(
    (activePromotion) =>
      !conditionsUsePromotionAsItemExtra.every((condition) =>
        condition(activePromotion),
      ),
  )

  const cartProductsWithSubitems = getCartProductsWithSubitems(
    cartProducts,
    activePromotions,
    config,
  )

  return activePromotionsFiltered?.reduce<ICartProduct[]>(
    (currentItems, activePromotion) => {
      const activePromotionFields = getActivePromotionsFields(activePromotion)
      return currentItems.map(
        (cartProduct) =>
          transformProductArray
            .find(({ condition }) =>
              condition({
                activePromotionFields,
                cartProduct,
                activePromotion,
                config,
              }),
            )
            ?.transformAction({
              activePromotionFields,
              cartProduct,
              activePromotion,
              config,
            }) || cartProduct,
      )
    },
    cartProductsWithSubitems,
  )
}
