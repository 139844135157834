import { gql } from '@apollo/client'
import { cartFieldsQuery } from '../../queries/getCart/query'

/**
 * Get's the string for `removeProduct` mutation.
 * This mutation removes a product from an existing cart.
 * @internal
 */
const cartRemoveProduct = gql`
  mutation CartRemoveProductMutation(
    $cartRemoveProductStorefrontName: String!
    $cartRemoveProductSessionUid: ID!
    $cartRemoveProductData: CartProductOpInput!
  ) {
    cartRemoveProduct(
      storefrontName: $cartRemoveProductStorefrontName
      sessionUid: $cartRemoveProductSessionUid
      data: $cartRemoveProductData
    ) {
      ${cartFieldsQuery}
    }
  }
`

export default cartRemoveProduct
