import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import checkoutSessionRuleQuery from './query'
import {
  ICheckoutSessionRuleResponse,
  ICheckoutSessionRuleVariables,
} from './types'

const useLazyCheckoutSessionRule = (
  options?: LazyQueryHookOptions<
    ICheckoutSessionRuleResponse,
    ICheckoutSessionRuleVariables
  >,
) => {
  return useLazyQuery<
    ICheckoutSessionRuleResponse,
    ICheckoutSessionRuleVariables
  >(checkoutSessionRuleQuery, options)
}

export { useLazyCheckoutSessionRule }
