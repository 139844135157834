import { CategoriesListProps } from '@engyalo/delivery-ui-components/lib/ui/components/CategoriesList'
import { DefaultProps } from '../../../interfaces'
import {
  CinnamonPaths,
  TemplateActionNames,
  TextLibrary,
} from '../defaultConstants'

export const CategoriesListDefault: DefaultProps<CategoriesListProps> = {
  categories: '{{categoriesNav.categoriesNav}}' as any,
  isLoading: '{{categoriesNav.fetchingMore}}' as any,
  headerText: TextLibrary.ALL_CATEGORIES_LABEL,
  actions: {
    cinnamonActions: [
      {
        name: TemplateActionNames.CATEGORIES_NAVIGATION_NAVIGATE_TO_CATEGORY,
        args: {
          item: '{{this.data}}',
          isCategoriesView: false,
        },
      },
    ],
  } as any,
}
