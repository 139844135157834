/**
 * This module has the hook, declaration types and tests for the useGetFiltersValues query
 * {@label GET_FILTER_VALUES}
 */
import { useLazyGetFiltersValues } from './hook'

export const MAX_PAGINATION_SIZE = 100
export * as mutation from './query'
export * as types from './types'
export { useLazyGetFiltersValues }
