import { ICategoriesItem } from '../graphQL/queries/getCategories/types'
import { ICatalogItem as CommerceSDKICatalogItem } from '../graphQL/queries/getCatalog/types'
import { PRODUCTS_PAGE_SIZE } from '../consts/defaultConfigValues/defaultConstants'
import type {
  ICatalogItem,
  ICategory,
  ICategoryNavigation,
  ICategoryPagination,
} from '../interfaces'
import { sanitizeSlug } from './sanitize'

export const resolveSlugs = (
  categories: ICatalogItem[] | ICategory[] | ICategoryNavigation[] = [],
) => {
  const slugList = categories.map((cat) => cat?.slug || '')

  return categories.map((category, index) => {
    const otherSlugs = slugList.filter((_, i) => i !== index)
    let { slug = '' } = category
    let counter = 1

    while (otherSlugs.includes(slug)) {
      slug = `${slug}-${counter}`
      counter += 1
    }
    slugList[index] = slug

    return {
      ...category,
      slug,
    }
  })
}

/**
 * Function to create a ICatalogItem[] from ICategoriesItem[] (commerce-sdk)
 *
 * @internal
 */
export const castICategoriesItemToICatalogItemArray = (
  categories: ICategoriesItem[] = [],
): ICatalogItem[] => {
  const categoryList: ICatalogItem[] = categories.map(({ name }) => {
    return {
      category: name || '',
      name: name || '',
      slug: sanitizeSlug(name || ''),
      productsPrices: [],
    }
  })
  return resolveSlugs(categoryList) as ICatalogItem[]
}

export const filterCategoriesForMenu = (
  categories: CommerceSDKICatalogItem[] = [],
): CommerceSDKICatalogItem[] => {
  return categories.filter((category) => !category.parentId)
}
/**
 * Function to create a ICategory[] from ICatalogItem[] (commerce-sdk)
 *
 * @internal
 */
export const castICatalogItemToICategoryArray = (
  categories: CommerceSDKICatalogItem[] = [],
): ICategory[] => {
  const categoryList: ICategory[] = categories.map(
    ({ category, productsPrices, name }) => {
      return {
        category: category || name || '',
        name: category || name || '',
        slug: sanitizeSlug(category || ''),
        image: productsPrices?.[0]?.imageURL?.[0] ?? '',
      }
    },
  )

  return resolveSlugs(categoryList) as ICategory[]
}
/**
 * Function declaration to cast ICategoriesItem[] from commerce-sdk
 * to ICategory[]
 *
 * @internal
 */
export const castICategoriesItemToICategoryArray = (
  categories: ICategoriesItem[] = [],
): ICategory[] => {
  const categoryList: ICategory[] = categories.map(({ name, imageURL }) => {
    return {
      category: name,
      name,
      slug: sanitizeSlug(name || ''),
      image: imageURL?.[0] ?? '',
    }
  })

  return resolveSlugs(categoryList) as ICategory[]
}

export const setCatalogPagination = (
  categories: ICatalogItem[] = [],
): Record<string, ICategoryPagination> => {
  const result: { [category: string]: ICategoryPagination } = {}

  categories.forEach(({ category, slug = '', productsPrices }) => {
    const newSlug = slug || sanitizeSlug(category || '')
    result[newSlug] = {
      pageNumber: Math.ceil((productsPrices?.length || 0) / PRODUCTS_PAGE_SIZE),
      pageSize: PRODUCTS_PAGE_SIZE,
      hasMore:
        (productsPrices?.length || 0) === 0 ||
        (productsPrices?.length || 0) % PRODUCTS_PAGE_SIZE === 0,
    }
  })

  return result
}

export const setCatalogData = (
  categories: ICatalogItem[] = [],
): ICatalogItem[] => {
  const categoryList = categories.map((cat) => ({
    ...cat,
    slug: cat.slug || sanitizeSlug(cat.category || ''),
  }))

  return resolveSlugs(categoryList) as ICatalogItem[]
}

export default {
  castICatalogItemToICategoryArray,
  castICategoriesItemToICategoryArray,
}
