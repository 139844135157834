import { CounterProps } from '@engyalo/delivery-ui-components/lib/ui/components/Counter'
import { ItemCardCounterProps } from '@engyalo/delivery-ui-components/lib/ui/components/ItemCard'
import deepMerge from '../../../../utils/deepMerge'
import {
  CinnamonActionNames,
  Referrers,
  TemplateActionNames,
  TextLibrary,
} from '../../defaultConstants'

export const CounterDefaultArgs = {
  sku: '{{this.data.sku}}',
  value: '{{this.value}}',
  newValue: '{{this.newValue}}',
  product: '{{this.data}}',
  resultFixed: '{{this.resultFixed}}',
  currency:
    '{{config.options.currencyPrefix}} || {{config.options.currencyCode}}',
}

export const CounterBundleArgs = {
  sku: '{{this.data.sku}}',
  value: '{{this.value}}',
  newValue: '{{this.newValue}}',
  product: '{{this.data}}',
  currency:
    '{{config.options.currencyPrefix}} || {{config.options.currencyCode}}',
  isBundle: true,
  newSubItems: '{{this.data.subItem}}',
  currentSubItems: '{{this.data.subItemsProduct}}',
}

export const CounterProductDetailArgs = {
  ...CounterDefaultArgs,
  sku: '{{params.sku}}',
  product: '{{catalog.selectedProduct}}',
}

export const CounterPackageArgs = {
  sku: '{{this.data.sku}}',
  value: '{{this.value}}',
  newValue: '{{this.newValue}}',
  product: '{{this.data}}',
  resultFixed: '{{this.resultFixed}}',
  currency:
    '{{config.options.currencyPrefix}} || {{config.options.currencyCode}}',
  isPackage: true,
}

const CounterDefault: ItemCardCounterProps = {
  step: '{{globals.counter.step}}' as any,
  max: '{{globals.counter.max}}' as any,
  activeInput: '{{globals.counter.activeInput}}' as any,
  modifierMap: '{{globals.counter.modifierMap}}' as any,
  updateValueOnDisabled: false,
  allowSetMaxvalue: '{{globals.counter.allowRetryWhenOutOfStock}}' as any,
  disabledActions: {
    cinnamonActions: [
      {
        name: CinnamonActionNames.OPEN_DIALOG,
        args: {
          icon: {
            name: 'MdCheck',
          },
          title: TextLibrary.PRODUCT_STOCK_TITLE,
          message: TextLibrary.PRODUCT_STOCK_MESSAGE,
          buttons: [
            {
              label: TextLibrary.ACTIONS_ACCEPT,
              actions: {
                cinnamonActions: [
                  {
                    name: CinnamonActionNames.CLOSE_DIALOG,
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as any,
}

export const CounterForList: ItemCardCounterProps = deepMerge(CounterDefault, {
  itemPropAsMin: 'minQtyAllowed' as any,
  minusButton: {
    styles: {
      padding: '4px',
    },
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
          args: CounterDefaultArgs,
        },
      ],
    } as any,
  },
  plusButton: {
    styles: {
      padding: '4px',
    },
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.ADD_ITEM_TO_CART,
          args: CounterDefaultArgs,
        },
      ],
    } as any,
  },
})

export const CounterForListBundle: ItemCardCounterProps = deepMerge(
  CounterDefault,
  {
    itemPropAsMin: 'minQtyAllowed' as any,
    minusButton: {
      styles: {
        padding: '4px',
      },
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
            args: CounterBundleArgs,
          },
        ],
      } as any,
    },
    plusButton: {
      styles: {
        padding: '4px',
      },
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.ADD_ITEM_TO_CART,
            args: CounterBundleArgs,
          },
        ],
      } as any,
    },
  },
)

export const CounterProductDetail: CounterProps = deepMerge(CounterDefault, {
  value: '{{catalog.selectedProduct.quantity}}' as any,
  customStep: '{{catalog.selectedProduct.step}}' as any,
  stock: '{{catalog.selectedProduct.stock}}' as any,
  min: '{{catalog.selectedProduct.minQtyAllowed}}' as any,
  modifierName: '{{catalog.selectedProduct.packageType}}' as any,
  minusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
          args: CounterProductDetailArgs,
        },
      ],
    } as any,
  },
  plusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.ADD_ITEM_TO_CART,
          args: CounterProductDetailArgs,
        },
      ],
    } as any,
  },
})

export const CounterProductDetailBundle: CounterProps = deepMerge(
  CounterDefault,
  {
    value: '{{catalog.currentBundleCount}}' as any,
    stock: '{{catalog.selectedProduct.stock}}' as any,
    min: '{{catalog.selectedProduct.minQtyAllowed}}' as any,
    minusButton: {
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.SET_BUNDLE_COUNT,
            args: { newValue: '{{this.newValue}}' },
          },
        ],
      } as any,
    },
    plusButton: {
      styles: {
        padding: '4px',
      },
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.SET_BUNDLE_COUNT,
            args: { newValue: '{{this.newValue}}' },
          },
        ],
      } as any,
    },
  },
)

export const CounterRecommended = deepMerge(CounterForList, {
  plusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.ADD_ITEM_TO_CART,
          args: CounterDefaultArgs,
        },
        {
          name: TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON,
          args: {
            label: TextLibrary.RECOMMENDED_PRODUCTS_PROCEED,
          },
        },
      ],
    } as any,
  },
})

export const CounterRecommendedDetail = deepMerge(CounterProductDetail, {
  plusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.ADD_ITEM_TO_CART,
          args: {
            ...CounterProductDetailArgs,
            referrer: Referrers.FREQUENTLY_BOUGHT_TOGETHER,
          },
        },
        {
          name: TemplateActionNames.CART_UPDATE_RECOMMENDED_PRODUCT_BUTTON,
          args: {
            label: TextLibrary.RECOMMENDED_PRODUCTS_PROCEED,
          },
        },
      ],
    } as any,
  },
  minusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
          args: {
            ...CounterProductDetailArgs,
            referrer: Referrers.FREQUENTLY_BOUGHT_TOGETHER,
          },
        },
      ],
    } as any,
  },
})

export const CounterLinkedProducts = deepMerge(CounterForList, {
  minusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.REMOVE_ITEM_FROM_CART,
          args: {
            ...CounterDefaultArgs,
            referrer: Referrers.LINKED_PRODUCTS,
          },
        },
      ],
    } as any,
  },
  plusButton: {
    actions: {
      cinnamonActions: [
        {
          name: CinnamonActionNames.ADD_ITEM_TO_CART,
          args: {
            ...CounterDefaultArgs,
            referrer: Referrers.LINKED_PRODUCTS,
          },
        },
      ],
    } as any,
  },
})
