import { FC } from 'react'
import { AppTheme } from '@engyalo/delivery-ui-components'
import { useAppSelector } from '../hooks'
import { RootState } from '../redux/store'

interface Props {
  children: React.ReactNode
}

const WrappedAppTheme: FC<Props> = ({ children }) => {
  const theme = useAppSelector(
    (state: RootState) => state.defaultSlice.config?.theme,
  )

  return <AppTheme customTheme={theme || {}}>{children}</AppTheme>
}

export default WrappedAppTheme
