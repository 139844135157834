/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { datadogRum } from '@datadog/browser-rum'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { SessionDataArgs } from '../../../graphQL/queries/getSession/types'
import { DefaultCheckoutRulesNames } from '../../../consts/defaultConfigValues/defaultConstants'
import {
  IConfig,
  IModals,
  ISliceDefault,
  AlertConstrains,
  LoaderConfig,
  LoadersType,
  ErrorMessageTranslation,
  IAlert,
  IGetConfigArgs,
} from '../../../interfaces'
import {
  IModalActionsProps,
  IDialogActionsProps,
} from '../../../interfaces/actions'
import getConfig from '../../../services/getConfig'
import processConfig from '../../../utils/processConfig'
import { generateDataDogContext } from '../../../utils/dataDog'
import castSessionNotifications from '../../../utils/castNotifications'

export const initialState: ISliceDefault = {
  loaders: [],
  loading: true,
  config: undefined,
  loadingDelay: 1000,
  sessionId: undefined,
  dialogs: undefined,
  storeName: undefined,
  validators: [],
  currentModalItem: '',
  errorMessage: null,
  globalError: false,
  showErrorTitle: false,
  onErrorCallback: undefined,
  excludedUiComponents: [],
  cartAlerts: {
    [AlertConstrains.cartConditionPendingMinAmount]: {
      isActive: false,
    },
    [AlertConstrains.cartConditionPendingMinQty]: {
      isActive: false,
    },
    [AlertConstrains.cartConditionPendingMaxAmount]: {
      isActive: false,
    },
    [AlertConstrains.cartConditionPendingMaxQty]: {
      isActive: false,
    },
    [AlertConstrains.creditLimitMaxAmount]: {
      isActive: false,
    },
  },
  params: {},
  showRecommendations: false,
  showSuggestedItems: false,
  alerts: [],
  sessionNotifications: {
    STOCK_AVAILABLE: false,
    AUTHENTICATION_NEEDED: false,
    SESSION_CREATED: false,
    ORDER_REMINDER: false,
    ABANDONED_CART: false,
  },
}

export const defaultSlice = createSlice({
  name: 'defaultSlice',
  initialState,
  reducers: {
    manageErrors: (
      state,
      {
        payload: {
          errorMessageTranslation,
          globalError,
          showErrorTitle,
          onErrorCallback,
        },
      }: PayloadAction<{
        errorMessageTranslation: ErrorMessageTranslation
        globalError?: boolean
        showErrorTitle?: boolean
        onErrorCallback?: () => void
      }>,
    ) => {
      let errorMessage: string = 'An error has occurred'
      const errorsTexts = state.config?.texts?.errors
      let callback
      if (errorMessageTranslation) {
        switch (errorMessageTranslation) {
          case ErrorMessageTranslation.CatalogueEmptyData:
            errorMessage =
              (errorsTexts?.catalogueEmptyData as string) || 'Catalogue empty'
            break
          case ErrorMessageTranslation.InitialData:
            errorMessage =
              (errorsTexts?.initialData as string) || 'Session expired'
            break
          case ErrorMessageTranslation.GetCart:
            errorMessage =
              (errorsTexts?.getCart as string) || 'Error on get cart'
            break
          case ErrorMessageTranslation.NonProvidedParams:
            errorMessage =
              (errorsTexts?.nonProvidedParams as string) || 'No params provided'
            break
          case ErrorMessageTranslation.ErrorOnInitialLoad:
            errorMessage =
              errorsTexts?.tryAgainIntruction || 'An error has ocurred'
            callback = onErrorCallback
            break
          default:
            break
        }
      }
      state.onErrorCallback = callback
      state.errorMessage = errorMessage
      state.globalError = !!globalError
      state.showErrorTitle = !!showErrorTitle
    },
    addLoader: (state, action: PayloadAction<LoaderConfig>) => {
      const { payload } = action
      if (typeof payload === 'string') {
        state.loaders = [...state.loaders, payload]
      } else {
        state.loaders = [...state.loaders, payload.name]
        state.loadingDelay = payload.delay
      }
      state.loading = true
    },
    removeLoader: (state, action: PayloadAction<string>) => {
      const index = state.loaders.indexOf(action.payload)
      const newLoaders = [...state.loaders]
      newLoaders.splice(index, 1)
      state.loaders = newLoaders
      state.loading = newLoaders.length > 0
    },
    setSessionData: (state, action: PayloadAction<SessionDataArgs>) => {
      const { payload } = action
      if (
        !payload.configuration?.checkoutRules?.cartConditionPendingMinAmount
          .isActive
      ) {
        state.excludedUiComponents = [
          DefaultCheckoutRulesNames.MIN_AMOUNT_CART_CHECKOUT_RULE,
        ]
      }
      if (payload?.configuration?.notifications) {
        state.sessionNotifications = castSessionNotifications(
          payload?.configuration?.notifications,
        )
      }
      state.sessionData = payload
      const { frequentlyBoughtTogether, suggestedItems } =
        payload.configuration?.recommendedProducts || {}
      const showRecommendations = !!payload.configuration?.showRecommendations
      const showFrequentlyBoughtTogetherItems =
        frequentlyBoughtTogether === 'ML' || showRecommendations
      const showSuggestedItems = suggestedItems === 'ML' || showRecommendations
      state.showRecommendations = showFrequentlyBoughtTogetherItems
      state.showSuggestedItems = showSuggestedItems
    },
    setSessionId: (state, { payload }) => {
      state.sessionId = payload
    },
    updateParams: (state, { payload }) => {
      state.params = payload
    },
    openDialog: (state, action: PayloadAction<IDialogActionsProps>) => {
      const { icon, title, message, buttons, id } = action.payload
      state.dialogs = {
        show: true,
        icon: icon || { name: '' },
        title: title || { value: '' },
        message: message || { value: '' },
        buttons,
        id,
      }
    },
    closeDialog: (state, action: PayloadAction<IDialogActionsProps>) => {
      state.dialogs = {
        show: false,
        icon: { name: '' },
        title: { value: '' },
        message: { value: '' },
        buttons: [],
      }
    },
    openModal: (state, action: PayloadAction<IModalActionsProps>) => {
      if (state.modals) {
        const { container, state: modalState = {}, item = '' } = action.payload
        state.currentModalItem = item
        state.modals[container] = {
          show: true,
          state: modalState,
        }
      }
    },
    closeModal: (state, action: PayloadAction<IModalActionsProps>) => {
      if (state.modals) {
        const { container, state: modalState } = action.payload
        if (container === 'all') {
          Object.keys(state.modals).forEach((modalKey) => {
            if (state.modals) {
              state.modals[modalKey] = {
                ...state.modals[modalKey],
                show: false,
              }
            }
          })
        } else {
          state.modals[container] = {
            show: false,
            state: modalState || state.modals[container]?.state,
          }
        }
      }
    },
    setModals: (state, { payload }) => {
      state.modals = payload
    },
    setStoreName: (state, action: PayloadAction<string>) => {
      state.storeName = action.payload
    },
    setValidators: (state, action: PayloadAction<string[]>) => {
      state.validators = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.secondaryLoader = action.payload
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
    setCartAlerts: (state, action) => {
      state.cartAlerts = action.payload
    },
    addAlert: (state, { payload }: PayloadAction<IAlert>) => {
      const key = new Date().getTime().toString()
      state.alerts = [
        ...state.alerts,
        { ...payload, key },
      ] as WritableDraft<IAlert>[]
    },
    removeAlert: (state, { payload }: PayloadAction<void>) => {
      state.alerts.shift()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.config = action.payload as WritableDraft<IConfig>
    })
  },
})

export const {
  setSessionId,
  updateParams,
  openModal,
  closeModal,
  openDialog,
  closeDialog,
  setModals,
  setStoreName,
  setSessionData,
  setValidators,
  setLoading,
  setErrorMessage,
  setCartAlerts,
  addLoader,
  removeLoader,
  manageErrors,
  addAlert,
  removeAlert,
} = defaultSlice.actions

export const fetchConfig = createAsyncThunk<IConfig, IGetConfigArgs>(
  'defaultSlice/fetchData',
  async ({ slug, language }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(addLoader(LoadersType.STORE_CONFIG))
      dispatch(setStoreName(slug))
      const { data } = await getConfig(slug, () => {
        dispatch(
          manageErrors({
            errorMessageTranslation: ErrorMessageTranslation.ErrorOnInitialLoad,
            globalError: true,
            showErrorTitle: true,
            onErrorCallback: () => window.location.reload(),
          }),
        )
        dispatch(removeLoader(LoadersType.STORE_CONFIG))
      })
      const config = processConfig(data, language)
      const modals: IModals = {}
      Object.keys(config.modals || {}).forEach((modalKey) => {
        modals[modalKey] = {
          show: false,
          state: {},
        }
      })
      dispatch(setModals(modals))
      dispatch(removeLoader(LoadersType.STORE_CONFIG))
      return config
    } catch (error) {
      const context = generateDataDogContext({
        title: 'could not load config',
        extraInfo: { function: 'fetchConfig' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(error, context)
      return rejectWithValue(error)
    }
  },
)

export default defaultSlice.reducer
