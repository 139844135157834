import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import cartAddProduct from './mutation'
import { IAddProductParams, IProductCart } from './types'

/**
 * Hook declaration to increment quantity of a product in the cart.
 * This hooks validates the required params are present and then calls the graphql endpoint.
 * @internal
 */
const useCartAddProduct = (
  options?: MutationHookOptions<IProductCart, IAddProductParams>,
): MutationTuple<IProductCart, IAddProductParams> => {
  return useMutation<IProductCart, IAddProductParams>(cartAddProduct, options)
}

export default useCartAddProduct
