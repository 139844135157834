import { getQueryParams } from './getURLData'

export interface DataDogContext {
  title: string
  extraInfo?: Record<string, any>
}

export function generateDataDogContext(context: DataDogContext) {
  const { pathname, host, search } = window.location
  const [storefrontName, sessionId] = pathname.split('/').filter(Boolean)
  const viewName = `${pathname}${search}`
  const searchParams = getQueryParams()
  const defaultCtx = {
    storefrontName,
    sessionId,
    host,
    pathname,
    searchParams,
    viewName,
  }

  return { ...context, ...defaultCtx }
}
