/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useMemo } from 'react'
import { Loader } from '@engyalo/delivery-ui-components'
import { LoaderProps } from '@engyalo/delivery-ui-components/lib/ui/components/Loader'
import { useAppSelector } from '../hooks'

let loadingTimeout: NodeJS.Timeout

const Splash = () => {
  const loading = useAppSelector((state) => state.defaultSlice.loading)
  const config = useAppSelector((state) => state.defaultSlice.config)
  const loadingDelay = useAppSelector(
    (state) => state.defaultSlice.loadingDelay,
  )

  const configLogo = config?.logo || ''
  const splashOptions = config?.options?.splash || {}
  const { logo = '', label = '', hideIcon = false } = splashOptions

  const [show, setShow] = useState<boolean>(loading)
  const loaderProps: LoaderProps = {
    show,
    logo: logo
      ? {
          src: logo === true ? configLogo : logo,
        }
      : undefined,
    label: {
      value: label || '',
    },
    hideIcon,
  }

  useEffect(() => {
    loadingTimeout = setTimeout(() => {
      setShow(loading)
    }, loadingDelay)

    return () => {
      clearTimeout(loadingTimeout)
    }
  }, [loading, loadingDelay])

  return <Loader {...loaderProps} />
}

export default Splash
