import { HeaderListTypes } from '@engyalo/delivery-ui-components/lib/ui/components/HeaderList/HeaderList.types'
import { DefaultProps } from '../../../interfaces'
import deepMerge from '../../../utils/deepMerge'
import {
  CinnamonActionNames,
  CinnamonPaths,
  DefaultModalContainerNames,
  TextLibrary,
} from '../defaultConstants'

export const HeaderListDefault: DefaultProps<HeaderListTypes> = {
  label: TextLibrary.FILTERS_ALL_PRODUCTS_LABEL,
  hidden: '{{filters.hiddenFiltersHeader}}' as any,
  iconHeader: {
    label: `${TextLibrary.FILTERS_VIEW_TITLE} (0)`,
    iconButton: {
      icon: 'MdTune',
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.PUSH_VIEW,
            args: {
              path: CinnamonPaths.FILTERS,
            },
          },
        ],
      } as any,
    },
  },
  styles: {
    '& button': {
      padding: '0px',
    },
  },
}

export const HeaderListFiltered = deepMerge(HeaderListDefault, {
  tags: '{{filters.tags}}' as any,
  iconHeader: {
    label: `${TextLibrary.FILTERS_VIEW_TITLE} ({{filters.activeFiltersCount}})`,
    iconButton: {
      icon: 'MdTune',
      actions: {
        cinnamonActions: [
          {
            name: CinnamonActionNames.OPEN_MODAL,
            args: {
              container: DefaultModalContainerNames.FILTERS_LIST,
            },
          },
        ],
      } as any,
    },
  },
})
