import { IAdvancedFilterArgs } from '@engyalo/delivery-ui-components/lib/ui/components/AdvancedFilters'
import { DefaultProps } from '../../../interfaces'
import { TemplateActionNames } from '../defaultConstants'

export const AdvancedFiltersDefault: DefaultProps<IAdvancedFilterArgs> = {
  runOnLoad: {
    cinnamonActions: [
      {
        name: TemplateActionNames.FILTERS_SET_FILTER_CATEGORY,
        args: {
          data: {
            checkboxName: '{{catalog.currentCategory.category}}',
            checked: true,
            filterName: 'categories',
          },
          usePaginatedCategories:
            '{{default.config.options.usePaginatedCategories}}',
        },
      },
    ],
  },
  onChange: {
    cinnamonActions: [
      // cascading actions would be great here...
      {
        name: TemplateActionNames.FILTERS_SET_FILTERS, // temporal, this will be changed on actual implementation
        args: {
          data: '{{this.data}}',
        },
      },
    ],
  } as any,
  filters: '{{filters.filtersConfig}}' as any,
}
