/* eslint-disable @typescript-eslint/no-use-before-define */
import { constantSources, sourceShortcuts } from '../consts/sources'
import type { IGenericFunction, UnresolvedRecord } from '../interfaces'
import deepMerge from './deepMerge'
import { makeObjectRecursion } from './recursions'
import { isObject } from './validTypes'

interface ProcessValue {
  value: string
  sources: UnresolvedRecord
  main?: boolean
  initial?: boolean
}

interface VariableResult {
  input: string
  result: any
  constant: boolean
}

/** regex */
const regexCBrackets = /[^{{]+(?=}\})/g
const regexBrackets = /[^[[]+(?=]\])/g
const regexArray = /[^(]+(?=\))/
/** operators */
const operators = ['||', '??']
/** no evaluate props */
const noEvaluate: string[] = []

/**
 * Looks for {{variables}} into an object of props, and returns the same object with the processed data
 * @param props: object
 * @param sources: object
 * @param init: boolean
 * @returns object
 */
const processData = (
  props: UnresolvedRecord,
  sources: UnresolvedRecord,
  initial: boolean = false,
) => {
  let newProps: any = { ...props }
  const globalSources = {
    ...sources,
    env: process.env,
  }
  Object.keys(newProps).forEach((prop: string) => {
    // if prop is in the noEvaluate list, skip
    if (noEvaluate.includes(prop)) {
      return
    }

    const propValue = newProps[prop]
    let newPropValue: any = propValue

    // if is string and includes {{variables}}
    if (typeof propValue === 'string' && propValue.includes('{{')) {
      newPropValue = processVariables({
        value: propValue,
        sources: globalSources,
        initial,
      })
    }

    // recursion
    if (typeof propValue === 'object') {
      newPropValue = makeObjectRecursion(
        propValue,
        processData as IGenericFunction,
        [globalSources, initial],
      )
    }

    // update prop
    if (isObject(newPropValue) && prop === 'template') {
      newProps = deepMerge(newPropValue, newProps)
      delete newProps[prop]
    } else {
      newProps[prop] = newPropValue
    }
  })

  return newProps
}

/**
 * Gets {{variables}} or [[subVariables]] strings and returns the procecced values
 * @param value: string
 * @param sources: any
 * @param main: boolean
 * @param init: boolean
 * @returns any
 */
function processVariables({
  value,
  sources,
  main = true,
  initial = false,
}: ProcessValue): any {
  const regex = main ? regexCBrackets : regexBrackets
  const variables = value.match(regex)?.map((variable) => {
    // if detects a [[subVariable]] process it before
    const hasSubVariables = variable?.includes('[[')
    const newVariable = hasSubVariables
      ? processVariables({
          value: variable,
          sources,
          main: false,
          initial,
        })
      : variable

    const input = main ? `{{${variable}}}` : `[[${variable}]]`
    let result = reduceData(newVariable, sources, initial)
    const constant = constantSources.includes(variable.split('.')[0])

    // if result is a string and includes a {{variable}}, process it again before returns
    if (typeof result === 'string' && result.includes('{{')) {
      result = processVariables({ value: result, sources })
    }

    // if input is an object and has template or globals as source, process its own data before returns
    if (
      isObject(result) &&
      (input.includes('templates.') || input.includes('globals.'))
    ) {
      result = processData(result, sources, initial)
    }

    return {
      input,
      result,
      constant,
    }
  })

  return composeResult(value, variables, initial)
}

/**
 * Converts a {{variable.selector}} into a real value navigating through the sources
 * @param data
 * @param sources
 * @param initial
 * @returns
 */
function reduceData(data: string, sources: any, initial: boolean = false) {
  const selectors = data.split('.') || []
  let source = selectors.shift() || ''

  if (initial && !constantSources.includes(source)) {
    return undefined
  }

  // if current source is a shortcut
  if (Object.keys(sourceShortcuts).includes(source)) {
    selectors.unshift(...sourceShortcuts[source])
    source = selectors.shift() || ''
  }

  return selectors.reduce((currSource: any, selector: string) => {
    if (Array.isArray(currSource)) {
      return getArrayData(selector, currSource)
    }
    return currSource?.[selector]
  }, sources[source])
}

/**
 * Returns the value into an Array, if a filter (key, value) is detected it does a find() otherwise it does a flatMap()
 * selectors availables: length(), isEmpty() and isNotEmpty()
 * @param selector
 * @param source
 * @returns any
 */
function getArrayData(selector: string, source: Array<any>) {
  switch (selector) {
    case 'length()':
      return source.length
    case 'isEmpty()':
      return source.length === 0
    case 'isNotEmpty()':
      return source.length > 0
    default:
      break
  }

  const arrayFilter = selector.match(regexArray)?.[0]?.split(',')

  if (arrayFilter) {
    const [key = '', value = ''] = arrayFilter
    return source.find((item) => item?.[key] === String(value).trim())
  }

  return source.flatMap((value) => value?.[selector] ?? value)
}

/**
 * Returns the {{variable}} final result according to its structure
 * @param value
 * @param variables
 * @param initial
 * @returns
 */
function composeResult(
  value: string,
  variables: VariableResult[] = [],
  initial: boolean = false,
): any {
  let resultValue = value

  if (variables.length) {
    const firstVariable = variables[0]
    // if variable input is the same as value, returns variable result
    if (firstVariable.input === value) {
      const returnInput = value.includes('this.') || initial
      return !returnInput || firstVariable.result !== undefined
        ? firstVariable.result
        : firstVariable.input
    }

    // if value has logical operators
    if (
      operators.some((operator) => value.includes(operator)) &&
      (!initial || variables.some((variable) => variable.constant))
    ) {
      const logicalMap = value.split(/( \|\| | \?\? )/g)
      let currResult = firstVariable.result

      variables.forEach(({ input }, index) => {
        const inputIndex = logicalMap.indexOf(input)
        const operator = logicalMap[inputIndex + 1]
        const nextResult =
          variables[index + 1]?.result !== undefined
            ? variables[index + 1]?.result
            : logicalMap[inputIndex + 2]

        switch (operator?.trim()) {
          case '||':
            currResult = currResult || nextResult
            break
          case '??':
            currResult = currResult ?? nextResult
            break
          default:
        }
      })

      return currResult
    }

    // else compose a string with variable results
    variables.forEach(({ input, result }) => {
      resultValue = resultValue.replaceAll(
        input,
        String(result !== undefined ? result : input),
      )
    })
  }
  return resultValue
}

export default processData
