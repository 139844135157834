import { gql } from '@apollo/client'

/**
 * Get's the string for `acceptCheckoutRuleMutation` mutation.
 * This mutation evaluates a condition on a given checkout rule
 * @internal
 */

const acceptCheckoutRuleMutation = gql`
  mutation AcceptedCheckoutRule(
    $storefrontName: String!
    $sessionUid: ID!
    $ruleType: String!
  ) {
    acceptedCheckoutRule(
      storefrontName: $storefrontName
      sessionUid: $sessionUid
      ruleType: $ruleType
    ) {
      ruleType
      accepted
    }
  }
`
export default acceptCheckoutRuleMutation
