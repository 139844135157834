import {
  ICartGroup,
  ICartProduct,
  ITransformCartProductsParams,
} from '../../interfaces'

export const transformGroups = ({
  groups,
  params,
  transformer,
}: {
  groups: ICartGroup[]
  params: Pick<ITransformCartProductsParams, 'activePromotions' | 'config'>
  transformer: (args: ITransformCartProductsParams) => ICartProduct[]
}): ICartGroup[] => {
  return groups.map((group) => {
    const { items } = group
    return {
      ...group,
      items: transformer({
        cartProducts: items,
        ...params,
      }),
    }
  })
}
