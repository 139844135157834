import useAppDispatch from './useAppDispatch'
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from '../redux/slices/config'
import { IModalActionsProps } from '../interfaces/actions'

const useModal = () => {
  const dispatch = useAppDispatch()
  const openModal = ({ container, state, item = '' }: IModalActionsProps) => {
    dispatch(openModalAction({ container, state, item }))
  }

  const closeModal = ({ container, state }: IModalActionsProps) => {
    dispatch(closeModalAction({ container, state }))
  }

  return { openModal, closeModal }
}

export default useModal
