import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import activateDeferredPromotion from './mutation'
import {
  IActivateDeferredPromotionParams,
  IActivateDeferredPromotionResponse,
} from './types'

/**
 * Hook declaration to activate deferred promotion for given session.
 * This hooks validates the required params are present and then calls the graphql endpoint.
 * @internal
 */
const useActivateDeferredPromotion = (
  options?: MutationHookOptions<
    IActivateDeferredPromotionResponse,
    IActivateDeferredPromotionParams
  >,
): MutationTuple<
  IActivateDeferredPromotionResponse,
  IActivateDeferredPromotionParams
> => {
  return useMutation<
    IActivateDeferredPromotionResponse,
    IActivateDeferredPromotionParams
  >(activateDeferredPromotion, options)
}

export default useActivateDeferredPromotion
