import { FC, ComponentType } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

interface IErrorBoundary {
  children?: React.ReactNode
}

const ErrorFallback: ComponentType<FallbackProps> = ({
  error: { message },
}) => (
  <div>
    <p>Error: {message}</p>
  </div>
)

const ErrorWrapper = ({ children }: IErrorBoundary) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)

export default ErrorWrapper
