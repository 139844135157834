import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../hooks'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import type { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import useCategoryFilters from '../hooks/catalog/useCategoryFilters'
import useBannersClick from '../hooks/useBannersClick'
import type { IGenericFunction } from '../interfaces'
import { useSuggestionItems } from '../hooks/suggestedItems/useSuggestedItems'
import { usePrefillCart } from '../hooks/prefillCart/usePrefillCart'
import useCloseGlobalPromotion from '../hooks/catalog/useCloseGlobalPromotion'
import useGetProductsNav from '../hooks/categoriesnav/useGetProductsNav'
import useProductsLazyLoader from '../hooks/categories/useProductsLazyLoader'

const Catalog: FC<ITemplate> = ({ ...props }) => {
  const { updateCategory, scrollToCategory, updateCurrentCategoryCatalog } =
    useCategoryFilters()
  const fetchSuggestedItems = useSuggestionItems()
  const { updatePrefillCartItem, addPrefillCartToCart } = usePrefillCart()
  const closeGlobalPromotions = useCloseGlobalPromotion()
  const getProductsNav = useGetProductsNav()
  const getProducts = useProductsLazyLoader()
  const { slug = '' } = useParams<{ slug: string }>()

  const sessionData = useAppSelector((state) => state.defaultSlice.sessionData)
  const customer = sessionData?.customer
  const workflow = sessionData?.workflow
  const { id: sessionId = '' } = sessionData || {}
  const { code = '', phoneNumber = [], type = '' } = customer || {}
  const { userUid, channelUid, channel, workflowName } = workflow || {}
  const bannersClick = useBannersClick()
  const templateActions: ITemplateActions = {
    [TemplateActionNames.CATALOG_SCROLL_TO_CATEGORY]:
      scrollToCategory as IGenericFunction,
    [TemplateActionNames.CATALOG_UPDATE_CATEGORY]:
      updateCategory as IGenericFunction,
    [TemplateActionNames.CATALOG_UPDATE_CATEGORY_CATALOG]:
      updateCurrentCategoryCatalog as IGenericFunction,
    [TemplateActionNames.CATALOG_BANNERS_CLICK]:
      bannersClick as IGenericFunction,
    [TemplateActionNames.SUGGESTED_ITEMS_FETCH_PRODUCTS]:
      fetchSuggestedItems as IGenericFunction,
    [TemplateActionNames.PREFILL_CART_UPDATE_ITEM]:
      updatePrefillCartItem as IGenericFunction,
    [TemplateActionNames.PREFILL_CART_TO_CART]:
      addPrefillCartToCart as IGenericFunction,
    [TemplateActionNames.CLOSE_GLOBAL_PROMOTIONS_ALERT]:
      closeGlobalPromotions as IGenericFunction,
    [TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS]:
      getProductsNav as IGenericFunction,
    [TemplateActionNames.CATEGORIES_LAZY_GET_PRODUCTS]:
      getProducts as IGenericFunction,
  }
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/${props.container.uid}` })
    window.fbq('track', 'PageView', { page: `/${props.container.uid}` })
  }, [])
  return <Default templateActions={templateActions} {...props} />
}

export default Catalog
