import { gql } from '@apollo/client'
import { cartFieldsQuery } from '../../queries/getCart/query'

/**
 * Get's the string for `cartAddProduct` mutation.
 * This mutation adds a product to an existing cart.
 * @internal
 */
export const cartAddProductQuery = `
mutation CartAddProductMutation(
  $cartAddProductSessionUid: ID!
  $cartAddProductStorefrontName: String!
  $cartAddProductData: CartProductOpInput!
) {

  cartAddProduct(
    sessionUid: $cartAddProductSessionUid
    storefrontName: $cartAddProductStorefrontName
    data: $cartAddProductData
  ) {
    ${cartFieldsQuery}
  }
}`

export default gql`
  ${cartAddProductQuery}
`
