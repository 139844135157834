import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { FilterResult, FilterStatus, IFiltersSlice } from '../../../interfaces'

export const initialState: IFiltersSlice = {
  hiddenFiltersHeader: true,
  filtersConfig: {},
  activeFiltersCount: 0,
  backroute: '',
  status: 'OK',
}

// Slice and reducers
export const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    setStatus: (state, { payload }: PayloadAction<FilterStatus>) => {
      state.status = payload
    },
    setActiveFiltersCount: (state, action) => {
      state.activeFiltersCount = action.payload
    },
    setFilterCheckboxValue: (
      state,
      action: PayloadAction<{
        filterName: string
        checkboxName: string
        checked: boolean
      }>,
    ) => {
      const { filterName, checkboxName, checked } = action.payload
      if (state.filtersConfig[filterName]?.options[checkboxName]) {
        state.filtersConfig[filterName].options[checkboxName].checked = checked
      }
    },
    setFilterResults: (state, { payload }: PayloadAction<FilterResult>) => {
      state.results = payload
      state.backroute = 'filters'
    },
    setTags: (state, { payload }: PayloadAction<string[]>) => {
      state.tags = payload
    },
    setFiltersConfig: (state, action) => {
      state.filtersConfig = action.payload
      state.hiddenFiltersHeader = Object.keys(action.payload).length === 0
    },
    /** @Todo: someone remove this and make `clearFilters` take a `key`
     * argument to whipe a specific field */
    clearCategoryFilters: (state) => {
      const { filtersConfig } = current(state)
      const { categories } = filtersConfig
      if (categories) {
        const { options } = categories
        const newFiltersconfig = {
          ...filtersConfig,
          categories: {
            ...filtersConfig.categories,
            options: Object.entries(options).reduce(
              (optionsObj, [optionSlug, filterConfig]) => {
                return {
                  ...optionsObj,
                  [optionSlug]: {
                    ...filterConfig,
                    checked: false,
                  },
                }
              },
              {},
            ),
          },
        }
        state.filtersConfig = newFiltersconfig
      }
    },
    clearFilters: (state) => {
      state.filtersConfig = Object.entries(state.filtersConfig).reduce(
        (filters, [filterKey, filterData]) => {
          return {
            ...filters,
            [filterKey]: {
              ...filterData,
              options: Object.entries(filterData.options).reduce(
                (options, [optionSlug, filterConfig]) => {
                  return {
                    ...options,
                    [optionSlug]: {
                      ...filterConfig,
                      checked: false,
                    },
                  }
                },
                {},
              ),
            },
          }
        },
        {},
      )
      state.activeFiltersCount = 0
      state.results = undefined
      state.tags = []
    },
  },
})

export const {
  setStatus,
  setFiltersConfig,
  clearFilters,
  setFilterResults,
  setActiveFiltersCount,
  setFilterCheckboxValue,
  clearCategoryFilters,
  setTags,
} = filtersSlice.actions

export default filtersSlice.reducer
