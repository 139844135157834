import { gql } from '@apollo/client'
import productsFieldsQuery from '../../commons/fields/products'

/**
 * gets the string for `suggestedItemsQuery` query
 * gets all the products that are suggested through ML
 * @internal
 */

const suggestedItemsQuery = gql`
  query SuggestedItems(
    $storefrontName: String!
    $sessionUid: String!
    $limit: Int
  ) {
    suggestedItems(
      storefrontName: $storefrontName
      sessionUid: $sessionUid
      limit: $limit
    ) {
      ${productsFieldsQuery}
    }
  }
`

export default suggestedItemsQuery
