import { SummaryListProps } from '@engyalo/delivery-ui-components/lib/ui/components/SummaryList/SummaryList.types'
import { DefaultProps } from '../../../interfaces'
import { PriceDefault } from './childProps/Price'
import {
  CinnamonActionNames,
  CinnamonPaths,
  TextLibrary,
} from '../defaultConstants'

export const SummaryListDefault: DefaultProps<SummaryListProps> = {
  cart: {
    items: '{{cart.items}}' as any,
    groups: '{{cart.groups}}' as any,
    subtotal: '{{cart.subtotal}}' as any,
    total: '{{cart.total}}' as any,
    activeFees: '{{cart.activeFees}}' as any,
  },
  summary: {
    items: '{{summary.items}}' as any,
    groups: '{{summary.groups}}' as any,
    subtotal: '{{summary.subtotal}}' as any,
    total: '{{summary.total}}' as any,
    activeFees: '{{summary.activeFees}}' as any,
    customFields: '{{summary.customFields}}' as any,
  },
  heading: TextLibrary.SUMMARY_TITLE,
  subtitle: TextLibrary.SUMMARY_SUBTITLE,
  totalLabel: TextLibrary.SUMMARY_TOTAL_LABEL,
  subTotalLabel: TextLibrary.SUMMARY_SUBTOTAL_LABEL,
  unitsLabel: TextLibrary.SUMMARY_UNITS_LABEL,
  headingQuantity: TextLibrary.SUMMARY_HEADING_QUANTITY,
  headingProduct: TextLibrary.SUMMARY_HEADING_PRODUCT,
  headingSubtotal: TextLibrary.SUMMARY_HEADING_SUBTOTAL,
  fieldsToDisplay: '{{globals.summaryList.fieldsToDisplay}}' as any,
  summaryFieldsToDisplay:
    '{{globals.summaryList.summaryFieldsToDisplay}}' as any,
  priceProps: {
    ...PriceDefault,
    align: 'right',
  },
  labelMap: '{{globals.cartLabels}}' as any,
  packagePriceMap: '{{globals.price.packagePriceMap}}' as any,
  emptyMessage: {
    icon: 'MdAddShoppingCart',
    title: TextLibrary.CART_EMPTY_TITLE,
    message: TextLibrary.CART_EMPTY_MESSAGE,
    buttons: [
      {
        label: TextLibrary.CART_BACK_HOME,
        color: 'primary',
        variant: 'text',
        actions: {
          cinnamonActions: [
            {
              name: CinnamonActionNames.GO_BACK,
              args: {
                path: CinnamonPaths.HOME,
              },
            },
          ],
        } as any,
      },
    ],
  },
}
