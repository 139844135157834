import {
  ISummaryAlertsStatus,
  AlertConstrainsSummary,
  ISummaryAlertsValues,
} from '../../interfaces'

const generateAlerts = (
  alerts: ISummaryAlertsStatus,
  alertsValues: ISummaryAlertsValues,
  summaryTotal: number,
) => {
  const newAlerts: ISummaryAlertsStatus = { ...alerts }
  Object.keys(alerts).forEach((alert) => {
    if (alert === AlertConstrainsSummary.summaryAlertOnMinAmount) {
      newAlerts[alert] = {
        isActive:
          alertsValues[alert].value !== null &&
          summaryTotal >= alertsValues[alert].value,
      }
    }
  })
  return newAlerts
}

export default generateAlerts
