import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { useState } from 'react'
import { useLazyGetFrequentlyBoughtTogether } from '../../graphQL/queries/frequentlyBoughtTogether'
import { IFrequentlyBoughtTogetherResponse } from '../../graphQL/queries/frequentlyBoughtTogether/types'
import { IProduct } from '../../interfaces'
import {
  nextPlaceOrderAction,
  setRecommendedProductsButtonLabel,
  setShowRecommendedProducts,
} from '../../redux/slices/cart'
import {
  setRecommendedProducts,
  setSynchronizingCart,
} from '../../redux/slices/catalog'
import { closeModal, openModal } from '../../redux/slices/config'
import { generateDataDogContext } from '../../utils/dataDog'
import {
  getFlattedCartItems,
  setProductsQuantity,
} from '../../utils/utilsForCatalogReducer'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { castIProducts } from '../../utils/castICatalogItems'

interface ICloseRecommendedProducts {
  container: string
  continue?: boolean
}

export const useFrequentlyBoughtTogether = () => {
  const dispatch = useAppDispatch()
  const [currentModalName, setCurrentModalName] = useState<string>('')

  const storeName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionId = useAppSelector((state) => state.defaultSlice.sessionId)
  const config = useAppSelector((state) => state.defaultSlice.config)
  const showRecommendations = useAppSelector(
    (state) => state.defaultSlice.showRecommendations,
  )
  const {
    recommendedProducts: { show, productsSkus },
    items,
    groups,
    activePromotions,
  } = useAppSelector((state) => state.cartSlice)

  const onCompleted = ({
    frequentlyBoughtTogether,
  }: IFrequentlyBoughtTogetherResponse) => {
    const { flattedCartItems } = getFlattedCartItems({
      items,
      groups,
    })
    const products = castIProducts(frequentlyBoughtTogether, config)
    const result = setProductsQuantity({
      products,
      flattedCartItems,
      config,
      activePromotions,
    })
    dispatch(setRecommendedProducts(result))
    dispatch(setSynchronizingCart(false))
    if (result.length && show) {
      dispatch(openModal({ container: currentModalName }))
    } else {
      dispatch(nextPlaceOrderAction())
    }
  }

  const onError = (error: ApolloError) => {
    dispatch(setSynchronizingCart(false))
    dispatch(nextPlaceOrderAction())
    const context = generateDataDogContext({
      title: 'could not fetch frequently bought together response',
      extraInfo: { function: 'useFrequentlyBoughtTogether' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [getFrequentlyBoughtTogetherFn] = useLazyGetFrequentlyBoughtTogether({
    onCompleted,
    onError,
  })

  const getFrequentlyBoughtTogether = async ({
    modalName,
  }: {
    modalName: string
  }) => {
    setCurrentModalName(modalName)

    try {
      if (show && productsSkus.length > 0 && showRecommendations) {
        dispatch(setSynchronizingCart(true))
        await getFrequentlyBoughtTogetherFn({
          variables: {
            storefrontName: storeName || '',
            sessionUid: sessionId || '',
            skus: productsSkus,
          },
        })
      } else {
        dispatch(nextPlaceOrderAction())
      }
    } catch (exception) {
      dispatch(setSynchronizingCart(true))
      dispatch(nextPlaceOrderAction())
      const context = generateDataDogContext({
        title: 'could not fetch frequently bought together',
        extraInfo: { function: 'getFrequentlyBoughtTogether' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }

  return getFrequentlyBoughtTogether
}

export const useRecommendedProductsActions = () => {
  const dispatch = useAppDispatch()

  const closeRecommendedProducts = ({
    container,
    continue: continueShopping,
  }: ICloseRecommendedProducts) => {
    dispatch(closeModal({ container }))
    dispatch(setShowRecommendedProducts(false))
    if (continueShopping) {
      dispatch(nextPlaceOrderAction())
    }
  }

  const updateRecommendedButton = ({ label }: { label: string }) => {
    dispatch(setRecommendedProductsButtonLabel(label))
  }

  return { closeRecommendedProducts, updateRecommendedButton }
}
