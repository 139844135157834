import { ApolloClient, InMemoryCache } from '@apollo/client'
import { storefrontApiFallback } from '../consts/fallbacks'

const apolloClientConfig = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  uri: process.env.REACT_APP_STOREFRONT_API || storefrontApiFallback,
})

export default apolloClientConfig
