import type { IGenericFunction } from '../interfaces'
import { isArray, isObject } from './validTypes'

export const makeObjectRecursion = (
  obj: any,
  callback: IGenericFunction,
  otherArgs: any[] = [],
): Record<string, unknown> => {
  let newObj
  if (isObject(obj)) {
    newObj = callback(obj, ...otherArgs)
  }
  if (isArray(obj)) {
    newObj = obj.map((value: any) => {
      if (isObject(value)) {
        return callback(value, ...otherArgs)
      }
      return value
    })
  }

  return newObj
}
