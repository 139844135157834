export interface SourceShortcutsList {
  [source: string]: string[]
}

export const sourceShortcuts: SourceShortcutsList = {
  default: ['state', 'defaultSlice'],
  globalPromotions: ['state', 'globalPromotionsSlice'],
  config: ['state', 'defaultSlice', 'config'],
  params: ['state', 'defaultSlice', 'params'],
  texts: ['state', 'defaultSlice', 'config', 'texts'],
  templates: ['state', 'defaultSlice', 'config', 'templates'],
  globals: ['state', 'defaultSlice', 'config', 'globals'],
  catalog: ['state', 'catalogSlice'],
  cart: ['state', 'cartSlice'],
  summary: ['state', 'summarySlice'],
  filters: ['state', 'filtersSlice'],
  banners: ['state', 'bannersSlice'],
  categoriesNav: ['state', 'categoriesNavSlice'],
  checkoutRules: [
    'state',
    'defaultSlice',
    'sessionData',
    'configuration',
    'checkoutRules',
  ],
}

export const constantSources = ['config', 'texts', 'templates', 'globals']
