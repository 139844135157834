import {
  DefaultDialogsContainerNames,
  TextLibrary,
  CinnamonActionNames,
  TemplateActionNames,
} from '../consts/defaultConfigValues/defaultConstants'

const getValidatorDialogsProps = (validator: string) => {
  switch (validator) {
    case DefaultDialogsContainerNames.VALIDATE_ALCOHOL: {
      return {
        show: true,
        container: DefaultDialogsContainerNames.VALIDATE_ALCOHOL,
        title: { value: '' },
        id: 'alcoholdialog-0001',
        icon: {
          name: 'MdWarningAmber',
        },
        message: {
          value:
            '{{default.sessionData.configuration.checkoutRules.cartConditionPendingUserValidation.message}}',
        },
        buttons: [
          {
            label: TextLibrary.ACTIONS_ACCEPT,
            actions: {
              cinnamonActions: [
                {
                  name: CinnamonActionNames.CLOSE_DIALOG,
                },
                {
                  name: CinnamonActionNames.LOAD_DIALOGS_VALIDATIONS,
                  args: {
                    validators: '{{default.validators}}',
                  },
                },
              ],
            },
          },
          {
            label: TextLibrary.ACTIONS_CANCEL,
            color: 'inherit',
            variant: 'outlined',
            actions: {
              cinnamonActions: [
                {
                  name: CinnamonActionNames.CLOSE_DIALOG,
                  args: {
                    container: DefaultDialogsContainerNames.VALIDATE_ALCOHOL,
                    rejected: true,
                  },
                },
                {
                  name: TemplateActionNames.PLACE_ORDER_CLEAR,
                },
              ],
            },
          },
        ],
      }
    }
    case DefaultDialogsContainerNames.VALIDATE_RETURNABLE: {
      return {
        show: true,
        title: { value: '' },
        id: 'returnabledialog-0002',
        container: DefaultDialogsContainerNames.VALIDATE_RETURNABLE,
        icon: {
          name: 'MdWarningAmber',
        },
        message: {
          value:
            '{{default.sessionData.configuration.checkoutRules.cartWarningCheckReturnables.message}}',
        },
        buttons: [
          {
            label: TextLibrary.ACTIONS_ACCEPT,
            actions: {
              cinnamonActions: [
                {
                  name: 'closeDialog',
                },
                {
                  name: CinnamonActionNames.LOAD_DIALOGS_VALIDATIONS,
                  args: {
                    validators: '{{default.validators}}',
                  },
                },
              ],
            },
          },
          {
            label: TextLibrary.ACTIONS_CANCEL,
            color: 'inherit',
            variant: 'outlined',
            actions: {
              cinnamonActions: [
                {
                  name: 'closeDialog',
                  args: {
                    rejected: true,
                    container: DefaultDialogsContainerNames.VALIDATE_RETURNABLE,
                  },
                },
                {
                  name: TemplateActionNames.PLACE_ORDER_CLEAR,
                },
              ],
            },
          },
        ],
      }
    }
    default: {
      return {
        show: false,
        title: { value: '' },
        icon: {
          name: '',
        },
        message: {
          value: '',
        },
        buttons: [],
      }
    }
  }
}

export default getValidatorDialogsProps
