import { PriceProps } from '@engyalo/delivery-ui-components/lib/ui/components/Price'
import deepMerge from '../../../../utils/deepMerge'
import { TextLibrary } from '../../defaultConstants'

export const PriceDefault: Partial<PriceProps> = {
  symbol: '{{config.options.currency}} || {{config.options.currencySymbol}}',
  code: '{{config.options.currencyPrefix}} || {{config.options.currencyCode}}',
  format: '{{config.options.format}}',
  display: '{{config.options.currencyDisplay}}',
  showUnitPrice: '{{globals.unitPrice.showUnitPrice}}' as any,
  unitsDisplay: TextLibrary.PRODUCT_UNIT_DISPLAY,
  unitsSize: '{{globals.unitPrice.unitsSize}}',
  unitsColor: '{{globals.unitPrice.unitsColor}}',
  unitsInline: '{{globals.unitPrice.unitsInline}}' as any,
  feeDisplay: TextLibrary.PRODUCT_FEE_DISPLAY,
  color: '{{globals.price.color}}',
  inline: '{{globals.price.inline}}' as any,
  vertical: '{{globals.price.vertical}}' as any,
  align: '{{globals.price.align}}' as any,
  size: '{{globals.price.size}}' as any,
  discountSize: '{{globals.price.discountSize}}' as any,
  feeColor: '{{globals.price.feeColor}}',
  feeSize: '{{globals.price.feeSize}}' as any,
  priceOrder: '{{globals.price.priceOrder}}' as any,
  discountOrder: '{{globals.price.discountOrder}}' as any,
  unitOrder: '{{globals.price.unitOrder}}' as any,
}

export const PriceSimplified = deepMerge(PriceDefault, {
  code: '',
  showUnitPrice: false,
})
