import { gql } from '@apollo/client'
import productsFieldsQuery from '../../commons/fields/products'

/**
 * Get's the string for `getProductBySku` query.
 * This query gets the data for a single product with a given SKU.
 * @internal
 */
const getProductBySkuQuery = gql`
  query GetProductBySKU(
    $storefrontName: String!
    $sessionId: String!
    $sku: String!
  ) {
    getProductBySKU(
      storefrontName: $storefrontName
      sessionUid: $sessionId
      sku: $sku
    ) {
      ${productsFieldsQuery}
    }
  }
`

export default getProductBySkuQuery
