const sortByKey: <T>(array: T[], key: keyof T) => T[] = (array, key) =>
  Array.from(array).sort((a, b) => {
    const x = a[key]
    const y = b[key]

    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
    return 0
  })

export default sortByKey
