import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import frequentlyBoughtTogetherQuery from './query'
import {
  IFrequentlyBoughtTogetherResponse,
  IFrequentlyBoughtTogetherVariables,
} from './types'

/**
 * Hook declaration to obtain a lazy query of frequently bought together products
 * @param options: LazyQueryHookOptions
 * @internal
 */
const useLazyGetFrequentlyBoughtTogether = (
  options?: LazyQueryHookOptions<
    IFrequentlyBoughtTogetherResponse,
    IFrequentlyBoughtTogetherVariables
  >,
) => {
  return useLazyQuery<
    IFrequentlyBoughtTogetherResponse,
    IFrequentlyBoughtTogetherVariables
  >(frequentlyBoughtTogetherQuery, options)
}

export { useLazyGetFrequentlyBoughtTogether }
