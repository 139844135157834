import { gql } from '@apollo/client'
import promotionsFieldsQuery from '../../commons/fields/promotions'
import cartProductsFieldsQuery from '../../commons/fields/cartProducts'

/**
 * Get summary fields
 * @internal
 */
export const summaryFieldsQuery: string = `
  items {
    ${cartProductsFieldsQuery}
  }
  groups {
    groupBy
    items {
      ${cartProductsFieldsQuery}
    }
    total
    subtotal
  }
  id
  status
  customFields
  createdAt
  updatedAt
  total
  subtotal
  warnings
  activeFees {
    total
    fee
    {
      fee
      threshold
      type
    }
  }
  activePromotions {
      count
      pricePerItem
      promotion {
        ${promotionsFieldsQuery}
      }
  }
`
/**
 * Get's the string for `summary` query.
 * This query gets all information from cart query plus the customFields with addon modifier.
 * @internal
 */
const getSummaryQuery = gql`
  query summary(
    $summaryStorefrontName: String!,
    $summarySessionUid: ID!,
  ) {
    summary(storefrontName: $summaryStorefrontName, sessionUid: $summarySessionUid) {
      ${summaryFieldsQuery}
    }
  }
`

export default getSummaryQuery
