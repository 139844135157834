/**
 * linkedConditionalStockQuery
 * @internal
 */
const linkedProductsFieldsQuery: string = `
  linkedProducts
  linkedConditionalStock{
    requiredQuantity
    stockPerRequiredQuantity
  }
`

export default linkedProductsFieldsQuery
