import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGlobalPromotion } from '../../../interfaces'

export const initialState: IGlobalPromotion = {
  show: false,
  message: '',
  showCart: false,
  messageCart: '',
}

export const globalPromotionsSlice = createSlice({
  name: 'suggestedProductsSlice',
  initialState,
  reducers: {
    setShow: (state, { payload }: PayloadAction<boolean>) => {
      state.show = payload
    },
    setMessage(state, { payload }: PayloadAction<string>) {
      state.message = payload
    },
    setCartInfo: (
      state,
      {
        payload: { show, message },
      }: PayloadAction<{ show: boolean; message?: string }>,
    ) => {
      state.showCart = show
      state.messageCart = message ?? ''
    },
  },
})

export const { setShow, setMessage, setCartInfo } =
  globalPromotionsSlice.actions

export default globalPromotionsSlice.reducer
