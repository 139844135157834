import { IGenericFunction } from '../interfaces'

const debounce = (func: IGenericFunction, timeout: number = 300) => {
  let timer: NodeJS.Timeout
  return (...args: unknown[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

/**
 * Debounce a function with the same id given, id must be the first param when result function be called and the rest of it will be apply as a params of func param
 * @param func: function
 * @param timeout: number
 * @returns function
 */
const debounceById = (func: IGenericFunction, timeout: number = 300) => {
  const timers: { [key: string]: NodeJS.Timeout } = {}
  return (...args: unknown[]) => {
    if (args.length === 0) {
      throw new Error('Id param must be included as an function argument')
    }
    const id: string = args[0] as string
    if (timers[id] !== undefined) {
      clearTimeout(timers[id])
    }
    timers[id] = setTimeout(() => {
      func.apply(this, args.slice(1, args.length))
    }, timeout)
  }
}

export { debounceById }
export default debounce
