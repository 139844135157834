import Hotjar from '@hotjar/browser'
import { IGoToCategory } from '../../interfaces/actions'
import {
  setCurrentCategory,
  setCurrentCategoryCatalog,
} from '../../redux/slices/catalog'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { getSlugParentCategory } from '../../utils/catalog/getParentCategory'

const useCategoryFilters = () => {
  const dispatch = useAppDispatch()
  const categories = useAppSelector((state) => state.catalogSlice.categories)
  const analiticsCategory = 'goToCategory'
  const action = 'Go to Category'
  const updateCategory = ({ category }: IGoToCategory) => {
    const [selectedCategory] = categories.filter(
      (currentCategory) => currentCategory.slug === category,
    )
    if (selectedCategory) {
      dispatch(setCurrentCategory(selectedCategory))
    }
  }

  const updateCurrentCategoryCatalog = ({
    category: categorySlug,
  }: IGoToCategory) => {
    const parentSlug = getSlugParentCategory(categories, categorySlug)
    dispatch(setCurrentCategoryCatalog(parentSlug))
  }

  const scrollToCategory = ({ category }: IGoToCategory) => {
    const [selectedCategory] = categories.filter(
      (currentCategory) => currentCategory.slug === category,
    )

    if (selectedCategory) {
      dispatch(setCurrentCategory(selectedCategory))
    }
    Hotjar.event(action)
    sendGoogleEvent(analiticsCategory, action, category)
    window.fbq('trackCustom', 'GoToCategory', { category })
    document.querySelector(`[data-category="${category}"]`)?.scrollIntoView({
      behavior: 'auto',
    })
  }

  return {
    updateCategory,
    scrollToCategory,
    updateCurrentCategoryCatalog,
  }
}

export default useCategoryFilters
