/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'
import { useAppSelector, useSuggestionItems } from '../hooks'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import useBannersClick from '../hooks/useBannersClick'
import { useClearFilters } from '../hooks/filters/useFilters'
import { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import type { IGenericFunction } from '../interfaces'
import { usePrefillCart } from '../hooks/prefillCart/usePrefillCart'
import { useCategoriesNavigation } from '../hooks/categoriesnav/useCategoriesNavigation'
import useGetProductsNav from '../hooks/categoriesnav/useGetProductsNav'

const Categories: FC<ITemplate> = ({ ...props }) => {
  const clearFilters = useClearFilters()
  const bannersClick = useBannersClick()
  const fetchSuggestedItems = useSuggestionItems()
  const { updatePrefillCartItem, addPrefillCartToCart } = usePrefillCart()
  const { setCategoriesNavigation, navigateToCategory } =
    useCategoriesNavigation()
  const { slug = '', categoryName } = useParams<{
    slug: string
    categoryName?: string
  }>()
  const getProductsNav = useGetProductsNav()

  const sessionData = useAppSelector((state) => state.defaultSlice.sessionData)
  const customer = sessionData?.customer
  const workflow = sessionData?.workflow
  const { id: sessionId = '' } = sessionData || {}
  const { code = '', phoneNumber = [], type = '' } = customer || {}
  const { userUid, channelUid, channel, workflowName } = workflow || {}

  const templateActions: ITemplateActions = {
    [TemplateActionNames.FILTERS_CLEAR_FILTERS]: clearFilters,
    [TemplateActionNames.CATALOG_BANNERS_CLICK]:
      bannersClick as IGenericFunction,
    [TemplateActionNames.SUGGESTED_ITEMS_FETCH_PRODUCTS]:
      fetchSuggestedItems as IGenericFunction,
    [TemplateActionNames.PREFILL_CART_UPDATE_ITEM]:
      updatePrefillCartItem as IGenericFunction,
    [TemplateActionNames.PREFILL_CART_TO_CART]:
      addPrefillCartToCart as IGenericFunction,
    [TemplateActionNames.CATEGORIES_NAVIGATION_SET_CATEGORIES]:
      setCategoriesNavigation as IGenericFunction,
    [TemplateActionNames.CATEGORIES_NAVIGATION_NAVIGATE_TO_CATEGORY]:
      navigateToCategory as IGenericFunction,
    [TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS]:
      getProductsNav as IGenericFunction,
  }
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/${props.container.uid}` })
    window.fbq('track', 'PageView', { page: `/${props.container.uid}` })
  }, [])
  return <Default templateActions={templateActions} {...props} />
}

export default Categories
