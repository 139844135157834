import { gql } from '@apollo/client'
import productsFieldsQuery from '../../commons/fields/products'
import linkedProductsFieldsQuery from '../../commons/fields/linkedProducts'

/**
 * Gets the string for `linkedProductsQuery` query,
 * with all the linked products related
 * @internal
 */

const linkedProductsQuery = gql`
  query LinkedProducts(
    $storefrontName: String!, 
    $sessionUid: ID!
  ) {
    linkedProducts(storefrontName: $storefrontName, sessionUid: $sessionUid) {
      ${productsFieldsQuery}
      ${linkedProductsFieldsQuery}
    }
  }
`

export default linkedProductsQuery
