import { CartLabelMap } from '@engyalo/delivery-ui-components/lib/ui/components/CartList'
import { TextLibrary } from '../defaultConstants'

export const cartLabels: CartLabelMap = {
  cash: {
    label: TextLibrary.CART_CASH_GROUP,
  },
  credit: {
    label: TextLibrary.CART_CREDIT_GROUP,
  },
  products: {
    label: TextLibrary.CART_PRODUCTS_GROUP,
  },
  promotions: {
    label: TextLibrary.CART_PROMOTIONS_GROUP,
  },
  box: {
    label: TextLibrary.CART_BOX_GROUP,
  },
  unit: {
    label: TextLibrary.CART_UNIT_GROUP,
  },
  service: {
    label: TextLibrary.CART_SERVICE_LABEL,
  },
  material: {
    label: TextLibrary.CART_MATERIAL_LABEL,
  },
  perception: {
    label: TextLibrary.CART_PERCEPTION_LABEL,
  },
  warning: {
    label: TextLibrary.CART_MAX_QTY_ALERT,
  },
  subtotal: {
    label: TextLibrary.SUMMARY_SUBTOTAL_LABEL,
  },
  discounts: {
    label: TextLibrary.SUMMARY_DISCOUNTS_LABEL,
  },
  taxes: {
    label: TextLibrary.SUMMARY_TAXES_LABEL,
  },
  perceptions: {
    label: TextLibrary.SUMMARY_PERCEPTIONS_LABEL,
  },
  administrativeServices: {
    label: TextLibrary.SUMMARY_ADMINISTRATIVE_SERVICE_LABEL,
  },
}
