import { useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch } from '../hooks'
import { updateParams } from '../redux/slices/config'
import { getQueryParams } from '../utils/getURLData'

const RouterParams = () => {
  const dispatch = useAppDispatch()
  const routerParams = useParams()
  const { length, action, location } = useHistory()
  const queryParams = getQueryParams()
  const params = useMemo(
    () => ({
      ...routerParams,
      ...queryParams,
      location: {
        ...location,
        action,
        length,
      },
    }),
    // params will update only when location (pathname + search vars) updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  useEffect(() => {
    dispatch(updateParams(params))
  }, [params, dispatch])
  return null
}

export default RouterParams
