import { Banner as BannerCommerceSDK } from '../graphQL/queries/getSession/types'
import { Banner } from '../interfaces/banners'

const castBanners = (source: BannerCommerceSDK[]): Banner[] =>
  source.map(
    ({ imagesURL: [image], id, type, isActive, priority, reference }) => ({
      image,
      data: {
        id,
        type,
        isActive,
        priority,
        reference,
      },
    }),
  )

export default castBanners
