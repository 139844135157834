import { useState } from 'react'
import { IComponentPromises } from '../../interfaces/templates'

const useTemplateComponentsState = () => {
  const [headerComponentsList, setHeaderComponentsList] =
    useState<IComponentPromises>([])
  const [bodyComponentsList, setBodyComponentsList] =
    useState<IComponentPromises>([])
  const [footerComponentsList, setFooterComponentsList] =
    useState<IComponentPromises>([])

  return {
    headerComponentsList,
    setHeaderComponentsList,
    bodyComponentsList,
    setBodyComponentsList,
    footerComponentsList,
    setFooterComponentsList,
  }
}

export default useTemplateComponentsState
