import { gql } from '@apollo/client'
import { cartFieldsQuery } from '../../queries/getCart/query'

/**
 * Get's the string for `activateDeferredPromotion` mutation.
 * This mutation activate deferred promotion for given session.
 * @internal
 */
export const activateDeferredPromotion = `
mutation ActivateDeferredPromotion(
  $storefrontName: String!
  $sessionUid: ID!
  $data: ActivateDeferredPromotionDataInput!
) {
  activateDeferredPromotion(
    storefrontName: $storefrontName
    sessionUid: $sessionUid
    data: $data
  ) {
    ${cartFieldsQuery}
  }
}`

export default gql`
  ${activateDeferredPromotion}
`
