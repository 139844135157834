import { Loader } from '@engyalo/delivery-ui-components'
import { LoaderProps } from '@engyalo/delivery-ui-components/lib/ui/components/Loader'
import { useAppSelector } from '../hooks'

const loaderProps: Partial<LoaderProps> = {
  fullScreen: false,
  styles: {
    zIndex: '9999',
    '.MuiBackdrop-root': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
    '.ModalBoxWrapper': {
      width: 'auto',
      maxHeight: 'auto',
      minHeight: 'auto',
      borderRadius: '200px',
      padding: '16px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  },
}

const GlobalLoader = () => {
  // TODO - add validators when you need to display a general loader
  const loaders: boolean[] = []

  return <Loader show={loaders.some((loader) => loader)} {...loaderProps} />
}

export default GlobalLoader
