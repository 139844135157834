/**
 * Packages fields
 * @internal
 */

const packageFields: string = `
    name
    label
    quantity
    price
    jumpQty
    minQty
    maxQty
    stock
    outOfStock
    basePrice
    fees {
      type
      threshold
      fee
    }
`

export default packageFields
