import {
  CATEGORIES_PAGE_SIZE_NAVIGATION,
  FIRST_PAGE_NUMBER,
} from '../../consts/defaultConfigValues/defaultConstants'
import {
  ICategoryNavigation,
  ICategoryPagination,
  IConditionAction,
  ITransformCategoriesArgs,
} from '../../interfaces'
import { castICategoriesToICategoryNavigation } from '../../utils/categoriesnav/castICategoriesToICategoryNavigation'
import { resolveSlugs } from '../../utils/categoryUtils'

export const URL_PREFFIX_CATEGORIES_NAV = 'categories-nav/'
export const URL_PREFFIX_CATEGORY = 'category-nav/'
export const URL_PREFFIX_CATEGORIES_NAV_HOME = 'categories/'

export const initialPagination: ICategoryPagination = {
  pageNumber: FIRST_PAGE_NUMBER,
  pageSize: CATEGORIES_PAGE_SIZE_NAVIGATION,
  hasMore: true,
}

export const transformerCategories: IConditionAction<
  ITransformCategoriesArgs,
  ICategoryNavigation[]
>[] = [
  {
    condition: ({ isPaginatedCategories, categoryId }) =>
      !isPaginatedCategories && !!categoryId,
    transformAction: ({ seeCategoryProductsItem, existingCategories }) => [
      seeCategoryProductsItem,
      ...existingCategories,
    ],
  },
  {
    condition: ({ isPaginatedCategories, categoryId }) =>
      !isPaginatedCategories && !categoryId,
    transformAction: ({ seeAllCatalogItem, existingCategories }) => [
      seeAllCatalogItem,
      ...existingCategories,
    ],
  },
  {
    condition: ({ categoriesResponse }) => !!categoriesResponse.length,
    transformAction: ({ existingCategories, categoriesResponse }) => [
      ...existingCategories,
      ...categoriesResponse.map((categoryResponse) =>
        castICategoriesToICategoryNavigation(categoryResponse),
      ),
    ],
  },
  {
    condition: ({ existingCategories }) => !!existingCategories.length,
    transformAction: ({ existingCategories }) =>
      resolveSlugs(existingCategories) as ICategoryNavigation[],
  },
]
