import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import getFiltersValuesQuery from './query'
import { IGetFiltersValuesResponse, IGetFiltersValuesVars } from './types'

/**
 * Hook declaration to obtain a get filters lazy query.
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetFiltersValues = (
  options?: LazyQueryHookOptions<
    IGetFiltersValuesResponse,
    IGetFiltersValuesVars
  >,
) => {
  return useLazyQuery<IGetFiltersValuesResponse, IGetFiltersValuesVars>(
    getFiltersValuesQuery,
    options,
  )
}
