import AppContainer from '@engyalo/delivery-ui-components/lib/ui/components/AppContainer/AppContainer'
import { IActionButton } from '@engyalo/delivery-ui-components/lib/interfaces/actions'
import { MessageProps } from '@engyalo/delivery-ui-components/lib/ui/components/Message'
import { Message } from '@engyalo/delivery-ui-components/lib/ui/components/Message/Message'
import { CHATBOT_WARNING_ICON } from '../consts/defaultConfigValues/defaultConstants'

export enum ActionType {
  reload = 'reload',
  navigate = 'navigate',
}

interface ErrorMessageProps {
  message: string
  title: string
  callbackMessage?: string
  callBack?: () => void
  customButton?: IActionButton
  action: ActionType
}

const ErrorMessage = ({
  message = '',
  title = '',
  callbackMessage = '',
  callBack,
  action,
}: ErrorMessageProps) => {
  const messageProps: MessageProps = {
    title: { value: title, size: 22 },
    image: {
      src: CHATBOT_WARNING_ICON,
      size: 120,
    },
    message: {
      value: message,
      size: 18,
    },
  }

  const baseActionButtonProps: IActionButton = {
    label: callbackMessage,
    textTransform: 'none',
    fullWidth: false,
    actions: callBack,
  }

  const actionButtonProps: IActionButton =
    action === ActionType.navigate
      ? {
          ...baseActionButtonProps,
          endIcon: 'MdWhatsapp',
          styles: {
            color: '#fff',
            background: '#32D951',
            '&:hover': {
              background: '#26AB53',
            },
          },
        }
      : {
          ...baseActionButtonProps,
          endIcon: 'MdReplay',
        }

  const buttons: IActionButton[] =
    callBack && typeof callBack === 'function' ? [actionButtonProps] : []

  return (
    <AppContainer
      id="error-app-container"
      body={{
        id: 'cinnamon-error-message',
        columns: 1,
        components: [
          <Message {...messageProps} buttons={buttons} key="error-message" />,
        ],
      }}
    />
  )
}

export default ErrorMessage
