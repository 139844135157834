import axios from 'axios'
import { SessionDataArgs } from '../graphQL/queries/getSession/types'
import { WHATSAPP_ME_URL } from '../consts/defaultConfigValues/defaultConstants'

interface ICreateOrderService {
  data: SessionDataArgs
  phoneNumber: string
  workflowApi: string
  botrunnerApi: string
}

const postCreateOrder = async (params: ICreateOrderService) => {
  const { data, phoneNumber, workflowApi, botrunnerApi } = params
  const { workflow, configuration } = data
  const currentWorkflowConfig =
    configuration?.workflow &&
    workflow?.name &&
    configuration.workflow[workflow.name]
  if (!currentWorkflowConfig) throw new Error('Unexpected error')
  let ngUrl = ''
  const stephookValue =
    currentWorkflowConfig.stephook && currentWorkflowConfig.stephook.preorder
      ? currentWorkflowConfig.stephook.preorder
      : ''
  const phone = currentWorkflowConfig.channelUid || phoneNumber
  if (currentWorkflowConfig.ng && currentWorkflowConfig.ng === true) {
    ngUrl = `${workflowApi}${stephookValue}/webhook?token=${currentWorkflowConfig.jwtToken}`
    const res = await axios.post(ngUrl, { userId: workflow.userUid })
    if (res.status === 200) {
      window.location = `${WHATSAPP_ME_URL}${phone}` as any
    } else {
      throw new Error('Unexpected error')
    }
  } else {
    ngUrl = currentWorkflowConfig
      ? `${botrunnerApi}${workflow.channel}/sent-to?jwt=${currentWorkflowConfig.jwtToken}`
      : ''
    const res = await axios.post(ngUrl, {
      userId: workflow?.userUid,
      state: stephookValue,
    })
    if (res.status === 200) {
      window.location = `${WHATSAPP_ME_URL}${phone}` as any
    } else {
      throw new Error('Unexpected error')
    }
  }
}

export default postCreateOrder
