import { ICart } from './cart'

export enum AlertConstrainsSummary {
  summaryAlertOnMinAmount = 'summaryAlertOnMinAmount',
}

export type ISummaryAlertsStatus = Record<
  AlertConstrainsSummary,
  { isActive: boolean }
>

export type ISummaryAlertsValues = Record<
  AlertConstrainsSummary,
  { value: number }
>

export interface ISummary extends ICart {
  summaryAlerts: ISummaryAlertsStatus
  placeOrderFlow: {
    actions: string[]
    isDone: boolean
  }
  deferredPromotions: any[]
}
