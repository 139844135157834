import {
  BundleDisplayOptionsEnum,
  IBundleExtraType,
  IBundleType,
} from '../graphQL/commons'
import { IBundleExtraSanitized, IConditionAction, IConfig } from '../interfaces'
import formatPrice from './formatPrice'

interface ISanitizeBundlesExtraCatalogArgs {
  bundleExtra: IBundleExtraType
  bundleConfig: IBundleType | null
  config?: IConfig
}

interface ISanitizeBundleConditionTransform
  extends ISanitizeBundlesExtraCatalogArgs {
  bundleExtraSanitized: IBundleExtraSanitized
}

const conditionsTransform: IConditionAction<
  ISanitizeBundleConditionTransform,
  IBundleExtraSanitized
>[] = [
  {
    condition: ({ bundleConfig }) =>
      bundleConfig?.displayOption === BundleDisplayOptionsEnum.DESCRIPTION,
    transformAction: ({
      bundleExtraSanitized,
      bundleExtra: { description },
    }) => ({
      ...bundleExtraSanitized,
      name: description || '',
    }),
  },
  {
    condition: ({ bundleConfig }) =>
      bundleConfig?.displayOption ===
      BundleDisplayOptionsEnum.UNITS_PER_PACKAGE,
    transformAction: ({
      bundleExtraSanitized,
      bundleExtra: { unitsPerPackage },
    }) => ({
      ...bundleExtraSanitized,
      name: unitsPerPackage || '',
    }),
  },
  {
    condition: ({ bundleConfig }) =>
      bundleConfig?.displayOption === BundleDisplayOptionsEnum.NAME,
    transformAction: ({ bundleExtraSanitized, bundleExtra: { name } }) => ({
      ...bundleExtraSanitized,
      name: name || '',
    }),
  },
  {
    condition: ({ bundleExtra: { price } }) => !!price,
    transformAction: ({
      bundleExtra: { price },
      bundleExtraSanitized,
      config,
    }) => {
      const currency: string | undefined =
        config?.options?.currency || config?.options?.currencySymbol
      const format: string | undefined = config?.options?.format

      return {
        ...bundleExtraSanitized,
        price,
        suffixLabel: formatPrice({
          price: price!,
          currency,
          format,
        }),
      }
    },
  },
]

export const sanitizeBundleExtra = (
  args: ISanitizeBundlesExtraCatalogArgs,
): IBundleExtraSanitized =>
  conditionsTransform.reduce<IBundleExtraSanitized>(
    (acc, { condition, transformAction }) =>
      condition({
        ...args,
        bundleExtraSanitized: acc,
      })
        ? transformAction({
            ...args,
            bundleExtraSanitized: acc,
          })
        : acc,
    {
      name: args.bundleExtra.name || '',
      sku: args.bundleExtra.sku || '',
      stock: args.bundleExtra.stock || 0,
      price: null,
    },
  )
