import { MinAmountProps } from '@engyalo/delivery-ui-components/lib/ui/components/MinAmount'
import { DefaultProps } from '../../../interfaces'

export const MinAmountDefault: DefaultProps<MinAmountProps> = {
  total: '{{cart.total}}' as any,
  show: '{{default.cartAlerts.cartConditionPendingMinAmount.isActive}}' as any,
  message: '{{checkoutRules.cartConditionPendingMinAmount.message}}',
  showAmount: '{{globals.minAmount.showAmount}}' as any,
  showIndicator: '{{globals.minAmount.showIndicator}}' as any,
  minAmount: {
    value: '{{checkoutRules.cartConditionPendingMinAmount.quantity}}' as any,
    symbol: '{{config.options.currencySymbol}} || {{config.options.currency}}',
    code: '{{config.options.currencyCode}} || {{config.options.currencyPrefix}}',
    format: '{{config.options.format}}',
  },
}
