import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client'
import getCategoriesQuery from './query'
import { ICategoriesVariables, ICategories } from './types'

/**
 * Hook declaration to obtain a lazy query of categories data
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetCategories = (
  options?: LazyQueryHookOptions<ICategories, ICategoriesVariables>,
) => {
  return useLazyQuery<ICategories, ICategoriesVariables>(
    getCategoriesQuery,
    options,
  )
}
